import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useCategoryDetailQuery } from '../../../generated/graphql';
import CategoryDetailMenu from '../components/CategoryDetailMenu';
import CategoryEditOverlay from '../components/CategoryEditOverlay';

const CategoryDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { categoryId } = useParams();

  const options = {
    variables: {
      id: categoryId || '',
    },
  };

  const { data, loading, error } = useCategoryDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.category) return <NotFoundCard resource="Kategorie" />;

  const { category } = data;

  return (
    <>
      <CategoryEditOverlay id={category.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{category.name}</Text>
              <Tag minimal className="ml-2">
                Kategorie
              </Tag>
            </>
          }
          rightElement={<CategoryDetailMenu category={category} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Kategorie</PropertyListHeader>
            <PropertyListItem
              label="Beschreibung"
              value={category.description || '—'}
              showValueDisabled={!category.description}
            />
            <PropertyListItem label="Farbe">
              <Tag style={{ backgroundColor: category.color }} />
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

CategoryDetailPage.fragments = {
  category: gql`
    fragment CategoryDetailPage on Category {
      id
      name
      description
      color
    }
  `,
};

export default CategoryDetailPage;
