import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PersonStatusSelect from '../../../components/PersonStatusSelect';
import Text from '../../../components/Text';
import { UserStatus } from '../../../generated/graphql';
import config from '../../../helpers/config';
import { openTab, getSubdomain } from '../../../helpers/utils';
import useQueryParams from '../../../hooks/useQueryParams';
import CompanionCreateOverlay from '../components/CompanionCreateOverlay';
import CompanionList from '../components/CompanionList';

const CompanionListPage = () => {
  const queryParams = useQueryParams();
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const status = (queryParams.get('status') as UserStatus) || UserStatus.Active;

  const changeStatus = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newStatus = event.currentTarget.value;
    queryParams.set('status', newStatus);
    history.push({ search: `?${queryParams.toString()}` });
  };

  const onCreated = (modelId: string) => {
    AppToaster.success('Begleiter hinzugefügt!');
    history.push(`/companions/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <CompanionCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <>
            <Text large className="mr-2">
              Begleiter
            </Text>
            <PersonStatusSelect value={status} onChange={changeStatus} />
          </>
        }
        rightElement={
          <>
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              className="ml-2"
              onClick={() => setIsCreateDialogOpen(true)}
            />
            <Button
              text="Exportieren"
              icon={IconNames.EXPORT}
              className="ml-2"
              onClick={() => openTab(`${config.baseUrl}/${getSubdomain()}/downloads/companions`)}
            />
          </>
        }
      />
      <CompanionList />
    </ContentCard>
  );
};

export default CompanionListPage;
