import { gql } from '@apollo/client';
import { Button, Elevation, HTMLSelect, Intent } from '@blueprintjs/core';
import React, { useCallback, useReducer } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import CategoryTag from '../../../components/CategoryTag';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListDivider, PropertyListHeader } from '../../../components/PropertyList';
import Text from '../../../components/Text';
import {
  EventMatchingQuery,
  useEventMatchingQuery,
  useUpdateEventCandidatesMutation,
} from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';
import EventStatusIcon from '../../events/components/EventStatusIcon';
import CandidateList from '../components/CandidateList';
import CandidateListStatus from '../components/CandidateListStatus';
import ProspectList from '../components/ProspectList';
import { initialState, isCompanionCandidate, reducer } from '../state';

const warnInsufficientTickets = () => {
  AppToaster.danger('Nicht genügend Karten vorhanden!');
};

const MatchingPage = () => {
  const history = useHistory();
  const { eventId } = useParams();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { takenTickets, purchasedTickets, cardholder, candidates, prospects } = state;

  const { data, loading, error } = useEventMatchingQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: eventId || '',
    },
    onCompleted: ({ event }: EventMatchingQuery) => {
      if (event && event.signups && event.signups.length) {
        dispatch({
          type: 'init',
          payload: event.signups,
        });
      }
    },
  });

  const [updateCandidates, { loading: mutating }] = useUpdateEventCandidatesMutation({
    variables: {
      input: {
        id: eventId || '',
        candidates: {
          ...candidates,
          cardholder: cardholder || '',
        },
      },
    },
    onCompleted: () => {
      AppToaster.success('Teilnehmerliste gesichert!');
      history.push(`/events/${eventId}`);
    },
    onError: showErrorMessage,
  });

  const save = () => {
    if (!cardholder) {
      AppToaster.danger('Kein Kartenverteiler gewählt');
      return;
    }

    updateCandidates();
  };

  const isUnlimited =
    !!data &&
    !!data.event &&
    (typeof data.event.available_tickets === 'undefined' || data.event.available_tickets === null);
  const remainingTickets = !data || !data.event || isUnlimited ? 0 : data.event.available_tickets! - takenTickets;

  const hasMinTickets = useCallback((num: number) => isUnlimited || remainingTickets >= num, [
    isUnlimited,
    remainingTickets,
  ]);

  const addLoneSoul = useCallback(
    (soulSignupId: string, buysTicket: boolean) => {
      if (!buysTicket && !hasMinTickets(1)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addLoneSoul',
        payload: {
          soul: soulSignupId,
          buysTicket,
        },
      });
    },
    [hasMinTickets],
  );

  const addLoneCompanion = useCallback(
    (companionSignupId: string) => {
      if (!hasMinTickets(1)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addLoneCompanion',
        payload: companionSignupId,
      });
    },
    [hasMinTickets],
  );

  const addTeam = useCallback(
    (companionSignupId: string, soulSignupId: string, soulBuysTicket: boolean) => {
      let requiredTickets = 0;
      if (!soulBuysTicket) requiredTickets++;
      if (!isCompanionCandidate(state, companionSignupId)) requiredTickets++;

      if (!hasMinTickets(requiredTickets)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addTeam',
        payload: {
          companion: companionSignupId,
          soul: soulSignupId,
          soulBuysTicket,
        },
      });
    },
    [hasMinTickets, state],
  );

  const addGroup = useCallback(
    (companionSignupId: string, soulSignupIds: string[]) => {
      if (!hasMinTickets(soulSignupIds.length + 1)) {
        warnInsufficientTickets();
        return;
      }
      dispatch({
        type: 'addGroup',
        payload: {
          companion: companionSignupId,
          souls: soulSignupIds,
        },
      });
    },
    [hasMinTickets],
  );

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.event) return <NotFoundCard resource="Spender" />;

  const { event } = data;

  const priorityMap = event.signups.reduce<{ [k: string]: number }>(
    (acc, signup) => ({
      ...acc,
      [signup.id]: signup.priority,
    }),
    {},
  );

  const soulSignupIds = prospects.souls.sort((a, b) => priorityMap[b] - priorityMap[a]);
  const companionSignupIds = prospects.companions.sort((a, b) => priorityMap[b] - priorityMap[a]);
  const cardholderOptions = event.signups
    .filter((signup) => !!signup.companion && !companionSignupIds.includes(signup.id))
    .map((signup) => ({ label: signup.companion!.display_name, value: signup.id }));

  return (
    <>
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <EventStatusIcon event={event} className="mr-2" />
              <Text large>{event.name}</Text>
              {event.categories.map((category) => (
                <CategoryTag key={category.id} category={category} className="ml-1" />
              ))}
            </>
          }
          rightElement={
            <Button intent={Intent.SUCCESS} text="Teilnehmerliste Sichern" loading={mutating} onClick={save} />
          }
        />
      </ContentCard>
      <div className="flex">
        <div className="w-1/2 pr-2">
          <ProspectList
            groupSignupData={prospects.groups}
            soulSignupIds={soulSignupIds}
            companionSignupIds={companionSignupIds}
            addLoneSoul={addLoneSoul}
            addLoneCompanion={addLoneCompanion}
            addTeam={addTeam}
            addGroup={addGroup}
          />
        </div>
        <div className="w-1/2 pl-2">
          <ContentCard elevation={Elevation.TWO} className="mb-5">
            <ContentCardHeader leftElement={<Text large>Teilnehmerliste</Text>} />
            <div className="flex py-4 px-5">
              <PropertyList>
                <PropertyListHeader>Karten</PropertyListHeader>
                <CandidateListStatus
                  takenTickets={takenTickets}
                  purchasedTickets={purchasedTickets}
                  availableTickets={event.available_tickets}
                />
              </PropertyList>
              <PropertyListDivider />
              <PropertyList>
                <PropertyListHeader>Kartenverteiler</PropertyListHeader>
                <HTMLSelect
                  disabled={!cardholderOptions.length}
                  options={cardholderOptions}
                  fill
                  value={cardholder}
                  onChange={(e) => dispatch({ type: 'setCardholder', payload: e.target.value })}
                />
              </PropertyList>
            </div>
          </ContentCard>
          <CandidateList candidates={candidates} dispatch={dispatch} />
        </div>
      </div>
    </>
  );
};

MatchingPage.fragments = {
  matching: gql`
    fragment MatchingPage on Event {
      id
      name
      status
      start
      end
      archived
      taken_tickets
      available_tickets
      candidates_chosen
      cardholder {
        id
        display_name
        mobile
        phone
        email
      }
      categories {
        id
        name
        color
      }
      signups {
        ...CandidateList
      }
      location {
        id
        name
        accessible
        meeting_point
        public_transport
        street
        postal_code
        city
        lat
        lng
      }
    }
    ${CandidateList.fragments.signups}
  `,
};

export default MatchingPage;
