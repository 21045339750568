import React from 'react';
import { useParams } from 'react-router-dom';
import PaginatedTable from '../../../components/PaginatedTable';
import { useLocationDonorsQuery } from '../../../generated/graphql';
import DonorListItem from '../../donors/components/DonorListItem';
import { dummyDonors } from '../../donors/dummy';

const LocationDonorList = () => {
  const { locationId } = useParams();
  const { data, loading, error } = useLocationDonorsQuery({
    // fetchPolicy: 'network-only',
    variables: {
      id: locationId || '',
    },
  });

  const donors = data?.location?.donors || [];

  return (
    <PaginatedTable
      columns={['Name', 'Ansprechpartner']}
      interactive
      loading={loading}
      hasError={!!error}
      empty={!donors?.length}
    >
      {(loading ? dummyDonors : donors).map((donor) => (
        <DonorListItem key={donor.id} item={donor} skeleton={loading} />
      ))}
    </PaginatedTable>
  );
};

export default LocationDonorList;
