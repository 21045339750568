import { Button, ButtonGroup, Elevation, FormGroup, HTMLSelect } from '@blueprintjs/core';
import { format, subMonths } from 'date-fns';
import React, { useEffect, useReducer } from 'react';
import { ContentCard } from '../../../components/ContentCard';
import { StatsRangeType } from '../../../generated/graphql';

type StatsSettingsProps = {
  onChange: ({ range, rangeType }: { range: string; rangeType: StatsRangeType }) => void;
};

type ActionType =
  | {
      type: 'changeMonth';
      payload: string;
    }
  | {
      type: 'changeYear';
      payload: string;
    }
  | {
      type: 'changeRangeType';
      payload: StatsRangeType;
    };

type StateType = {
  year: string;
  month: string;
  rangeType: StatsRangeType;
  range: string;
};

const now = new Date();
const startYear = 2014;
const yearsSinceStart = parseInt(format(now, 'yyyy'), 10) - startYear + 1;
const availableMonths = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember',
];
const availableYears = Array(yearsSinceStart)
  .fill(0)
  .map((_, offset) => startYear + offset);

export const initialState = {
  year: format(now, 'yyyy'),
  month: format(subMonths(now, 1), 'M'),
  rangeType: StatsRangeType.Month,
  range: format(subMonths(now, 1), 'yyyy-M'),
};

const withRange = (state: StateType) => ({
  ...state,
  range: state.rangeType === StatsRangeType.Year ? state.year : `${state.year}-${state.month}`,
});

const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'changeMonth':
      return withRange({
        ...state,
        month: action.payload,
      });
    case 'changeYear':
      return withRange({
        ...state,
        year: action.payload,
      });
    case 'changeRangeType':
      return withRange({
        ...state,
        rangeType: action.payload,
      });
    default:
      return state;
  }
};

const StatsSettings = ({ onChange }: StatsSettingsProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    onChange({
      range: state.range,
      rangeType: state.rangeType,
    });
  }, [onChange, state]);

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5 mr-5 py-4 px-5">
      <FormGroup label="Periode" labelInfo="(erforderlich)">
        <ButtonGroup>
          <Button
            text="Jahr"
            value={StatsRangeType.Year}
            active={state.rangeType === StatsRangeType.Year}
            onClick={() => dispatch({ type: 'changeRangeType', payload: StatsRangeType.Year })}
          />
          <Button
            text="Monat"
            value={StatsRangeType.Month}
            active={state.rangeType === StatsRangeType.Month}
            onClick={() => dispatch({ type: 'changeRangeType', payload: StatsRangeType.Month })}
          />
        </ButtonGroup>
      </FormGroup>
      <FormGroup label="Zeitraum" labelInfo="(erforderlich)">
        {state.rangeType === StatsRangeType.Month && (
          <HTMLSelect
            className="mr-2"
            placeholder="Monat"
            value={state.month}
            onChange={({ currentTarget: { value } }) => dispatch({ type: 'changeMonth', payload: value })}
            options={availableMonths.map((month, index) => ({ label: month, value: (index + 1).toString() }))}
          />
        )}
        <HTMLSelect
          placeholder="Jahr"
          value={state.year}
          onChange={({ currentTarget: { value } }) => dispatch({ type: 'changeYear', payload: value })}
          options={availableYears.map((year) => year.toString())}
        />
      </FormGroup>
    </ContentCard>
  );
};

export default StatsSettings;
