import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import ContactCreateOverlay from '../../contacts/components/ContactCreateOverlay';
import DonorContactList from '../components/DonorContactList';
import DonorEditOverlay from '../components/DonorEditOverlay';
import DonorInfo from '../components/DonorInfo';
import DonorLocationList from '../components/DonorLocationList';

const DonorDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isContactCreateDialogOpen, setIsContactCreateDialogOpen] = useState(false);
  const history = useHistory();
  const { donorId } = useParams();

  const onCreated = (modelId: string) => {
    AppToaster.success('Ansprechpartner hinzugefügt!');
    history.push(`/contacts/${modelId}`);
  };

  return (
    <>
      {/* Create */}
      <ContactCreateOverlay
        donorId={donorId || ''}
        isOpen={isContactCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsContactCreateDialogOpen(false)}
      />

      {/* Edit */}
      <DonorEditOverlay id={donorId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />

      {/* Info */}
      <DonorInfo onEdit={() => setIsEditDialogOpen(true)} />

      {/* Contacts */}
      <ContentCard elevation={Elevation.TWO} className="mb-6">
        <ContentCardHeader
          leftElement={<Text large>Ansprechpartner</Text>}
          rightElement={
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              className="ml-2"
              onClick={() => setIsContactCreateDialogOpen(true)}
            />
          }
        />
        <DonorContactList />
      </ContentCard>

      {/* Locations */}
      <ContentCard elevation={Elevation.TWO} className="mb-6">
        <ContentCardHeader leftElement={<Text large>Lokationen</Text>} />
        <DonorLocationList />
      </ContentCard>
    </>
  );
};

export default DonorDetailPage;
