import { Alert, Button, Intent, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import AppToaster from '../../../AppToaster';
import EmailUsersOverlay from '../../../components/EmailUsersOverlay';
import { EventDetailPageFragment, useInviteCandidatesMutation } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';
import { useNavigate } from '../../../hooks/useNavigate';

type AttendeeListMenuProps = {
  event: EventDetailPageFragment;
};

const AttendeeListMenu = ({ event }: AttendeeListMenuProps) => {
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [isInviteAlertOpen, setIsInviteAlertOpen] = useState(false);
  const navigate = useNavigate();
  const [inviteCandidates, { loading: inviting }] = useInviteCandidatesMutation({
    variables: {
      id: event.id,
    },
    onCompleted: () => AppToaster.success('Alle Teilnehmer wurden eingeladen!'),
    onError: showErrorMessage,
  });

  const onEmailsSent = () => {
    AppToaster.success('Emails wurden versandt!');
    setIsEmailDialogOpen(false);
  };

  if (!event.candidates_chosen) {
    return (
      <Button
        text="Erstellen"
        intent={Intent.PRIMARY}
        icon={IconNames.SAVED}
        className="ml-2"
        onClick={navigate(`/events/${event.id}/matching`)}
      />
    );
  }

  const users = event.candidates
    .filter((candidate) => (candidate.soul ? candidate.soul.email : candidate.companion!.email))
    .map((candidate) =>
      candidate.soul
        ? {
            id: candidate.soul.id,
            label: candidate.soul.display_name,
          }
        : {
            id: candidate.companion!.id,
            label: candidate.companion!.display_name,
          },
    );

  return (
    <>
      <EmailUsersOverlay
        isOpen={isEmailDialogOpen}
        onSuccess={onEmailsSent}
        onClose={() => setIsEmailDialogOpen(false)}
        users={users}
      />
      <Alert
        isOpen={isInviteAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Teilnehmer Einladen"
        icon={IconNames.TAKE_ACTION}
        intent={Intent.PRIMARY}
        onClose={() => setIsInviteAlertOpen(false)}
        onConfirm={inviteCandidates as any}
      >
        <p>
          Sollen jetzt die Genießer und Beleiter auf der Teilnehmerliste eingeladen werden?
          <br />
          <br />
          Die Einladungen werden per Email versendet.
        </p>
      </Alert>
      <Button
        text="Bearbeiten"
        icon={IconNames.EDIT}
        className="ml-2"
        onClick={navigate(`/events/${event.id}/matching`)}
      />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            {event.needsInvite && (
              <MenuItem
                text="Teilnehmer Einladen"
                icon={IconNames.TAKE_ACTION}
                onClick={() => setIsInviteAlertOpen(true)}
              />
            )}
            <MenuItem
              text="Nachricht an alle Teilnehmer"
              icon={IconNames.ENVELOPE}
              onClick={() => setIsEmailDialogOpen(true)}
            />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={inviting} />
      </Popover>
    </>
  );
};

export default AttendeeListMenu;
