/** @jsx jsx */
import { Colors, HTMLSelect } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { endOfDay, subDays, subMonths } from 'date-fns';
import { Hit } from 'react-instantsearch-core';
import { Highlight } from 'react-instantsearch-dom';
import DateRangePicker from '../../../components/DateRangePicker';
import DropdownSearch from '../../../components/DropdownSearch';
import Filter from '../../../components/Filter';
import FilterItem from '../../../components/FilterItem';
import { DateTimeRange, EventStatus } from '../../../generated/graphql';
import { FutureDateTimeShortcuts, PastDateTimeShortcuts } from '../../../helpers/dateTimeUtils';
import { DonorHit } from '../../../types';

type EventListFilterProps = {
  archive?: boolean;
};

const EventListFilter = ({ archive }: EventListFilterProps) => (
  <Filter
    filters={[
      {
        key: 'status',
      },
      {
        key: 'donor',
        labelPath: 'name',
      },
      {
        key: 'start',
      },
    ]}
  >
    {archive || (
      <FilterItem<EventStatus> name="status" label="Status" defaultValue={EventStatus.Draft}>
        {({ value, onChange }) => (
          <HTMLSelect
            value={value || undefined}
            onChange={(event) => onChange(event.currentTarget.value as EventStatus)}
            options={[
              {
                label: 'Entwurf',
                value: EventStatus.Draft,
              },
              {
                label: 'Bereit zur Veröffentlichung',
                value: EventStatus.Ready,
              },
              {
                label: 'Veröffentlicht',
                value: EventStatus.Published,
              },
              {
                label: 'Teilnehmer eingeladen',
                value: EventStatus.Closed,
              },
              {
                label: 'Abgesagt',
                value: EventStatus.Cancelled,
              },
            ]}
          />
        )}
      </FilterItem>
    )}
    <FilterItem<Hit<DonorHit>> name="donor" label="Spender">
      {({ value, onChange }) => (
        <DropdownSearch<DonorHit>
          value={value}
          onChange={onChange}
          index="donors"
          labelPath="name"
          canClear
          renderItem={(hit) => <Highlight hit={hit} attribute="name" css={styles.highlight} />}
        />
      )}
    </FilterItem>
    <FilterItem<DateTimeRange> name="start" label="Datum">
      {({ value, onChange }) => (
        <DateRangePicker
          value={value}
          onChange={onChange}
          shortcuts={archive ? PastDateTimeShortcuts : FutureDateTimeShortcuts}
          initialMonth={archive ? subMonths(new Date(), 1) : new Date()}
          maxDate={archive ? endOfDay(subDays(new Date(), 1)) : undefined}
        />
      )}
    </FilterItem>
  </Filter>
);

export default EventListFilter;

const styles = {
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
