import React from 'react';
import MainContent from '../components/MainContent';
import Sidebar from '../components/Sidebar';
import TopNavBar from '../components/TopNavbar';

const MainPage: React.FC = () => (
  <div className="flex min-h-screen my-0 mx-auto" style={{ maxWidth: 1280 }}>
    <Sidebar />
    <div className="flex-grow" style={{ marginLeft: 230 }}>
      <TopNavBar />
      <MainContent />
    </div>
  </div>
);

export default MainPage;
