/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: string;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: string;
};

export type Query = {
  __typename?: 'Query';
  allotment?: Maybe<Allotment>;
  allotments: Array<Allotment>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  categoryStats: Array<CategoryStatsPayload>;
  companion?: Maybe<Companion>;
  companions?: Maybe<CompanionPaginator>;
  contact?: Maybe<Contact>;
  contacts: Array<Contact>;
  currentTenant: Tenant;
  donor?: Maybe<Donor>;
  donors?: Maybe<DonorPaginator>;
  email?: Maybe<Email>;
  employee?: Maybe<Employee>;
  employees?: Maybe<EmployeePaginator>;
  event?: Maybe<Event>;
  events?: Maybe<EventPaginator>;
  isAuthenticated: Scalars['Boolean'];
  location?: Maybe<Location>;
  locations?: Maybe<LocationPaginator>;
  newsletter?: Maybe<Newsletter>;
  newsletters?: Maybe<NewsletterPaginator>;
  organisation?: Maybe<Organisation>;
  organisations: Array<Organisation>;
  overviewStats: OverviewStatsPayload;
  permission?: Maybe<Permission>;
  permissions: Array<Permission>;
  role?: Maybe<Role>;
  roles: Array<Role>;
  signup?: Maybe<Signup>;
  soul?: Maybe<Soul>;
  souls?: Maybe<SoulPaginator>;
  tag?: Maybe<Tag>;
  tags: Array<Tag>;
  targetGroupStats: Array<TargetGroupStatsPayload>;
  tenant?: Maybe<Tenant>;
};


export type QueryAllotmentArgs = {
  id: Scalars['ID'];
};


export type QueryCategoriesArgs = {
  trashed?: Maybe<Trashed>;
};


export type QueryCategoryArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryCategoryStatsArgs = {
  input: CategoryStatsInput;
};


export type QueryCompanionArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryCompanionsArgs = {
  status?: Maybe<UserStatus>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryContactsArgs = {
  trashed?: Maybe<Trashed>;
};


export type QueryDonorArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryDonorsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryEmailArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryEmployeeArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryEmployeesArgs = {
  status?: Maybe<UserStatus>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryEventArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryEventsArgs = {
  status?: Maybe<EventStatus>;
  donor?: Maybe<Scalars['Int']>;
  start?: Maybe<DateTimeRange>;
  archived?: Maybe<Archived>;
  orderBy?: Maybe<Array<QueryEventsOrderByOrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryLocationsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryNewsletterArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryNewslettersArgs = {
  sent_at?: Maybe<DateTimeRange>;
  orderBy?: Maybe<Array<OrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryOrganisationArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QueryOrganisationsArgs = {
  trashed?: Maybe<Trashed>;
};


export type QueryOverviewStatsArgs = {
  input: OverviewStatsInput;
};


export type QueryPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryRoleArgs = {
  id: Scalars['ID'];
};


export type QuerySignupArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QuerySoulArgs = {
  id: Scalars['ID'];
  trashed?: Maybe<Trashed>;
};


export type QuerySoulsArgs = {
  status?: Maybe<UserStatus>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTargetGroupStatsArgs = {
  input: TargetGroupStatsInput;
};


export type QueryTenantArgs = {
  id: Scalars['ID'];
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  icon: Scalars['String'];
  intent: Intent;
  description?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export enum Intent {
  None = 'none',
  Primary = 'primary',
  Success = 'success',
  Warning = 'warning',
  Danger = 'danger'
}


export enum UserStatus {
  All = 'ALL',
  New = 'NEW',
  Active = 'ACTIVE',
  Passive = 'PASSIVE'
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

/** A paginated list of Soul items. */
export type SoulPaginator = {
  __typename?: 'SoulPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Soul items. */
  data: Array<Soul>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type Soul = User & {
  __typename?: 'Soul';
  id: Scalars['ID'];
  status: UserStatus;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gdpr_consent: Scalars['Boolean'];
  gdpr_consent_source?: Maybe<GdprConsentSource>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta: SoulMeta;
  organisation?: Maybe<Organisation>;
  tags: Array<Tag>;
  signups?: Maybe<SignupPaginator>;
  emails?: Maybe<EmailPaginator>;
  future_signups?: Maybe<SignupPaginator>;
  attends?: Maybe<SignupPaginator>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type SoulOrganisationArgs = {
  trashed?: Maybe<Trashed>;
};


export type SoulSignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulFuture_SignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type SoulAttendsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type User = {
  id: Scalars['ID'];
  status: UserStatus;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export enum GdprConsentSource {
  Employee = 'EMPLOYEE',
  User = 'USER'
}

export type SoulMeta = {
  __typename?: 'SoulMeta';
  needs_drive: Scalars['Boolean'];
  buys_ticket: Scalars['Boolean'];
  needs_blind: Scalars['Boolean'];
  target_group: SoulTargetGroup;
  needs_wheelchair: Scalars['Boolean'];
  needs_wheeled_walker: Scalars['Boolean'];
  needs_trainstation_fetch: Scalars['Boolean'];
};

export enum SoulTargetGroup {
  Disabled = 'DISABLED',
  Child = 'CHILD',
  Immigrant = 'IMMIGRANT',
  Senior = 'SENIOR',
  SociallyInNeed = 'SOCIALLY_IN_NEED',
  None = 'NONE'
}

export type Organisation = {
  __typename?: 'Organisation';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  souls: Array<Soul>;
  companions: Array<Companion>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type Companion = User & {
  __typename?: 'Companion';
  id: Scalars['ID'];
  roles: Array<Role>;
  permissions: Array<Permission>;
  status: UserStatus;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gdpr_consent: Scalars['Boolean'];
  gdpr_consent_source?: Maybe<GdprConsentSource>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta: CompanionMeta;
  organisation?: Maybe<Organisation>;
  tags: Array<Tag>;
  signups?: Maybe<SignupPaginator>;
  emails?: Maybe<EmailPaginator>;
  future_signups?: Maybe<SignupPaginator>;
  attends?: Maybe<SignupPaginator>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type CompanionOrganisationArgs = {
  trashed?: Maybe<Trashed>;
};


export type CompanionSignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionFuture_SignupsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type CompanionAttendsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  permissions: Array<Permission>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name: Scalars['String'];
  title: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export type CompanionMeta = {
  __typename?: 'CompanionMeta';
  can_drive: Scalars['Boolean'];
  can_blind: Scalars['Boolean'];
  can_wheelchair: Scalars['Boolean'];
  can_wheeled_walker: Scalars['Boolean'];
  can_trainstation_fetch: Scalars['Boolean'];
};

/** A paginated list of Signup items. */
export type SignupPaginator = {
  __typename?: 'SignupPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Signup items. */
  data: Array<Signup>;
};

export type Signup = {
  __typename?: 'Signup';
  id: Scalars['ID'];
  event_id: Scalars['String'];
  is_candidate: Scalars['Boolean'];
  is_invited: Scalars['Boolean'];
  has_accepted: Scalars['Boolean'];
  has_rejected: Scalars['Boolean'];
  token: Scalars['String'];
  is_cardholder: Scalars['Boolean'];
  priority: Scalars['Int'];
  is_soul: Scalars['Boolean'];
  is_companion: Scalars['Boolean'];
  event: Event;
  soul?: Maybe<Soul>;
  companion?: Maybe<Companion>;
  conflicting_signups: Array<Signup>;
  is_first_attend: Scalars['Boolean'];
  memberSignups: Array<Signup>;
  leaderSignup?: Maybe<Signup>;
  emails: Array<Email>;
  notes?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type Event = {
  __typename?: 'Event';
  allotment?: Maybe<Allotment>;
  archived: Scalars['Boolean'];
  archived_at?: Maybe<Scalars['DateTime']>;
  attendeeCount: Scalars['Int'];
  attendees: Array<Signup>;
  available_tickets?: Maybe<Scalars['Int']>;
  candidateCount: Scalars['Int'];
  candidates: Array<Signup>;
  candidates_chosen: Scalars['Boolean'];
  cardholder?: Maybe<Companion>;
  categories: Array<Category>;
  contact?: Maybe<Contact>;
  created_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  donor?: Maybe<Donor>;
  end?: Maybe<Scalars['DateTime']>;
  groups: Array<Signup>;
  id: Scalars['ID'];
  inviteeCount: Scalars['Int'];
  invitees: Array<Signup>;
  location: Location;
  loneCompanions: Array<Signup>;
  loneSouls: Array<Signup>;
  name: Scalars['String'];
  needsInvite: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  prospectCount: Scalars['Int'];
  prospects: Array<Signup>;
  reservation_number?: Maybe<Scalars['String']>;
  signups: Array<Signup>;
  slug: Scalars['String'];
  start: Scalars['DateTime'];
  status: EventStatus;
  taken_tickets: Scalars['Int'];
  teams: Array<Signup>;
  ticket_retrieval_location?: Maybe<Scalars['String']>;
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>;
  ticket_time: Scalars['Int'];
  ticket_type: EventTicketType;
  tickets?: Maybe<Scalars['Int']>;
  updated_at: Scalars['DateTime'];
};


export type EventCardholderArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventCategoriesArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventContactArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventDonorArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventLocationArgs = {
  trashed?: Maybe<Trashed>;
};


export type EventSignupsArgs = {
  trashed?: Maybe<Trashed>;
};

export enum EventStatus {
  Draft = 'DRAFT',
  Ready = 'READY',
  Published = 'PUBLISHED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export enum EventTicketType {
  Fixed = 'FIXED',
  Allotment = 'ALLOTMENT',
  Unlimited = 'UNLIMITED'
}

export enum EventTicketRetrievalType {
  Onsite = 'ONSITE',
  Delivery = 'DELIVERY'
}

export type Donor = {
  __typename?: 'Donor';
  id: Scalars['ID'];
  name: Scalars['String'];
  public: Scalars['Boolean'];
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  contacts: Array<Contact>;
  locations: Array<Location>;
  logo?: Maybe<Media>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type DonorContactsArgs = {
  trashed?: Maybe<Trashed>;
};


export type DonorLocationsArgs = {
  trashed?: Maybe<Trashed>;
};

export type Contact = User & {
  __typename?: 'Contact';
  id: Scalars['ID'];
  status: UserStatus;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  donor_id: Scalars['ID'];
  emails?: Maybe<EmailPaginator>;
  donor: Donor;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type ContactEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type ContactDonorArgs = {
  trashed?: Maybe<Trashed>;
};

/** A paginated list of Email items. */
export type EmailPaginator = {
  __typename?: 'EmailPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Email items. */
  data: Array<Email>;
};

export type Email = {
  __typename?: 'Email';
  id: Scalars['ID'];
  status: EmailStatus;
  reason?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  sent_at?: Maybe<Scalars['DateTime']>;
  opened_first_at?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export enum EmailStatus {
  Queued = 'QUEUED',
  Scheduled = 'SCHEDULED',
  Sent = 'SENT',
  Delivered = 'DELIVERED',
  Opened = 'OPENED',
  Rejected = 'REJECTED',
  Invalid = 'INVALID',
  SoftBounced = 'SOFT_BOUNCED',
  HardBounced = 'HARD_BOUNCED',
  MarkedSpam = 'MARKED_SPAM',
  Delayed = 'DELAYED',
  Unknown = 'UNKNOWN'
}

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postal_code: Scalars['String'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  meeting_point: Scalars['String'];
  public_transport?: Maybe<Scalars['String']>;
  accessible: Scalars['Boolean'];
  donors: Array<Donor>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export type Media = {
  __typename?: 'Media';
  id: Scalars['ID'];
  name: Scalars['String'];
  url: Scalars['String'];
  thumb_url?: Maybe<Scalars['String']>;
};

export type Allotment = {
  __typename?: 'Allotment';
  id: Scalars['ID'];
  name: Scalars['String'];
  status: AllotmentStatus;
  amount: Scalars['Int'];
  amount_left: Scalars['Int'];
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};

export enum AllotmentStatus {
  Planned = 'PLANNED',
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Depleted = 'DEPLETED'
}

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color: Scalars['String'];
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

/** A paginated list of Companion items. */
export type CompanionPaginator = {
  __typename?: 'CompanionPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Companion items. */
  data: Array<Companion>;
};

/** A paginated list of Employee items. */
export type EmployeePaginator = {
  __typename?: 'EmployeePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Employee items. */
  data: Array<Employee>;
};

export type Employee = User & {
  __typename?: 'Employee';
  id: Scalars['ID'];
  roles: Array<Role>;
  permissions: Array<Permission>;
  status: UserStatus;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  display_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lng?: Maybe<Scalars['Float']>;
  phone?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta: EmployeeMeta;
  emails?: Maybe<EmailPaginator>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};


export type EmployeeEmailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};

export type EmployeeMeta = {
  __typename?: 'EmployeeMeta';
  position: Scalars['String'];
};

/** A paginated list of Donor items. */
export type DonorPaginator = {
  __typename?: 'DonorPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Donor items. */
  data: Array<Donor>;
};

/** A paginated list of Location items. */
export type LocationPaginator = {
  __typename?: 'LocationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Location items. */
  data: Array<Location>;
};

export type DateTimeRange = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export enum Archived {
  /** Only return archived results. */
  Only = 'ONLY',
  /** Return both archived and non-archived results. */
  With = 'WITH',
  /** Only return non-archived results. */
  Without = 'WITHOUT'
}

/** Order by clause for the `orderBy` argument on the query `events`. */
export type QueryEventsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryEventsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on field `events` on type `Query`. */
export enum QueryEventsOrderByColumn {
  Start = 'START'
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** A paginated list of Event items. */
export type EventPaginator = {
  __typename?: 'EventPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Event items. */
  data: Array<Event>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** A paginated list of Newsletter items. */
export type NewsletterPaginator = {
  __typename?: 'NewsletterPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Newsletter items. */
  data: Array<Newsletter>;
};

export type Newsletter = {
  __typename?: 'Newsletter';
  id: Scalars['ID'];
  status: NewsletterStatus;
  subject: Scalars['String'];
  body: Scalars['String'];
  segment: MailcoachSegment;
  sent_at?: Maybe<Scalars['DateTime']>;
  events: Array<Event>;
  created_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  deleted_at?: Maybe<Scalars['DateTime']>;
};

export enum NewsletterStatus {
  Draft = 'DRAFT',
  Sending = 'SENDING',
  Sent = 'SENT'
}

export enum MailcoachSegment {
  Active = 'ACTIVE',
  Souls = 'SOULS',
  Companions = 'COMPANIONS',
  All = 'ALL'
}

export type Tenant = {
  __typename?: 'Tenant';
  id: Scalars['ID'];
  name: Scalars['String'];
  subdomain: Scalars['String'];
  legacy_api_key: Scalars['String'];
  use_legacy_newsletter: Scalars['Boolean'];
  redirect_events?: Maybe<Scalars['String']>;
  redirect_ticket_accept_ok?: Maybe<Scalars['String']>;
  redirect_ticket_accept_not_found?: Maybe<Scalars['String']>;
  redirect_password_reset?: Maybe<Scalars['String']>;
};

export type OverviewStatsInput = {
  range_type: StatsRangeType;
  range: Scalars['String'];
};

export enum StatsRangeType {
  Year = 'YEAR',
  Month = 'MONTH'
}

export type OverviewStatsPayload = {
  __typename?: 'OverviewStatsPayload';
  tickets: Scalars['Int'];
  signups: Scalars['Int'];
  attends: Scalars['Int'];
};

export type TargetGroupStatsInput = {
  range_type: StatsRangeType;
  range: Scalars['String'];
  categories?: Maybe<Array<Scalars['ID']>>;
};

export type TargetGroupStatsPayload = {
  __typename?: 'TargetGroupStatsPayload';
  target_group: SoulTargetGroup;
  data: TargetGroupDataPayload;
};

export type TargetGroupDataPayload = {
  __typename?: 'TargetGroupDataPayload';
  signups: Scalars['Int'];
  attends: Scalars['Int'];
  previous_signups: Scalars['Int'];
  previous_attends: Scalars['Int'];
};

export type CategoryStatsInput = {
  range_type: StatsRangeType;
  range: Scalars['String'];
  target_groups?: Maybe<Array<SoulTargetGroup>>;
};

export type CategoryStatsPayload = {
  __typename?: 'CategoryStatsPayload';
  category: Category;
  data: CategoryDataPayload;
};

export type CategoryDataPayload = {
  __typename?: 'CategoryDataPayload';
  tickets: Scalars['Int'];
  signups: Scalars['Int'];
  previous_tickets: Scalars['Int'];
  previous_signups: Scalars['Int'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: AuthPayload;
  logout: LogoutResponse;
  forgotPassword: ForgotPasswordResponse;
  updateForgottenPassword: ForgotPasswordResponse;
  createTag: Tag;
  updateTag?: Maybe<Tag>;
  deleteTag?: Maybe<Tag>;
  emailUsers?: Maybe<Scalars['Boolean']>;
  sendResetLinkForUser?: Maybe<Scalars['Boolean']>;
  createSoul: Soul;
  approveSoul: Soul;
  denySoul: Soul;
  activateSoul: Soul;
  deactivateSoul: Soul;
  updateSoul?: Maybe<Soul>;
  deleteSoul: Soul;
  restoreSouls: Array<Maybe<Soul>>;
  forceDeleteSouls: Array<Maybe<Soul>>;
  emptySoulTrash: Array<Maybe<Soul>>;
  createCompanion: Companion;
  approveCompanion: Companion;
  denyCompanion: Companion;
  activateCompanion: Companion;
  deactivateCompanion: Companion;
  updateCompanion?: Maybe<Companion>;
  deleteCompanion: Companion;
  restoreCompanions: Array<Maybe<Companion>>;
  forceDeleteCompanions: Array<Maybe<Companion>>;
  emptyCompanionTrash: Array<Maybe<Companion>>;
  createEmployee: Employee;
  updateEmployee?: Maybe<Employee>;
  deleteEmployee: Employee;
  restoreEmployees: Array<Maybe<Employee>>;
  forceDeleteEmployees: Array<Maybe<Employee>>;
  emptyEmployeeTrash: Array<Maybe<Employee>>;
  createOrganisation: Organisation;
  updateOrganisation?: Maybe<Organisation>;
  deleteOrganisation: Organisation;
  restoreOrganisations: Array<Maybe<Organisation>>;
  forceDeleteOrganisations: Array<Maybe<Organisation>>;
  emptyOrganisationTrash: Array<Maybe<Organisation>>;
  createDonor: Donor;
  updateDonor?: Maybe<Donor>;
  setDonorLogo?: Maybe<Donor>;
  deleteDonor: Donor;
  restoreDonors: Array<Maybe<Donor>>;
  forceDeleteDonors: Array<Maybe<Donor>>;
  emptyDonorTrash: Array<Maybe<Donor>>;
  createLocation: Location;
  updateLocation?: Maybe<Location>;
  deleteLocation: Location;
  restoreLocations: Array<Maybe<Location>>;
  forceDeleteLocations: Array<Maybe<Location>>;
  emptyLocationTrash: Array<Maybe<Location>>;
  createContact: Contact;
  updateContact?: Maybe<Contact>;
  deleteContact: Contact;
  restoreContacts: Array<Maybe<Contact>>;
  forceDeleteContacts: Array<Maybe<Contact>>;
  emptyContactTrash: Array<Maybe<Contact>>;
  createRole: Role;
  updateRole?: Maybe<Role>;
  deleteRole?: Maybe<Role>;
  createCategory: Category;
  updateCategory?: Maybe<Category>;
  deleteCategory?: Maybe<Category>;
  restoreCategories: Array<Maybe<Category>>;
  forceDeleteCategories: Array<Maybe<Category>>;
  emptyCategoryTrash: Array<Maybe<Category>>;
  createAllotment: Allotment;
  updateAllotment?: Maybe<Allotment>;
  deleteAllotment?: Maybe<Allotment>;
  deleteSignup?: Maybe<Signup>;
  createEvent: Event;
  updateEvent?: Maybe<Event>;
  cancelEvent?: Maybe<Event>;
  publishEvent?: Maybe<Event>;
  acceptSignups?: Maybe<Event>;
  addSignups?: Maybe<Event>;
  updateCandidates?: Maybe<Event>;
  inviteCandidates?: Maybe<Event>;
  deleteEvent?: Maybe<Event>;
  restoreEvents: Array<Maybe<Event>>;
  forceDeleteEvents: Array<Maybe<Event>>;
  emptyEventTrash: Array<Maybe<Event>>;
  createNewsletter: Newsletter;
  deleteNewsletter?: Maybe<Newsletter>;
  updateTenant?: Maybe<Tenant>;
  regenerateApiKey: Tenant;
};


export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


export type MutationUpdateForgottenPasswordArgs = {
  input?: Maybe<NewPasswordWithCodeInput>;
};


export type MutationCreateTagArgs = {
  input: CreateTagInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationEmailUsersArgs = {
  input: EmailUsersInput;
};


export type MutationSendResetLinkForUserArgs = {
  id: Scalars['ID'];
};


export type MutationCreateSoulArgs = {
  input: CreateSoulInput;
};


export type MutationApproveSoulArgs = {
  id: Scalars['ID'];
};


export type MutationDenySoulArgs = {
  id: Scalars['ID'];
};


export type MutationActivateSoulArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateSoulArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateSoulArgs = {
  input: UpdateSoulInput;
};


export type MutationDeleteSoulArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreSoulsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteSoulsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateCompanionArgs = {
  input: CreateCompanionInput;
};


export type MutationApproveCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationDenyCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationActivateCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationDeactivateCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCompanionArgs = {
  input: UpdateCompanionInput;
};


export type MutationDeleteCompanionArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreCompanionsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteCompanionsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput;
};


export type MutationDeleteEmployeeArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreEmployeesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteEmployeesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};


export type MutationUpdateOrganisationArgs = {
  input: UpdateOrganisationInput;
};


export type MutationDeleteOrganisationArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreOrganisationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteOrganisationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateDonorArgs = {
  input: CreateDonorInput;
};


export type MutationUpdateDonorArgs = {
  input: UpdateDonorInput;
};


export type MutationSetDonorLogoArgs = {
  input: SetDonorLogoInput;
};


export type MutationDeleteDonorArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreDonorsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteDonorsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreLocationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteLocationsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateContactArgs = {
  input: CreateContactInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreContactsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteContactsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationDeleteRoleArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationUpdateCategoryArgs = {
  input: UpdateCategoryInput;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteCategoriesArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateAllotmentArgs = {
  input: CreateAllotmentInput;
};


export type MutationUpdateAllotmentArgs = {
  input: UpdateAllotmentInput;
};


export type MutationDeleteAllotmentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSignupArgs = {
  id: Scalars['ID'];
};


export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


export type MutationUpdateEventArgs = {
  input: UpdateEventInput;
};


export type MutationCancelEventArgs = {
  input: CancelEventInput;
};


export type MutationPublishEventArgs = {
  id: Scalars['ID'];
};


export type MutationAcceptSignupsArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type MutationAddSignupsArgs = {
  input: AddSignupsInput;
};


export type MutationUpdateCandidatesArgs = {
  input: UpdateCandidatesInput;
};


export type MutationInviteCandidatesArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEventArgs = {
  id: Scalars['ID'];
};


export type MutationRestoreEventsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationForceDeleteEventsArgs = {
  ids: Array<Scalars['ID']>;
};


export type MutationCreateNewsletterArgs = {
  input: CreateNewsletterInput;
};


export type MutationDeleteNewsletterArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};


export type MutationRegenerateApiKeyArgs = {
  id: Scalars['ID'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user: Employee;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type NewPasswordWithCodeInput = {
  email: Scalars['String'];
  token: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type CreateTagInput = {
  name: Scalars['String'];
  icon: Scalars['String'];
  intent: Intent;
  description?: Maybe<Scalars['String']>;
};

export type UpdateTagInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  intent?: Maybe<Intent>;
  description?: Maybe<Scalars['String']>;
};

export type EmailUsersInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  users: Array<Scalars['String']>;
};

export type CreateSoulInput = {
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gdpr_consent?: Maybe<Scalars['Boolean']>;
  gdpr_consent_source?: Maybe<GdprConsentSource>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta: SoulMetaInput;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type SoulMetaInput = {
  needs_drive: Scalars['Boolean'];
  buys_ticket: Scalars['Boolean'];
  needs_blind: Scalars['Boolean'];
  target_group: SoulTargetGroup;
  needs_wheelchair: Scalars['Boolean'];
  needs_wheeled_walker: Scalars['Boolean'];
  needs_trainstation_fetch: Scalars['Boolean'];
};

export type UpdateTagsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateOrganisationRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateSoulInput = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gdpr_consent?: Maybe<Scalars['Boolean']>;
  gdpr_consent_source?: Maybe<GdprConsentSource>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<SoulMetaInput>;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type CreateCompanionInput = {
  roles?: Maybe<UpdateRolesRelation>;
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  gdpr_consent?: Maybe<Scalars['Boolean']>;
  gdpr_consent_source?: Maybe<GdprConsentSource>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta: CompanionMetaInput;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type UpdateRolesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CompanionMetaInput = {
  can_drive: Scalars['Boolean'];
  can_blind: Scalars['Boolean'];
  can_wheelchair: Scalars['Boolean'];
  can_wheeled_walker: Scalars['Boolean'];
  can_trainstation_fetch: Scalars['Boolean'];
};

export type UpdateCompanionInput = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gdpr_consent?: Maybe<Scalars['Boolean']>;
  gdpr_consent_source?: Maybe<GdprConsentSource>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<CompanionMetaInput>;
  tags?: Maybe<UpdateTagsRelation>;
  organisation?: Maybe<UpdateOrganisationRelation>;
};

export type CreateEmployeeInput = {
  roles?: Maybe<UpdateRolesRelation>;
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta: EmployeeMetaInput;
};

export type EmployeeMetaInput = {
  position: Scalars['String'];
};

export type UpdateEmployeeInput = {
  id: Scalars['ID'];
  roles?: Maybe<UpdateRolesRelation>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  meta?: Maybe<EmployeeMetaInput>;
};

export type CreateOrganisationInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  souls?: Maybe<UpdateOrganisationSoulRelation>;
  companions?: Maybe<UpdateOrganisationCompanionRelation>;
};

export type UpdateOrganisationSoulRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateOrganisationCompanionRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateOrganisationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  souls?: Maybe<UpdateOrganisationSoulRelation>;
  companions?: Maybe<UpdateOrganisationCompanionRelation>;
};

export type CreateDonorInput = {
  name: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  locations: UpdateLocationsRelation;
};

export type UpdateLocationsRelation = {
  create?: Maybe<Array<CreateLocationInput>>;
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type CreateLocationInput = {
  name: Scalars['String'];
  street: Scalars['String'];
  city: Scalars['String'];
  postal_code: Scalars['String'];
  meeting_point: Scalars['String'];
  public_transport?: Maybe<Scalars['String']>;
  accessible: Scalars['Boolean'];
  donors: UpdateDonorsRelation;
};

export type UpdateDonorsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateDonorInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  public?: Maybe<Scalars['Boolean']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  locations?: Maybe<UpdateLocationsRelation>;
};

export type SetDonorLogoInput = {
  id: Scalars['ID'];
  logo: UpdateMediaInput;
};

export type UpdateMediaInput = {
  create?: Maybe<CreateMediaInput>;
  delete?: Maybe<Scalars['Boolean']>;
};

export type CreateMediaInput = {
  file_path: Scalars['String'];
  file_name: Scalars['String'];
};

export type UpdateLocationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  meeting_point?: Maybe<Scalars['String']>;
  public_transport?: Maybe<Scalars['String']>;
  accessible?: Maybe<Scalars['Boolean']>;
  donors?: Maybe<UpdateDonorsRelation>;
};

export type CreateContactInput = {
  donor_id: Scalars['ID'];
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
};

export type UpdateContactInput = {
  id: Scalars['ID'];
  donor_id?: Maybe<Scalars['ID']>;
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type CreateRoleInput = {
  name: Scalars['String'];
  permissions?: Maybe<UpdatePermissionsRelation>;
};

export type UpdatePermissionsRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateRoleInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<UpdatePermissionsRelation>;
};

export type CreateCategoryInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  color: Scalars['String'];
};

export type UpdateCategoryInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
};

export type CreateAllotmentInput = {
  name: Scalars['String'];
  amount: Scalars['Int'];
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
};

export type UpdateAllotmentInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};

export type CreateEventInput = {
  name: Scalars['String'];
  status: EventStatus;
  description?: Maybe<Scalars['String']>;
  start: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
  ticket_type: EventTicketType;
  tickets?: Maybe<Scalars['Int']>;
  ticket_time?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['String']>;
  ticket_retrieval_location?: Maybe<Scalars['String']>;
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>;
  notes?: Maybe<Scalars['String']>;
  donor: UpdateDonorRelation;
  contact: UpdateContactRelation;
  location: UpdateLocationRelation;
  allotment?: Maybe<UpdateAllotmentRelation>;
  categories?: Maybe<UpdateCategoriesRelation>;
};

export type UpdateDonorRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateContactRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateLocationRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateAllotmentRelation = {
  connect?: Maybe<Scalars['ID']>;
  disconnect?: Maybe<Scalars['Boolean']>;
};

export type UpdateCategoriesRelation = {
  sync?: Maybe<Array<Scalars['ID']>>;
};

export type UpdateEventInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<EventStatus>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  ticket_type?: Maybe<EventTicketType>;
  tickets?: Maybe<Scalars['Int']>;
  ticket_time?: Maybe<Scalars['Int']>;
  reservation_number?: Maybe<Scalars['String']>;
  ticket_retrieval_location?: Maybe<Scalars['String']>;
  ticket_retrieval_type?: Maybe<EventTicketRetrievalType>;
  notes?: Maybe<Scalars['String']>;
  donor?: Maybe<UpdateDonorRelation>;
  contact?: Maybe<UpdateContactRelation>;
  location?: Maybe<UpdateLocationRelation>;
  allotment?: Maybe<UpdateAllotmentRelation>;
  categories?: Maybe<UpdateCategoriesRelation>;
};

export type CancelEventInput = {
  id: Scalars['ID'];
  reason: Scalars['String'];
};

export type AddSignupsInput = {
  eventId: Scalars['ID'];
  loneSouls?: Maybe<Array<Scalars['ID']>>;
  loneCompanions?: Maybe<Array<Scalars['ID']>>;
  group?: Maybe<AddGroupSignupInput>;
  notes?: Maybe<Scalars['String']>;
};

export type AddGroupSignupInput = {
  companion: Scalars['ID'];
  souls: Array<Scalars['ID']>;
};

export type UpdateCandidatesInput = {
  id: Scalars['ID'];
  candidates: CandidatesInput;
};

export type CandidatesInput = {
  cardholder: Scalars['ID'];
  groups: Array<GroupInput>;
  teams: Array<GroupInput>;
  souls: Array<Scalars['ID']>;
  companions: Array<Scalars['ID']>;
};

export type GroupInput = {
  companion: Scalars['ID'];
  souls: Array<Scalars['ID']>;
};

export type CreateNewsletterInput = {
  subject: Scalars['String'];
  body: Scalars['String'];
  segment: MailcoachSegment;
  events?: Maybe<UpdateEventsRelation>;
};

export type UpdateEventsRelation = {
  sync: Array<Scalars['ID']>;
};

export type UpdateTenantInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  subdomain?: Maybe<Scalars['String']>;
  redirect_events?: Maybe<Scalars['String']>;
  redirect_ticket_accept_ok?: Maybe<Scalars['String']>;
  redirect_ticket_accept_not_found?: Maybe<Scalars['String']>;
  redirect_password_reset?: Maybe<Scalars['String']>;
};

export type RegisterInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type CreateUserInput = {
  status?: Maybe<UserStatus>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  birthdate?: Maybe<Scalars['Date']>;
  street?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  mobile?: Maybe<Scalars['String']>;
};

export type Applicant = Companion | Soul;

export type UpdateNewsletterInput = {
  id: Scalars['ID'];
  subject: Scalars['String'];
  body: Scalars['String'];
  segment: MailcoachSegment;
  events?: Maybe<UpdateEventsRelation>;
};

export type DateRange = {
  from: Scalars['Date'];
  to: Scalars['Date'];
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

export type AttendListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id'>
  & { event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'start'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'color'>
    )> }
  ), leaderSignup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { companion?: Maybe<(
      { __typename?: 'Companion' }
      & Pick<Companion, 'id' | 'display_name'>
    )> }
  )>, memberSignups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { soul?: Maybe<(
      { __typename?: 'Soul' }
      & Pick<Soul, 'id' | 'display_name'>
    )> }
  )> }
);

export type FutureSignupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status'>
  )>, event: (
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name' | 'status' | 'candidates_chosen' | 'start'>
    & { categories: Array<(
      { __typename?: 'Category' }
      & Pick<Category, 'id' | 'name' | 'color'>
    )> }
  ) }
);

export type PersonOrganisationSelectFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name'>
);

export type PersonTagIconFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
);

export type PersonTagMultiSelectFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
);

export type AllotmentCreateFormFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'name' | 'amount' | 'start' | 'end'>
);

export type AllotmentEditFormFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentListItemFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentDetailPageFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name' | 'status' | 'amount' | 'amount_left' | 'start' | 'end'>
);

export type AllotmentListQueryVariables = Exact<{ [key: string]: never; }>;


export type AllotmentListQuery = (
  { __typename?: 'Query' }
  & { allotments: Array<(
    { __typename?: 'Allotment' }
    & AllotmentListItemFragment
  )> }
);

export type AllotmentDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllotmentDetailQuery = (
  { __typename?: 'Query' }
  & { allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
    & AllotmentDetailPageFragment
  )> }
);

export type AllotmentEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AllotmentEditQuery = (
  { __typename?: 'Query' }
  & { allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
    & AllotmentEditFormFragment
  )> }
);

export type CreateAllotmentMutationVariables = Exact<{
  input: CreateAllotmentInput;
}>;


export type CreateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { createAllotment: (
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  ) }
);

export type UpdateAllotmentMutationVariables = Exact<{
  input: UpdateAllotmentInput;
}>;


export type UpdateAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAllotment?: Maybe<(
    { __typename?: 'Allotment' }
    & AllotmentDetailPageFragment
  )> }
);

export type DeleteAllotmentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAllotmentMutation = (
  { __typename?: 'Mutation' }
  & { deleteAllotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
  )> }
);

export type AttendeeGroupFragment = (
  { __typename?: 'Signup' }
  & { memberSignups: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )> }
  & AttendeeListItemFragment
);

export type AcceptSignupsFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'token' | 'has_accepted' | 'has_rejected'>
  )> }
);

export type AttendeeListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'is_soul' | 'has_accepted' | 'has_rejected' | 'notes' | 'is_first_attend'>
  & { emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status'>
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'notes'>
    & { meta: (
      { __typename?: 'CompanionMeta' }
      & Pick<CompanionMeta, 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'display_name' | 'email' | 'phone' | 'mobile' | 'notes'>
    & { meta: (
      { __typename?: 'SoulMeta' }
      & Pick<SoulMeta, 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type AttendeeLoneCompanionFragment = (
  { __typename?: 'Signup' }
  & AttendeeListItemFragment
);

export type AttendeeLoneSoulFragment = (
  { __typename?: 'Signup' }
  & AttendeeListItemFragment
);

export type AttendeeTeamFragment = (
  { __typename?: 'Signup' }
  & { memberSignups: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )> }
  & AttendeeListItemFragment
);

export type IsUserAuthenticatedQueryVariables = Exact<{ [key: string]: never; }>;


export type IsUserAuthenticatedQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isAuthenticated'>
);

export type CurrentTenantQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentTenantQuery = (
  { __typename?: 'Query' }
  & { currentTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id' | 'subdomain' | 'name' | 'use_legacy_newsletter'>
  ) }
);

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'AuthPayload' }
    & { user: (
      { __typename?: 'Employee' }
      & Pick<Employee, 'id' | 'email' | 'display_name'>
      & { permissions: Array<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'status' | 'message'>
  ) }
);

export type ForgotPasswordMutationVariables = Exact<{
  input: ForgotPasswordInput;
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & { forgotPassword: (
    { __typename?: 'ForgotPasswordResponse' }
    & Pick<ForgotPasswordResponse, 'status' | 'message'>
  ) }
);

export type UpdateForgottenPasswordMutationVariables = Exact<{
  input: NewPasswordWithCodeInput;
}>;


export type UpdateForgottenPasswordMutation = (
  { __typename?: 'Mutation' }
  & { updateForgottenPassword: (
    { __typename?: 'ForgotPasswordResponse' }
    & Pick<ForgotPasswordResponse, 'status' | 'message'>
  ) }
);

export type CategoryCreateFormFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'name' | 'description' | 'color'>
);

export type CategoryEditFormFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color'>
);

export type CategoryListItemFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color'>
);

export type CategoryDetailPageFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name' | 'description' | 'color'>
);

export type CategoryListQueryVariables = Exact<{
  trashed?: Maybe<Trashed>;
}>;


export type CategoryListQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryListItemFragment
  )> }
);

export type CategoryDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CategoryDetailQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
    & CategoryDetailPageFragment
  )> }
);

export type CategoryEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CategoryEditQuery = (
  { __typename?: 'Query' }
  & { category?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
    & CategoryEditFormFragment
  )> }
);

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
}>;


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  ) }
);

export type UpdateCategoryMutationVariables = Exact<{
  input: UpdateCategoryInput;
}>;


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory?: Maybe<(
    { __typename?: 'Category' }
    & CategoryDetailPageFragment
  )> }
);

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteCategory?: Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )> }
);

export type RestoreCategoriesMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { restoreCategories: Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>> }
);

export type ForceDeleteCategoriesMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteCategories: Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>> }
);

export type EmptyCategoryTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyCategoryTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyCategoryTrash: Array<Maybe<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>> }
);

export type CompanionCreateFormFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'notes' | 'first_name' | 'last_name' | 'status' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  & { meta: (
    { __typename?: 'CompanionMeta' }
    & Pick<CompanionMeta, 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind' | 'can_trainstation_fetch'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionEditFormFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { meta: (
    { __typename?: 'CompanionMeta' }
    & Pick<CompanionMeta, 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind' | 'can_trainstation_fetch'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionInfoFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'status' | 'display_name' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'gdpr_consent_source' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { meta: (
    { __typename?: 'CompanionMeta' }
    & Pick<CompanionMeta, 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type CompanionListItemFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'status' | 'display_name' | 'email' | 'phone' | 'mobile'>
  & { meta: (
    { __typename?: 'CompanionMeta' }
    & Pick<CompanionMeta, 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type CompanionListQueryVariables = Exact<{
  status?: Maybe<UserStatus>;
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
}>;


export type CompanionListQuery = (
  { __typename?: 'Query' }
  & { companions?: Maybe<(
    { __typename?: 'CompanionPaginator' }
    & { data: Array<(
      { __typename?: 'Companion' }
      & CompanionListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type CompanionDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanionDetailQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & CompanionInfoFragment
  )> }
);

export type CompanionEmailsQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type CompanionEmailsQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type CompanionEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanionEditQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & CompanionEditFormFragment
  )> }
);

export type CreateCompanionMutationVariables = Exact<{
  input: CreateCompanionInput;
}>;


export type CreateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { createCompanion: (
    { __typename?: 'Companion' }
    & CompanionInfoFragment
  ) }
);

export type UpdateCompanionMutationVariables = Exact<{
  input: UpdateCompanionInput;
}>;


export type UpdateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { updateCompanion?: Maybe<(
    { __typename?: 'Companion' }
    & CompanionInfoFragment
  )> }
);

export type ApproveCompanionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveCompanionMutation = (
  { __typename?: 'Mutation' }
  & { approveCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'status'>
  ) }
);

export type DenyCompanionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DenyCompanionMutation = (
  { __typename?: 'Mutation' }
  & { denyCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'status'>
  ) }
);

export type ActivateCompanionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { activateCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'status'>
  ) }
);

export type DeactivateCompanionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateCompanionMutation = (
  { __typename?: 'Mutation' }
  & { deactivateCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'status'>
  ) }
);

export type DeleteCompanionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanionMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompanion: (
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  ) }
);

export type RestoreCompanionsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreCompanionsMutation = (
  { __typename?: 'Mutation' }
  & { restoreCompanions: Array<Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )>> }
);

export type ForceDeleteCompanionsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteCompanionsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteCompanions: Array<Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )>> }
);

export type EmptyCompanionTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyCompanionTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyCompanionTrash: Array<Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )>> }
);

export type ContactCreateFormFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes' | 'donor_id'>
);

export type ContactEditFormFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
);

export type ContactInfoFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'display_name' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
);

export type ContactListItemFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
);

export type ContactListQueryVariables = Exact<{
  trashed?: Maybe<Trashed>;
}>;


export type ContactListQuery = (
  { __typename?: 'Query' }
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & ContactListItemFragment
  )> }
);

export type ContactDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContactDetailQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & ContactInfoFragment
  )> }
);

export type ContactEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContactEditQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & ContactEditFormFragment
  )> }
);

export type ContactEmailsQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type ContactEmailsQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type CreateContactMutationVariables = Exact<{
  input: CreateContactInput;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact: (
    { __typename?: 'Contact' }
    & ContactInfoFragment
  ) }
);

export type UpdateContactMutationVariables = Exact<{
  input: UpdateContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'Contact' }
    & ContactInfoFragment
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  ) }
);

export type RestoreContactsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreContactsMutation = (
  { __typename?: 'Mutation' }
  & { restoreContacts: Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  )>> }
);

export type ForceDeleteContactsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteContactsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteContacts: Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  )>> }
);

export type EmptyContactTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyContactTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyContactTrash: Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  )>> }
);

export type DonorCreateFormFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'name' | 'url'>
  )>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )> }
);

export type DonorEditFormFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'name' | 'url'>
  )>, locations: Array<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )> }
);

export type DonorInfoFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { logo?: Maybe<(
    { __typename?: 'Media' }
    & Pick<Media, 'name' | 'thumb_url'>
  )> }
);

export type DonorListItemFragment = (
  { __typename?: 'Donor' }
  & Pick<Donor, 'id' | 'name'>
  & { contacts: Array<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'display_name'>
  )> }
);

export type DonorListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
}>;


export type DonorListQuery = (
  { __typename?: 'Query' }
  & { donors?: Maybe<(
    { __typename?: 'DonorPaginator' }
    & { data: Array<(
      { __typename?: 'Donor' }
      & DonorListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type DonorDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorDetailQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & DonorInfoFragment
  )> }
);

export type DonorContactsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorContactsQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & ContactListItemFragment
    )> }
  )> }
);

export type DonorContactSelectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorContactSelectQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & ContactSelectFragment
    )> }
  )> }
);

export type DonorLocationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorLocationsQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & LocationListItemFragment
    )> }
  )> }
);

export type DonorLocationSelectQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorLocationSelectQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { locations: Array<(
      { __typename?: 'Location' }
      & LocationSelectFragment
    )> }
  )> }
);

export type CreateDonorMutationVariables = Exact<{
  input: CreateDonorInput;
}>;


export type CreateDonorMutation = (
  { __typename?: 'Mutation' }
  & { createDonor: (
    { __typename?: 'Donor' }
    & DonorInfoFragment
  ) }
);

export type DonorEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DonorEditQuery = (
  { __typename?: 'Query' }
  & { donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & DonorEditFormFragment
  )> }
);

export type UpdateDonorMutationVariables = Exact<{
  input: UpdateDonorInput;
}>;


export type UpdateDonorMutation = (
  { __typename?: 'Mutation' }
  & { updateDonor?: Maybe<(
    { __typename?: 'Donor' }
    & DonorInfoFragment
  )> }
);

export type SetDonorLogoMutationVariables = Exact<{
  input: SetDonorLogoInput;
}>;


export type SetDonorLogoMutation = (
  { __typename?: 'Mutation' }
  & { setDonorLogo?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
    & { logo?: Maybe<(
      { __typename?: 'Media' }
      & Pick<Media, 'name' | 'thumb_url'>
    )> }
  )> }
);

export type DeleteDonorMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDonorMutation = (
  { __typename?: 'Mutation' }
  & { deleteDonor: (
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  ) }
);

export type RestoreDonorsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreDonorsMutation = (
  { __typename?: 'Mutation' }
  & { restoreDonors: Array<Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )>> }
);

export type ForceDeleteDonorsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteDonorsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteDonors: Array<Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )>> }
);

export type EmptyDonorTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyDonorTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyDonorTrash: Array<Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )>> }
);

export type EmailListItemFragment = (
  { __typename?: 'Email' }
  & Pick<Email, 'id' | 'status' | 'subject' | 'sent_at'>
);

export type EmailOverlayFragment = (
  { __typename?: 'Email' }
  & Pick<Email, 'id' | 'status' | 'reason' | 'subject' | 'sent_at' | 'body'>
);

export type EmailDetailsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmailDetailsQuery = (
  { __typename?: 'Query' }
  & { email?: Maybe<(
    { __typename?: 'Email' }
    & Pick<Email, 'id'>
    & EmailOverlayFragment
  )> }
);

export type EmployeeCreateFormFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'status' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
  )>, meta: (
    { __typename?: 'EmployeeMeta' }
    & Pick<EmployeeMeta, 'position'>
  ) }
);

export type EmployeeEditFormFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'status' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>, meta: (
    { __typename?: 'EmployeeMeta' }
    & Pick<EmployeeMeta, 'position'>
  ) }
);

export type EmployeeInfoFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'display_name' | 'first_name' | 'last_name' | 'email' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>, meta: (
    { __typename?: 'EmployeeMeta' }
    & Pick<EmployeeMeta, 'position'>
  ) }
);

export type EmployeeListItemFragment = (
  { __typename?: 'Employee' }
  & Pick<Employee, 'id' | 'display_name' | 'email' | 'phone' | 'mobile'>
  & { roles: Array<(
    { __typename?: 'Role' }
    & Pick<Role, 'id' | 'name'>
  )>, meta: (
    { __typename?: 'EmployeeMeta' }
    & Pick<EmployeeMeta, 'position'>
  ) }
);

export type RoleMultiSelectFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
);

export type EmployeeListQueryVariables = Exact<{
  status?: Maybe<UserStatus>;
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
}>;


export type EmployeeListQuery = (
  { __typename?: 'Query' }
  & { employees?: Maybe<(
    { __typename?: 'EmployeePaginator' }
    & { data: Array<(
      { __typename?: 'Employee' }
      & EmployeeListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type EmployeeDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmployeeDetailQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeInfoFragment
  )> }
);

export type EmployeeEmailsQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type EmployeeEmailsQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type EmployeeEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EmployeeEditQuery = (
  { __typename?: 'Query' }
  & { employee?: Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
    & EmployeeEditFormFragment
  )> }
);

export type CreateEmployeeMutationVariables = Exact<{
  input: CreateEmployeeInput;
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee: (
    { __typename?: 'Employee' }
    & EmployeeInfoFragment
  ) }
);

export type UpdateEmployeeMutationVariables = Exact<{
  input: UpdateEmployeeInput;
}>;


export type UpdateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { updateEmployee?: Maybe<(
    { __typename?: 'Employee' }
    & EmployeeInfoFragment
  )> }
);

export type DeleteEmployeeMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { deleteEmployee: (
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  ) }
);

export type RestoreEmployeesMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreEmployeesMutation = (
  { __typename?: 'Mutation' }
  & { restoreEmployees: Array<Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>> }
);

export type ForceDeleteEmployeesMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteEmployeesMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteEmployees: Array<Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>> }
);

export type EmptyEmployeeTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyEmployeeTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyEmployeeTrash: Array<Maybe<(
    { __typename?: 'Employee' }
    & Pick<Employee, 'id'>
  )>> }
);

export type AllotmentSelectFragment = (
  { __typename?: 'Allotment' }
  & Pick<Allotment, 'id' | 'name'>
);

export type CategoryMultiSelectFragment = (
  { __typename?: 'Category' }
  & Pick<Category, 'id' | 'name'>
);

export type ContactSelectFragment = (
  { __typename?: 'Contact' }
  & Pick<Contact, 'id' | 'display_name'>
);

export type EventCreateFormFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'name' | 'status' | 'description' | 'start' | 'end' | 'tickets' | 'ticket_time' | 'ticket_type' | 'reservation_number' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'notes'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id'>
  )>, donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )>, contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id'>
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ), allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id'>
  )> }
);

export type EventEditFormFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'description' | 'start' | 'end' | 'tickets' | 'ticket_time' | 'ticket_type' | 'reservation_number' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'notes'>
  & { categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name'>
  )>, donor?: Maybe<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'name'>
  )>, contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'display_name'>
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ), allotment?: Maybe<(
    { __typename?: 'Allotment' }
    & Pick<Allotment, 'id' | 'name'>
  )> }
);

export type EventListItemFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'available_tickets' | 'taken_tickets' | 'archived' | 'candidateCount' | 'attendeeCount'>
  & { attendees: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'is_soul' | 'has_accepted' | 'has_rejected'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'is_candidate' | 'is_invited' | 'is_soul' | 'has_accepted' | 'has_rejected'>
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible'>
  ) }
);

export type LocationSelectFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name'>
);

export type RemoveSignupFragment = (
  { __typename?: 'Event' }
  & { prospects: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )> }
);

export type SignupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'notes'>
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'notes'>
    & { meta: (
      { __typename?: 'CompanionMeta' }
      & Pick<CompanionMeta, 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'display_name' | 'notes'>
    & { meta: (
      { __typename?: 'SoulMeta' }
      & Pick<SoulMeta, 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type EventDetailPageFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'notes' | 'description' | 'taken_tickets' | 'available_tickets' | 'candidates_chosen' | 'ticket_type' | 'ticket_time' | 'ticket_retrieval_type' | 'ticket_retrieval_location' | 'reservation_number' | 'archived' | 'needsInvite' | 'candidateCount' | 'attendeeCount'>
  & { prospects: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, candidates: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, attendees: Array<(
    { __typename?: 'Signup' }
    & AttendeeListItemFragment
  )>, groups: Array<(
    { __typename?: 'Signup' }
    & AttendeeGroupFragment
  )>, teams: Array<(
    { __typename?: 'Signup' }
    & AttendeeTeamFragment
  )>, loneSouls: Array<(
    { __typename?: 'Signup' }
    & AttendeeLoneSoulFragment
  )>, loneCompanions: Array<(
    { __typename?: 'Signup' }
    & AttendeeLoneCompanionFragment
  )>, cardholder?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & { memberSignups: Array<(
      { __typename?: 'Signup' }
      & AttendeeListItemFragment
    )>, leaderSignup?: Maybe<(
      { __typename?: 'Signup' }
      & AttendeeListItemFragment
    )> }
    & AttendeeListItemFragment
  )>, contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible' | 'meeting_point' | 'public_transport' | 'street' | 'postal_code' | 'city'>
  ), categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  )> }
);

export type EventListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  status?: Maybe<EventStatus>;
  start?: Maybe<DateTimeRange>;
  donor?: Maybe<Scalars['Int']>;
  archived?: Maybe<Archived>;
  orderByColumn?: Maybe<QueryEventsOrderByColumn>;
  orderByOrder?: Maybe<SortOrder>;
  trashed?: Maybe<Trashed>;
}>;


export type EventListQuery = (
  { __typename?: 'Query' }
  & { events?: Maybe<(
    { __typename?: 'EventPaginator' }
    & { data: Array<(
      { __typename?: 'Event' }
      & EventListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type EventDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EventDetailQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & EventDetailPageFragment
  )> }
);

export type EventEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EventEditQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & EventEditFormFragment
  )> }
);

export type UpdateEventMutationVariables = Exact<{
  input: UpdateEventInput;
}>;


export type UpdateEventMutation = (
  { __typename?: 'Mutation' }
  & { updateEvent?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CancelEventMutationVariables = Exact<{
  input: CancelEventInput;
}>;


export type CancelEventMutation = (
  { __typename?: 'Mutation' }
  & { cancelEvent?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CreateEventMutationVariables = Exact<{
  input: CreateEventInput;
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'Event' }
    & EventDetailPageFragment
  ) }
);

export type PublishEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PublishEventMutation = (
  { __typename?: 'Mutation' }
  & { publishEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'status'>
  )> }
);

export type DeleteSignupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSignupMutation = (
  { __typename?: 'Mutation' }
  & { deleteSignup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
  )> }
);

export type AcceptSignupsMutationVariables = Exact<{
  id: Scalars['ID'];
  token: Scalars['String'];
}>;


export type AcceptSignupsMutation = (
  { __typename?: 'Mutation' }
  & { acceptSignups?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type AddSignupsMutationVariables = Exact<{
  input: AddSignupsInput;
}>;


export type AddSignupsMutation = (
  { __typename?: 'Mutation' }
  & { addSignups?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type InviteCandidatesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InviteCandidatesMutation = (
  { __typename?: 'Mutation' }
  & { inviteCandidates?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteEvent?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type RestoreEventsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreEventsMutation = (
  { __typename?: 'Mutation' }
  & { restoreEvents: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )>> }
);

export type ForceDeleteEventsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteEventsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteEvents: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )>> }
);

export type EmptyEventTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyEventTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyEventTrash: Array<Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )>> }
);

export type LocationCreateFormFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
  & { donors: Array<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id'>
  )> }
);

export type LocationEditFormFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
  & { donors: Array<(
    { __typename?: 'Donor' }
    & Pick<Donor, 'id' | 'name'>
  )> }
);

export type LocationInfoFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
);

export type LocationListItemFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'street' | 'city' | 'postal_code' | 'meeting_point' | 'public_transport' | 'accessible'>
);

export type LocationListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
}>;


export type LocationListQuery = (
  { __typename?: 'Query' }
  & { locations?: Maybe<(
    { __typename?: 'LocationPaginator' }
    & { data: Array<(
      { __typename?: 'Location' }
      & LocationListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type LocationDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationDetailQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & LocationInfoFragment
  )> }
);

export type LocationDonorsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationDonorsQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & { donors: Array<(
      { __typename?: 'Donor' }
      & DonorListItemFragment
    )> }
  )> }
);

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation: (
    { __typename?: 'Location' }
    & LocationInfoFragment
  ) }
);

export type LocationEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationEditQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
    & LocationEditFormFragment
  )> }
);

export type UpdateLocationMutationVariables = Exact<{
  input: UpdateLocationInput;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation?: Maybe<(
    { __typename?: 'Location' }
    & LocationInfoFragment
  )> }
);

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation: (
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  ) }
);

export type RestoreLocationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreLocationsMutation = (
  { __typename?: 'Mutation' }
  & { restoreLocations: Array<Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )>> }
);

export type ForceDeleteLocationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteLocationsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteLocations: Array<Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )>> }
);

export type EmptyLocationTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyLocationTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyLocationTrash: Array<Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id'>
  )>> }
);

export type CandidateListFragment = (
  { __typename?: 'Signup' }
  & { memberSignups: Array<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )>, leaderSignup?: Maybe<(
    { __typename?: 'Signup' }
    & CandidateListItemFragment
  )> }
  & CandidateListItemFragment
);

export type CandidateListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'is_cardholder' | 'notes' | 'priority' | 'is_first_attend'>
  & { conflicting_signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'start'>
    ) }
  )>, emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status'>
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng' | 'notes'>
    & { meta: (
      { __typename?: 'CompanionMeta' }
      & Pick<CompanionMeta, 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng' | 'notes'>
    & { meta: (
      { __typename?: 'SoulMeta' }
      & Pick<SoulMeta, 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type CompanionMatchListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority'>
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng' | 'notes'>
    & { meta: (
      { __typename?: 'CompanionMeta' }
      & Pick<CompanionMeta, 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type CompanionMatchingTabFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id'>
  & { signups: Array<(
    { __typename?: 'Signup' }
    & CompanionMatchListItemFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'street' | 'postal_code' | 'city' | 'lat' | 'lng'>
  ) }
);

export type ProspectGroupListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'notes' | 'priority' | 'is_first_attend'>
  & { conflicting_signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'start'>
    ) }
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'birthdate' | 'notes'>
    & { meta: (
      { __typename?: 'CompanionMeta' }
      & Pick<CompanionMeta, 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )>, memberSignups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id' | 'notes' | 'priority' | 'is_first_attend'>
    & { conflicting_signups: Array<(
      { __typename?: 'Signup' }
      & Pick<Signup, 'id'>
      & { event: (
        { __typename?: 'Event' }
        & Pick<Event, 'id' | 'name' | 'start'>
      ) }
    )>, soul?: Maybe<(
      { __typename?: 'Soul' }
      & Pick<Soul, 'id' | 'display_name' | 'birthdate' | 'notes'>
      & { meta: (
        { __typename?: 'SoulMeta' }
        & Pick<SoulMeta, 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
      ), tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
      )> }
    )> }
  )> }
);

export type ProspectListFragment = (
  { __typename?: 'Signup' }
  & { memberSignups: Array<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )>, leaderSignup?: Maybe<(
    { __typename?: 'Signup' }
    & ProspectListItemFragment
  )> }
  & ProspectListItemFragment
);

export type ProspectListItemFragment = (
  { __typename?: 'Signup' }
  & Pick<Signup, 'id' | 'event_id' | 'token' | 'is_candidate' | 'is_invited' | 'has_accepted' | 'has_rejected' | 'notes' | 'priority' | 'is_first_attend'>
  & { conflicting_signups: Array<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & { event: (
      { __typename?: 'Event' }
      & Pick<Event, 'id' | 'name' | 'start'>
    ) }
  )>, emails: Array<(
    { __typename?: 'Email' }
    & Pick<Email, 'id' | 'status'>
  )>, companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng' | 'notes'>
    & { meta: (
      { __typename?: 'CompanionMeta' }
      & Pick<CompanionMeta, 'can_blind' | 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )>, soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'display_name' | 'birthdate' | 'email' | 'phone' | 'mobile' | 'lat' | 'lng' | 'notes'>
    & { meta: (
      { __typename?: 'SoulMeta' }
      & Pick<SoulMeta, 'buys_ticket' | 'needs_blind' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch'>
    ), tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
    )>, organisation?: Maybe<(
      { __typename?: 'Organisation' }
      & Pick<Organisation, 'id' | 'name'>
    )> }
  )> }
);

export type MatchingPageFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'status' | 'start' | 'end' | 'archived' | 'taken_tickets' | 'available_tickets' | 'candidates_chosen'>
  & { cardholder?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name' | 'mobile' | 'phone' | 'email'>
  )>, categories: Array<(
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  )>, signups: Array<(
    { __typename?: 'Signup' }
    & CandidateListFragment
  )>, location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'accessible' | 'meeting_point' | 'public_transport' | 'street' | 'postal_code' | 'city' | 'lat' | 'lng'>
  ) }
);

export type EventMatchingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EventMatchingQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & MatchingPageFragment
  )> }
);

export type UpdateEventCandidatesMutationVariables = Exact<{
  input: UpdateCandidatesInput;
}>;


export type UpdateEventCandidatesMutation = (
  { __typename?: 'Mutation' }
  & { updateCandidates?: Maybe<(
    { __typename?: 'Event' }
    & EventDetailPageFragment
  )> }
);

export type CandidateSignupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CandidateSignupQuery = (
  { __typename?: 'Query' }
  & { signup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & CandidateListItemFragment
  )> }
);

export type ProspectSignupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProspectSignupQuery = (
  { __typename?: 'Query' }
  & { signup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & ProspectListItemFragment
  )> }
);

export type ProspectGroupSignupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProspectGroupSignupQuery = (
  { __typename?: 'Query' }
  & { signup?: Maybe<(
    { __typename?: 'Signup' }
    & Pick<Signup, 'id'>
    & ProspectGroupListItemFragment
  )> }
);

export type CompanionMatchingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanionMatchingQuery = (
  { __typename?: 'Query' }
  & { event?: Maybe<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
    & CompanionMatchingTabFragment
  )> }
);

export type SoulAttendsQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type SoulAttendsQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { attends?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & AttendListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type CompanionAttendsQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type CompanionAttendsQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { attends?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & AttendListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type SoulFutureSignupsQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type SoulFutureSignupsQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { future_signups?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & FutureSignupListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type CompanionFutureSignupsQueryVariables = Exact<{
  id: Scalars['ID'];
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
}>;


export type CompanionFutureSignupsQuery = (
  { __typename?: 'Query' }
  & { companion?: Maybe<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
    & { future_signups?: Maybe<(
      { __typename?: 'SignupPaginator' }
      & { data: Array<(
        { __typename?: 'Signup' }
        & FutureSignupListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
      ) }
    )> }
  )> }
);

export type NewsletterEventListItemFragment = (
  { __typename?: 'Event' }
  & Pick<Event, 'id' | 'name' | 'start' | 'end'>
  & { location: (
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  ) }
);

export type NewsletterCreateFormFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Newsletter, 'subject' | 'body' | 'segment'>
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  )> }
);

export type NewsletterListItemFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Newsletter, 'id' | 'subject' | 'status' | 'sent_at' | 'segment'>
);

export type NewsletterDetailPageFragment = (
  { __typename?: 'Newsletter' }
  & Pick<Newsletter, 'id' | 'status' | 'subject' | 'body' | 'sent_at' | 'segment'>
  & { events: Array<(
    { __typename?: 'Event' }
    & Pick<Event, 'id' | 'name'>
  )> }
);

export type NewsletterListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  sentAt?: Maybe<DateTimeRange>;
  trashed?: Maybe<Trashed>;
}>;


export type NewsletterListQuery = (
  { __typename?: 'Query' }
  & { newsletters?: Maybe<(
    { __typename?: 'NewsletterPaginator' }
    & { data: Array<(
      { __typename?: 'Newsletter' }
      & NewsletterListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type NewsletterDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NewsletterDetailQuery = (
  { __typename?: 'Query' }
  & { newsletter?: Maybe<(
    { __typename?: 'Newsletter' }
    & Pick<Newsletter, 'id'>
    & NewsletterDetailPageFragment
  )> }
);

export type CreateNewsletterMutationVariables = Exact<{
  input: CreateNewsletterInput;
}>;


export type CreateNewsletterMutation = (
  { __typename?: 'Mutation' }
  & { createNewsletter: (
    { __typename?: 'Newsletter' }
    & NewsletterDetailPageFragment
  ) }
);

export type OrganisationCompanionListItemFragment = (
  { __typename?: 'Companion' }
  & Pick<Companion, 'id' | 'display_name'>
  & { meta: (
    { __typename?: 'CompanionMeta' }
    & Pick<CompanionMeta, 'can_wheelchair' | 'can_wheeled_walker' | 'can_drive' | 'can_blind'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type OrganisationCreateFormFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'name' | 'description' | 'notes'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id'>
  )> }
);

export type OrganisationEditFormFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'description' | 'notes'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'display_name'>
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & Pick<Companion, 'id' | 'display_name'>
  )> }
);

export type OrganisationListItemFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name'>
);

export type OrganisationSoulListItemFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'display_name'>
  & { meta: (
    { __typename?: 'SoulMeta' }
    & Pick<SoulMeta, 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  ), tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type OrganisationDetailPageFragment = (
  { __typename?: 'Organisation' }
  & Pick<Organisation, 'id' | 'name' | 'description' | 'notes'>
  & { souls: Array<(
    { __typename?: 'Soul' }
    & OrganisationSoulListItemFragment
  )>, companions: Array<(
    { __typename?: 'Companion' }
    & OrganisationCompanionListItemFragment
  )> }
);

export type OrganisationListQueryVariables = Exact<{
  trashed?: Maybe<Trashed>;
}>;


export type OrganisationListQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & OrganisationListItemFragment
  )> }
);

export type OrganisationDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganisationDetailQuery = (
  { __typename?: 'Query' }
  & { organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
    & OrganisationDetailPageFragment
  )> }
);

export type OrganisationEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrganisationEditQuery = (
  { __typename?: 'Query' }
  & { organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
    & OrganisationEditFormFragment
  )> }
);

export type CreateOrganisationMutationVariables = Exact<{
  input: CreateOrganisationInput;
}>;


export type CreateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { createOrganisation: (
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  ) }
);

export type UpdateOrganisationMutationVariables = Exact<{
  input: UpdateOrganisationInput;
}>;


export type UpdateOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { updateOrganisation?: Maybe<(
    { __typename?: 'Organisation' }
    & OrganisationDetailPageFragment
  )> }
);

export type DeleteOrganisationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrganisationMutation = (
  { __typename?: 'Mutation' }
  & { deleteOrganisation: (
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  ) }
);

export type RestoreOrganisationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreOrganisationsMutation = (
  { __typename?: 'Mutation' }
  & { restoreOrganisations: Array<Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  )>> }
);

export type ForceDeleteOrganisationsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteOrganisationsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteOrganisations: Array<Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  )>> }
);

export type EmptyOrganisationTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptyOrganisationTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptyOrganisationTrash: Array<Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id'>
  )>> }
);

export type PermissionMultiSelectFragment = (
  { __typename?: 'Permission' }
  & Pick<Permission, 'id' | 'title'>
);

export type RoleCreateFormFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id'>
  )> }
);

export type RoleEditFormFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'title'>
  )> }
);

export type RoleListItemFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'title'>
  )> }
);

export type RoleDetailPageFragment = (
  { __typename?: 'Role' }
  & Pick<Role, 'id' | 'name'>
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & Pick<Permission, 'id' | 'title'>
  )> }
);

export type RoleListQueryVariables = Exact<{ [key: string]: never; }>;


export type RoleListQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & RoleListItemFragment
  )> }
);

export type RoleDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoleDetailQuery = (
  { __typename?: 'Query' }
  & { role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
    & RoleDetailPageFragment
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;


export type CreateRoleMutation = (
  { __typename?: 'Mutation' }
  & { createRole: (
    { __typename?: 'Role' }
    & RoleDetailPageFragment
  ) }
);

export type RoleEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RoleEditQuery = (
  { __typename?: 'Query' }
  & { role?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
    & RoleEditFormFragment
  )> }
);

export type UpdateRoleMutationVariables = Exact<{
  input: UpdateRoleInput;
}>;


export type UpdateRoleMutation = (
  { __typename?: 'Mutation' }
  & { updateRole?: Maybe<(
    { __typename?: 'Role' }
    & RoleDetailPageFragment
  )> }
);

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRoleMutation = (
  { __typename?: 'Mutation' }
  & { deleteRole?: Maybe<(
    { __typename?: 'Role' }
    & Pick<Role, 'id'>
  )> }
);

export type SettingsDetailPageFragment = (
  { __typename?: 'Tenant' }
  & Pick<Tenant, 'id' | 'subdomain' | 'legacy_api_key' | 'redirect_events' | 'redirect_ticket_accept_ok' | 'redirect_ticket_accept_not_found' | 'redirect_password_reset'>
);

export type CurrentTenantDetailQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentTenantDetailQuery = (
  { __typename?: 'Query' }
  & { currentTenant: (
    { __typename?: 'Tenant' }
    & Pick<Tenant, 'id'>
    & SettingsDetailPageFragment
  ) }
);

export type UpdateTenantMutationVariables = Exact<{
  input: UpdateTenantInput;
}>;


export type UpdateTenantMutation = (
  { __typename?: 'Mutation' }
  & { updateTenant?: Maybe<(
    { __typename?: 'Tenant' }
    & SettingsDetailPageFragment
  )> }
);

export type RegenerateApiKeyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RegenerateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { regenerateApiKey: (
    { __typename?: 'Tenant' }
    & SettingsDetailPageFragment
  ) }
);

export type SoulCreateFormFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'first_name' | 'last_name' | 'status' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { meta: (
    { __typename?: 'SoulMeta' }
    & Pick<SoulMeta, 'target_group' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulEditFormFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { meta: (
    { __typename?: 'SoulMeta' }
    & Pick<SoulMeta, 'target_group' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulInfoFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'status' | 'display_name' | 'first_name' | 'last_name' | 'birthdate' | 'email' | 'gdpr_consent' | 'gdpr_consent_source' | 'phone' | 'mobile' | 'street' | 'city' | 'postal_code' | 'notes'>
  & { meta: (
    { __typename?: 'SoulMeta' }
    & Pick<SoulMeta, 'target_group' | 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id' | 'name' | 'icon' | 'intent'>
  )> }
);

export type SoulListItemFragment = (
  { __typename?: 'Soul' }
  & Pick<Soul, 'id' | 'status' | 'display_name' | 'email' | 'phone' | 'mobile'>
  & { meta: (
    { __typename?: 'SoulMeta' }
    & Pick<SoulMeta, 'buys_ticket' | 'needs_wheelchair' | 'needs_wheeled_walker' | 'needs_drive' | 'needs_trainstation_fetch' | 'needs_blind'>
  ), organisation?: Maybe<(
    { __typename?: 'Organisation' }
    & Pick<Organisation, 'id' | 'name'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagIconFragment
  )> }
);

export type SoulListQueryVariables = Exact<{
  status?: Maybe<UserStatus>;
  page?: Maybe<Scalars['Int']>;
  trashed?: Maybe<Trashed>;
}>;


export type SoulListQuery = (
  { __typename?: 'Query' }
  & { souls?: Maybe<(
    { __typename?: 'SoulPaginator' }
    & { data: Array<(
      { __typename?: 'Soul' }
      & SoulListItemFragment
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'lastPage' | 'hasMorePages'>
    ) }
  )> }
);

export type SoulDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SoulDetailQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & SoulInfoFragment
  )> }
);

export type SoulEmailsQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: Maybe<Scalars['Int']>;
}>;


export type SoulEmailsQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
    & { emails?: Maybe<(
      { __typename?: 'EmailPaginator' }
      & { data: Array<(
        { __typename?: 'Email' }
        & EmailListItemFragment
      )>, paginatorInfo: (
        { __typename?: 'PaginatorInfo' }
        & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages' | 'lastPage'>
      ) }
    )> }
  )> }
);

export type SoulEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SoulEditQuery = (
  { __typename?: 'Query' }
  & { soul?: Maybe<(
    { __typename?: 'Soul' }
    & SoulEditFormFragment
  )> }
);

export type CreateSoulMutationVariables = Exact<{
  input: CreateSoulInput;
}>;


export type CreateSoulMutation = (
  { __typename?: 'Mutation' }
  & { createSoul: (
    { __typename?: 'Soul' }
    & SoulInfoFragment
  ) }
);

export type UpdateSoulMutationVariables = Exact<{
  input: UpdateSoulInput;
}>;


export type UpdateSoulMutation = (
  { __typename?: 'Mutation' }
  & { updateSoul?: Maybe<(
    { __typename?: 'Soul' }
    & SoulInfoFragment
  )> }
);

export type ApproveSoulMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApproveSoulMutation = (
  { __typename?: 'Mutation' }
  & { approveSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'status'>
  ) }
);

export type DenySoulMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DenySoulMutation = (
  { __typename?: 'Mutation' }
  & { denySoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'status'>
  ) }
);

export type ActivateSoulMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateSoulMutation = (
  { __typename?: 'Mutation' }
  & { activateSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'status'>
  ) }
);

export type DeactivateSoulMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeactivateSoulMutation = (
  { __typename?: 'Mutation' }
  & { deactivateSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id' | 'status'>
  ) }
);

export type DeleteSoulMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSoulMutation = (
  { __typename?: 'Mutation' }
  & { deleteSoul: (
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  ) }
);

export type RestoreSoulsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type RestoreSoulsMutation = (
  { __typename?: 'Mutation' }
  & { restoreSouls: Array<Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )>> }
);

export type ForceDeleteSoulsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type ForceDeleteSoulsMutation = (
  { __typename?: 'Mutation' }
  & { forceDeleteSouls: Array<Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )>> }
);

export type EmptySoulTrashMutationVariables = Exact<{ [key: string]: never; }>;


export type EmptySoulTrashMutation = (
  { __typename?: 'Mutation' }
  & { emptySoulTrash: Array<Maybe<(
    { __typename?: 'Soul' }
    & Pick<Soul, 'id'>
  )>> }
);

export type CategoryStatsFragment = (
  { __typename?: 'CategoryStatsPayload' }
  & { category: (
    { __typename?: 'Category' }
    & Pick<Category, 'id' | 'name' | 'color'>
  ), data: (
    { __typename?: 'CategoryDataPayload' }
    & Pick<CategoryDataPayload, 'tickets' | 'signups' | 'previous_tickets' | 'previous_signups'>
  ) }
);

export type TargetGroupStatsFragment = (
  { __typename?: 'TargetGroupStatsPayload' }
  & Pick<TargetGroupStatsPayload, 'target_group'>
  & { data: (
    { __typename?: 'TargetGroupDataPayload' }
    & Pick<TargetGroupDataPayload, 'signups' | 'attends' | 'previous_signups' | 'previous_attends'>
  ) }
);

export type OverviewStatsQueryVariables = Exact<{
  input: OverviewStatsInput;
}>;


export type OverviewStatsQuery = (
  { __typename?: 'Query' }
  & { overviewStats: (
    { __typename?: 'OverviewStatsPayload' }
    & Pick<OverviewStatsPayload, 'tickets' | 'signups' | 'attends'>
  ) }
);

export type TargetGroupStatsQueryVariables = Exact<{
  input: TargetGroupStatsInput;
}>;


export type TargetGroupStatsQuery = (
  { __typename?: 'Query' }
  & { targetGroupStats: Array<(
    { __typename?: 'TargetGroupStatsPayload' }
    & Pick<TargetGroupStatsPayload, 'target_group'>
    & TargetGroupStatsFragment
  )> }
);

export type CategoryStatsQueryVariables = Exact<{
  input: CategoryStatsInput;
}>;


export type CategoryStatsQuery = (
  { __typename?: 'Query' }
  & { categoryStats: Array<(
    { __typename?: 'CategoryStatsPayload' }
    & { category: (
      { __typename?: 'Category' }
      & Pick<Category, 'id'>
    ) }
    & CategoryStatsFragment
  )> }
);

export type TagCreateFormFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'name' | 'icon' | 'intent' | 'description'>
);

export type TagEditFormFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent' | 'description'>
);

export type TagListItemFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent' | 'description'>
);

export type TagDetailPageFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'icon' | 'intent' | 'description'>
);

export type TagListQueryVariables = Exact<{ [key: string]: never; }>;


export type TagListQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & TagListItemFragment
  )> }
);

export type TagDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TagDetailQuery = (
  { __typename?: 'Query' }
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
    & TagDetailPageFragment
  )> }
);

export type TagEditQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TagEditQuery = (
  { __typename?: 'Query' }
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
    & TagEditFormFragment
  )> }
);

export type CreateTagMutationVariables = Exact<{
  input: CreateTagInput;
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag: (
    { __typename?: 'Tag' }
    & TagDetailPageFragment
  ) }
);

export type UpdateTagMutationVariables = Exact<{
  input: UpdateTagInput;
}>;


export type UpdateTagMutation = (
  { __typename?: 'Mutation' }
  & { updateTag?: Maybe<(
    { __typename?: 'Tag' }
    & TagDetailPageFragment
  )> }
);

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
  )> }
);

export type TagsQueryVariables = Exact<{ [key: string]: never; }>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: Array<(
    { __typename?: 'Tag' }
    & PersonTagMultiSelectFragment
  )> }
);

export type PermissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type PermissionsQuery = (
  { __typename?: 'Query' }
  & { permissions: Array<(
    { __typename?: 'Permission' }
    & PermissionMultiSelectFragment
  )> }
);

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = (
  { __typename?: 'Query' }
  & { roles: Array<(
    { __typename?: 'Role' }
    & RoleMultiSelectFragment
  )> }
);

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = (
  { __typename?: 'Query' }
  & { categories: Array<(
    { __typename?: 'Category' }
    & CategoryMultiSelectFragment
  )> }
);

export type AllotmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllotmentsQuery = (
  { __typename?: 'Query' }
  & { allotments: Array<(
    { __typename?: 'Allotment' }
    & AllotmentSelectFragment
  )> }
);

export type OrganisationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganisationsQuery = (
  { __typename?: 'Query' }
  & { organisations: Array<(
    { __typename?: 'Organisation' }
    & PersonOrganisationSelectFragment
  )> }
);

export type EmailUsersMutationVariables = Exact<{
  input: EmailUsersInput;
}>;


export type EmailUsersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'emailUsers'>
);

export type SendResetLinkForUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendResetLinkForUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'sendResetLinkForUser'>
);

export const AttendListItemFragmentDoc = gql`
    fragment AttendListItem on Signup {
  id
  event {
    id
    name
    start
    categories {
      id
      name
      color
    }
  }
  leaderSignup {
    id
    companion {
      id
      display_name
    }
  }
  memberSignups {
    id
    soul {
      id
      display_name
    }
  }
}
    `;
export const FutureSignupListItemFragmentDoc = gql`
    fragment FutureSignupListItem on Signup {
  id
  is_candidate
  is_invited
  has_accepted
  has_rejected
  emails {
    id
    status
  }
  event {
    id
    name
    status
    candidates_chosen
    start
    categories {
      id
      name
      color
    }
  }
}
    `;
export const PersonOrganisationSelectFragmentDoc = gql`
    fragment PersonOrganisationSelect on Organisation {
  id
  name
}
    `;
export const PersonTagMultiSelectFragmentDoc = gql`
    fragment PersonTagMultiSelect on Tag {
  id
  name
  icon
  intent
}
    `;
export const AllotmentCreateFormFragmentDoc = gql`
    fragment AllotmentCreateForm on Allotment {
  name
  amount
  start
  end
}
    `;
export const AllotmentEditFormFragmentDoc = gql`
    fragment AllotmentEditForm on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AllotmentListItemFragmentDoc = gql`
    fragment AllotmentListItem on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AllotmentDetailPageFragmentDoc = gql`
    fragment AllotmentDetailPage on Allotment {
  id
  name
  status
  amount
  amount_left
  start
  end
}
    `;
export const AcceptSignupsFragmentDoc = gql`
    fragment AcceptSignups on Event {
  id
  signups {
    id
    token
    has_accepted
    has_rejected
  }
}
    `;
export const CategoryCreateFormFragmentDoc = gql`
    fragment CategoryCreateForm on Category {
  name
  description
  color
}
    `;
export const CategoryEditFormFragmentDoc = gql`
    fragment CategoryEditForm on Category {
  id
  name
  description
  color
}
    `;
export const CategoryListItemFragmentDoc = gql`
    fragment CategoryListItem on Category {
  id
  name
  description
  color
}
    `;
export const CategoryDetailPageFragmentDoc = gql`
    fragment CategoryDetailPage on Category {
  id
  name
  description
  color
}
    `;
export const CompanionCreateFormFragmentDoc = gql`
    fragment CompanionCreateForm on Companion {
  notes
  first_name
  last_name
  status
  birthdate
  email
  gdpr_consent
  phone
  mobile
  street
  city
  postal_code
  meta {
    can_wheelchair
    can_wheeled_walker
    can_drive
    can_blind
    can_trainstation_fetch
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const CompanionEditFormFragmentDoc = gql`
    fragment CompanionEditForm on Companion {
  id
  first_name
  last_name
  birthdate
  email
  gdpr_consent
  phone
  mobile
  street
  city
  postal_code
  notes
  meta {
    can_wheelchair
    can_wheeled_walker
    can_drive
    can_blind
    can_trainstation_fetch
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const CompanionInfoFragmentDoc = gql`
    fragment CompanionInfo on Companion {
  id
  status
  display_name
  first_name
  last_name
  birthdate
  email
  gdpr_consent
  gdpr_consent_source
  phone
  mobile
  street
  city
  postal_code
  notes
  meta {
    can_wheelchair
    can_wheeled_walker
    can_drive
    can_blind
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const PersonTagIconFragmentDoc = gql`
    fragment PersonTagIcon on Tag {
  id
  name
  icon
  intent
}
    `;
export const CompanionListItemFragmentDoc = gql`
    fragment CompanionListItem on Companion {
  id
  status
  display_name
  email
  phone
  mobile
  meta {
    can_wheelchair
    can_wheeled_walker
    can_drive
    can_blind
  }
  organisation {
    id
    name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const ContactCreateFormFragmentDoc = gql`
    fragment ContactCreateForm on Contact {
  first_name
  last_name
  email
  phone
  mobile
  street
  city
  postal_code
  notes
  donor_id
}
    `;
export const ContactEditFormFragmentDoc = gql`
    fragment ContactEditForm on Contact {
  id
  first_name
  last_name
  email
  phone
  mobile
  street
  city
  postal_code
  notes
}
    `;
export const ContactInfoFragmentDoc = gql`
    fragment ContactInfo on Contact {
  id
  display_name
  first_name
  last_name
  email
  phone
  mobile
  street
  city
  postal_code
  notes
}
    `;
export const ContactListItemFragmentDoc = gql`
    fragment ContactListItem on Contact {
  id
  display_name
  email
  phone
  mobile
}
    `;
export const DonorCreateFormFragmentDoc = gql`
    fragment DonorCreateForm on Donor {
  name
  street
  city
  postal_code
  notes
  logo {
    name
    url
  }
  locations {
    id
  }
}
    `;
export const DonorEditFormFragmentDoc = gql`
    fragment DonorEditForm on Donor {
  id
  name
  street
  city
  postal_code
  notes
  logo {
    name
    url
  }
  locations {
    id
    name
  }
}
    `;
export const DonorInfoFragmentDoc = gql`
    fragment DonorInfo on Donor {
  id
  name
  street
  city
  postal_code
  notes
  logo {
    name
    thumb_url
  }
}
    `;
export const DonorListItemFragmentDoc = gql`
    fragment DonorListItem on Donor {
  id
  name
  contacts {
    id
    display_name
  }
}
    `;
export const EmailListItemFragmentDoc = gql`
    fragment EmailListItem on Email {
  id
  status
  subject
  sent_at
}
    `;
export const EmailOverlayFragmentDoc = gql`
    fragment EmailOverlay on Email {
  id
  status
  reason
  subject
  sent_at
  body
}
    `;
export const EmployeeCreateFormFragmentDoc = gql`
    fragment EmployeeCreateForm on Employee {
  status
  first_name
  last_name
  email
  phone
  mobile
  street
  city
  postal_code
  roles {
    id
  }
  meta {
    position
  }
}
    `;
export const EmployeeEditFormFragmentDoc = gql`
    fragment EmployeeEditForm on Employee {
  id
  status
  first_name
  last_name
  email
  phone
  mobile
  street
  city
  postal_code
  roles {
    id
    name
  }
  meta {
    position
  }
}
    `;
export const EmployeeInfoFragmentDoc = gql`
    fragment EmployeeInfo on Employee {
  id
  display_name
  first_name
  last_name
  email
  phone
  mobile
  street
  city
  postal_code
  roles {
    id
    name
  }
  meta {
    position
  }
}
    `;
export const EmployeeListItemFragmentDoc = gql`
    fragment EmployeeListItem on Employee {
  id
  display_name
  email
  phone
  mobile
  roles {
    id
    name
  }
  meta {
    position
  }
}
    `;
export const RoleMultiSelectFragmentDoc = gql`
    fragment RoleMultiSelect on Role {
  id
  name
}
    `;
export const AllotmentSelectFragmentDoc = gql`
    fragment AllotmentSelect on Allotment {
  id
  name
}
    `;
export const CategoryMultiSelectFragmentDoc = gql`
    fragment CategoryMultiSelect on Category {
  id
  name
}
    `;
export const ContactSelectFragmentDoc = gql`
    fragment ContactSelect on Contact {
  id
  display_name
}
    `;
export const EventCreateFormFragmentDoc = gql`
    fragment EventCreateForm on Event {
  name
  status
  description
  start
  end
  tickets
  ticket_time
  ticket_type
  reservation_number
  ticket_retrieval_type
  ticket_retrieval_location
  notes
  categories {
    id
  }
  donor {
    id
  }
  contact {
    id
  }
  location {
    id
  }
  allotment {
    id
  }
}
    `;
export const EventEditFormFragmentDoc = gql`
    fragment EventEditForm on Event {
  id
  name
  status
  description
  start
  end
  tickets
  ticket_time
  ticket_type
  reservation_number
  ticket_retrieval_type
  ticket_retrieval_location
  notes
  categories {
    id
    name
  }
  donor {
    id
    name
  }
  contact {
    id
    display_name
  }
  location {
    id
    name
  }
  allotment {
    id
    name
  }
}
    `;
export const EventListItemFragmentDoc = gql`
    fragment EventListItem on Event {
  id
  name
  status
  start
  end
  available_tickets
  taken_tickets
  archived
  candidateCount @client
  attendeeCount @client
  attendees @client {
    id
    is_candidate
    is_invited
    is_soul
    has_accepted
    has_rejected
  }
  signups {
    id
    is_candidate
    is_invited
    is_soul
    has_accepted
    has_rejected
  }
  location {
    id
    name
    accessible
  }
}
    `;
export const LocationSelectFragmentDoc = gql`
    fragment LocationSelect on Location {
  id
  name
}
    `;
export const RemoveSignupFragmentDoc = gql`
    fragment RemoveSignup on Event {
  prospects @client {
    id
  }
  signups {
    id
  }
}
    `;
export const SignupListItemFragmentDoc = gql`
    fragment SignupListItem on Signup {
  id
  event_id
  notes
  companion {
    id
    display_name
    notes
    meta {
      can_blind
      can_wheelchair
      can_wheeled_walker
      can_drive
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
  soul {
    id
    display_name
    notes
    meta {
      buys_ticket
      needs_blind
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
}
    `;
export const AttendeeListItemFragmentDoc = gql`
    fragment AttendeeListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  is_soul
  has_accepted
  has_rejected
  notes
  is_first_attend
  emails {
    id
    status
  }
  companion {
    id
    display_name
    email
    phone
    mobile
    notes
    meta {
      can_blind
      can_wheelchair
      can_wheeled_walker
      can_drive
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
  soul {
    id
    display_name
    email
    phone
    mobile
    notes
    meta {
      buys_ticket
      needs_blind
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
}
    `;
export const AttendeeGroupFragmentDoc = gql`
    fragment AttendeeGroup on Signup {
  ...AttendeeListItem
  memberSignups {
    ...AttendeeListItem
  }
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeTeamFragmentDoc = gql`
    fragment AttendeeTeam on Signup {
  ...AttendeeListItem
  memberSignups {
    ...AttendeeListItem
  }
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeLoneSoulFragmentDoc = gql`
    fragment AttendeeLoneSoul on Signup {
  ...AttendeeListItem
}
    ${AttendeeListItemFragmentDoc}`;
export const AttendeeLoneCompanionFragmentDoc = gql`
    fragment AttendeeLoneCompanion on Signup {
  ...AttendeeListItem
}
    ${AttendeeListItemFragmentDoc}`;
export const EventDetailPageFragmentDoc = gql`
    fragment EventDetailPage on Event {
  id
  name
  status
  start
  end
  notes
  description
  taken_tickets
  available_tickets
  candidates_chosen
  ticket_type
  ticket_time
  ticket_retrieval_type
  ticket_retrieval_location
  reservation_number
  archived
  needsInvite @client
  candidateCount @client
  attendeeCount @client
  prospects @client {
    ...AttendeeListItem
  }
  candidates @client {
    ...AttendeeListItem
  }
  attendees @client {
    ...AttendeeListItem
  }
  groups @client {
    ...AttendeeGroup
  }
  teams @client {
    ...AttendeeTeam
  }
  loneSouls @client {
    ...AttendeeLoneSoul
  }
  loneCompanions @client {
    ...AttendeeLoneCompanion
  }
  cardholder {
    id
    display_name
    mobile
    phone
    email
  }
  signups {
    ...AttendeeListItem
    memberSignups {
      ...AttendeeListItem
    }
    leaderSignup {
      ...AttendeeListItem
    }
  }
  contact {
    id
    display_name
    mobile
    phone
    email
  }
  location {
    id
    name
    accessible
    meeting_point
    public_transport
    street
    postal_code
    city
  }
  categories {
    id
    name
    color
  }
}
    ${AttendeeListItemFragmentDoc}
${AttendeeGroupFragmentDoc}
${AttendeeTeamFragmentDoc}
${AttendeeLoneSoulFragmentDoc}
${AttendeeLoneCompanionFragmentDoc}`;
export const LocationCreateFormFragmentDoc = gql`
    fragment LocationCreateForm on Location {
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  donors {
    id
  }
}
    `;
export const LocationEditFormFragmentDoc = gql`
    fragment LocationEditForm on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
  donors {
    id
    name
  }
}
    `;
export const LocationInfoFragmentDoc = gql`
    fragment LocationInfo on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
}
    `;
export const LocationListItemFragmentDoc = gql`
    fragment LocationListItem on Location {
  id
  name
  street
  city
  postal_code
  meeting_point
  public_transport
  accessible
}
    `;
export const CompanionMatchListItemFragmentDoc = gql`
    fragment CompanionMatchListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  companion {
    id
    display_name
    birthdate
    email
    phone
    mobile
    lat
    lng
    notes
    meta {
      can_blind
      can_wheelchair
      can_wheeled_walker
      can_drive
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
}
    `;
export const CompanionMatchingTabFragmentDoc = gql`
    fragment CompanionMatchingTab on Event {
  id
  signups {
    ...CompanionMatchListItem
  }
  location {
    id
    street
    postal_code
    city
    lat
    lng
  }
}
    ${CompanionMatchListItemFragmentDoc}`;
export const ProspectGroupListItemFragmentDoc = gql`
    fragment ProspectGroupListItem on Signup {
  id
  notes
  priority
  is_first_attend
  conflicting_signups {
    id
    event {
      id
      name
      start
    }
  }
  companion {
    id
    display_name
    birthdate
    notes
    meta {
      can_blind
      can_wheelchair
      can_wheeled_walker
      can_drive
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
  memberSignups {
    id
    notes
    priority
    is_first_attend
    conflicting_signups {
      id
      event {
        id
        name
        start
      }
    }
    soul {
      id
      display_name
      birthdate
      notes
      meta {
        buys_ticket
        needs_blind
        needs_wheelchair
        needs_wheeled_walker
        needs_drive
        needs_trainstation_fetch
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  }
}
    `;
export const ProspectListItemFragmentDoc = gql`
    fragment ProspectListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  notes
  priority
  is_first_attend
  conflicting_signups {
    id
    event {
      id
      name
      start
    }
  }
  emails {
    id
    status
  }
  companion {
    id
    display_name
    birthdate
    email
    phone
    mobile
    lat
    lng
    notes
    meta {
      can_blind
      can_wheelchair
      can_wheeled_walker
      can_drive
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
  soul {
    id
    display_name
    birthdate
    email
    phone
    mobile
    lat
    lng
    notes
    meta {
      buys_ticket
      needs_blind
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
}
    `;
export const ProspectListFragmentDoc = gql`
    fragment ProspectList on Signup {
  ...ProspectListItem
  memberSignups {
    ...ProspectListItem
  }
  leaderSignup {
    ...ProspectListItem
  }
}
    ${ProspectListItemFragmentDoc}`;
export const CandidateListItemFragmentDoc = gql`
    fragment CandidateListItem on Signup {
  id
  event_id
  token
  is_candidate
  is_invited
  has_accepted
  has_rejected
  is_cardholder
  notes
  priority
  is_first_attend
  conflicting_signups {
    id
    event {
      id
      name
      start
    }
  }
  emails {
    id
    status
  }
  companion {
    id
    display_name
    birthdate
    email
    phone
    mobile
    lat
    lng
    notes
    meta {
      can_blind
      can_wheelchair
      can_wheeled_walker
      can_drive
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
  soul {
    id
    display_name
    birthdate
    email
    phone
    mobile
    lat
    lng
    notes
    meta {
      buys_ticket
      needs_blind
      needs_wheelchair
      needs_wheeled_walker
      needs_drive
      needs_trainstation_fetch
    }
    tags {
      id
      name
      icon
      intent
    }
    organisation {
      id
      name
    }
  }
}
    `;
export const CandidateListFragmentDoc = gql`
    fragment CandidateList on Signup {
  ...CandidateListItem
  memberSignups {
    ...CandidateListItem
  }
  leaderSignup {
    ...CandidateListItem
  }
}
    ${CandidateListItemFragmentDoc}`;
export const MatchingPageFragmentDoc = gql`
    fragment MatchingPage on Event {
  id
  name
  status
  start
  end
  archived
  taken_tickets
  available_tickets
  candidates_chosen
  cardholder {
    id
    display_name
    mobile
    phone
    email
  }
  categories {
    id
    name
    color
  }
  signups {
    ...CandidateList
  }
  location {
    id
    name
    accessible
    meeting_point
    public_transport
    street
    postal_code
    city
    lat
    lng
  }
}
    ${CandidateListFragmentDoc}`;
export const NewsletterEventListItemFragmentDoc = gql`
    fragment NewsletterEventListItem on Event {
  id
  name
  start
  end
  location {
    id
    name
  }
}
    `;
export const NewsletterCreateFormFragmentDoc = gql`
    fragment NewsletterCreateForm on Newsletter {
  subject
  body
  segment
  events {
    id
  }
}
    `;
export const NewsletterListItemFragmentDoc = gql`
    fragment NewsletterListItem on Newsletter {
  id
  subject
  status
  sent_at
  segment
}
    `;
export const NewsletterDetailPageFragmentDoc = gql`
    fragment NewsletterDetailPage on Newsletter {
  id
  status
  subject
  body
  sent_at
  segment
  events {
    id
    name
  }
}
    `;
export const OrganisationCreateFormFragmentDoc = gql`
    fragment OrganisationCreateForm on Organisation {
  name
  description
  notes
  souls {
    id
  }
  companions {
    id
  }
}
    `;
export const OrganisationEditFormFragmentDoc = gql`
    fragment OrganisationEditForm on Organisation {
  id
  name
  description
  notes
  souls {
    id
    display_name
  }
  companions {
    id
    display_name
  }
}
    `;
export const OrganisationListItemFragmentDoc = gql`
    fragment OrganisationListItem on Organisation {
  id
  name
}
    `;
export const OrganisationSoulListItemFragmentDoc = gql`
    fragment OrganisationSoulListItem on Soul {
  id
  display_name
  meta {
    buys_ticket
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    needs_blind
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const OrganisationCompanionListItemFragmentDoc = gql`
    fragment OrganisationCompanionListItem on Companion {
  id
  display_name
  meta {
    can_wheelchair
    can_wheeled_walker
    can_drive
    can_blind
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const OrganisationDetailPageFragmentDoc = gql`
    fragment OrganisationDetailPage on Organisation {
  id
  name
  description
  notes
  souls {
    ...OrganisationSoulListItem
  }
  companions {
    ...OrganisationCompanionListItem
  }
}
    ${OrganisationSoulListItemFragmentDoc}
${OrganisationCompanionListItemFragmentDoc}`;
export const PermissionMultiSelectFragmentDoc = gql`
    fragment PermissionMultiSelect on Permission {
  id
  title
}
    `;
export const RoleCreateFormFragmentDoc = gql`
    fragment RoleCreateForm on Role {
  name
  permissions {
    id
  }
}
    `;
export const RoleEditFormFragmentDoc = gql`
    fragment RoleEditForm on Role {
  id
  name
  permissions {
    id
    title
  }
}
    `;
export const RoleListItemFragmentDoc = gql`
    fragment RoleListItem on Role {
  id
  name
  permissions {
    id
    title
  }
}
    `;
export const RoleDetailPageFragmentDoc = gql`
    fragment RoleDetailPage on Role {
  id
  name
  permissions {
    id
    title
  }
}
    `;
export const SettingsDetailPageFragmentDoc = gql`
    fragment SettingsDetailPage on Tenant {
  id
  subdomain
  legacy_api_key
  redirect_events
  redirect_ticket_accept_ok
  redirect_ticket_accept_not_found
  redirect_password_reset
}
    `;
export const SoulCreateFormFragmentDoc = gql`
    fragment SoulCreateForm on Soul {
  first_name
  last_name
  status
  birthdate
  email
  gdpr_consent
  phone
  mobile
  street
  city
  postal_code
  notes
  meta {
    target_group
    buys_ticket
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    needs_blind
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulEditFormFragmentDoc = gql`
    fragment SoulEditForm on Soul {
  id
  first_name
  last_name
  birthdate
  email
  gdpr_consent
  phone
  mobile
  street
  city
  postal_code
  notes
  meta {
    target_group
    buys_ticket
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    needs_blind
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulInfoFragmentDoc = gql`
    fragment SoulInfo on Soul {
  id
  status
  display_name
  first_name
  last_name
  birthdate
  email
  gdpr_consent
  gdpr_consent_source
  phone
  mobile
  street
  city
  postal_code
  notes
  meta {
    target_group
    buys_ticket
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    needs_blind
  }
  organisation {
    id
    name
  }
  tags {
    id
    name
    icon
    intent
  }
}
    `;
export const SoulListItemFragmentDoc = gql`
    fragment SoulListItem on Soul {
  id
  status
  display_name
  email
  phone
  mobile
  meta {
    buys_ticket
    needs_wheelchair
    needs_wheeled_walker
    needs_drive
    needs_trainstation_fetch
    needs_blind
  }
  organisation {
    id
    name
  }
  tags {
    ...PersonTagIcon
  }
}
    ${PersonTagIconFragmentDoc}`;
export const CategoryStatsFragmentDoc = gql`
    fragment CategoryStats on CategoryStatsPayload {
  category {
    id
    name
    color
  }
  data {
    tickets
    signups
    previous_tickets
    previous_signups
  }
}
    `;
export const TargetGroupStatsFragmentDoc = gql`
    fragment TargetGroupStats on TargetGroupStatsPayload {
  target_group
  data {
    signups
    attends
    previous_signups
    previous_attends
  }
}
    `;
export const TagCreateFormFragmentDoc = gql`
    fragment TagCreateForm on Tag {
  name
  icon
  intent
  description
}
    `;
export const TagEditFormFragmentDoc = gql`
    fragment TagEditForm on Tag {
  id
  name
  icon
  intent
  description
}
    `;
export const TagListItemFragmentDoc = gql`
    fragment TagListItem on Tag {
  id
  name
  icon
  intent
  description
}
    `;
export const TagDetailPageFragmentDoc = gql`
    fragment TagDetailPage on Tag {
  id
  name
  icon
  intent
  description
}
    `;
export const AllotmentListDocument = gql`
    query AllotmentList {
  allotments {
    ...AllotmentListItem
  }
}
    ${AllotmentListItemFragmentDoc}`;

/**
 * __useAllotmentListQuery__
 *
 * To run a query within a React component, call `useAllotmentListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllotmentListQuery(baseOptions?: Apollo.QueryHookOptions<AllotmentListQuery, AllotmentListQueryVariables>) {
        return Apollo.useQuery<AllotmentListQuery, AllotmentListQueryVariables>(AllotmentListDocument, baseOptions);
      }
export function useAllotmentListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllotmentListQuery, AllotmentListQueryVariables>) {
          return Apollo.useLazyQuery<AllotmentListQuery, AllotmentListQueryVariables>(AllotmentListDocument, baseOptions);
        }
export type AllotmentListQueryHookResult = ReturnType<typeof useAllotmentListQuery>;
export type AllotmentListLazyQueryHookResult = ReturnType<typeof useAllotmentListLazyQuery>;
export type AllotmentListQueryResult = Apollo.QueryResult<AllotmentListQuery, AllotmentListQueryVariables>;
export const AllotmentDetailDocument = gql`
    query AllotmentDetail($id: ID!) {
  allotment(id: $id) {
    id
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;

/**
 * __useAllotmentDetailQuery__
 *
 * To run a query within a React component, call `useAllotmentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllotmentDetailQuery(baseOptions?: Apollo.QueryHookOptions<AllotmentDetailQuery, AllotmentDetailQueryVariables>) {
        return Apollo.useQuery<AllotmentDetailQuery, AllotmentDetailQueryVariables>(AllotmentDetailDocument, baseOptions);
      }
export function useAllotmentDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllotmentDetailQuery, AllotmentDetailQueryVariables>) {
          return Apollo.useLazyQuery<AllotmentDetailQuery, AllotmentDetailQueryVariables>(AllotmentDetailDocument, baseOptions);
        }
export type AllotmentDetailQueryHookResult = ReturnType<typeof useAllotmentDetailQuery>;
export type AllotmentDetailLazyQueryHookResult = ReturnType<typeof useAllotmentDetailLazyQuery>;
export type AllotmentDetailQueryResult = Apollo.QueryResult<AllotmentDetailQuery, AllotmentDetailQueryVariables>;
export const AllotmentEditDocument = gql`
    query AllotmentEdit($id: ID!) {
  allotment(id: $id) {
    id
    ...AllotmentEditForm
  }
}
    ${AllotmentEditFormFragmentDoc}`;

/**
 * __useAllotmentEditQuery__
 *
 * To run a query within a React component, call `useAllotmentEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAllotmentEditQuery(baseOptions?: Apollo.QueryHookOptions<AllotmentEditQuery, AllotmentEditQueryVariables>) {
        return Apollo.useQuery<AllotmentEditQuery, AllotmentEditQueryVariables>(AllotmentEditDocument, baseOptions);
      }
export function useAllotmentEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllotmentEditQuery, AllotmentEditQueryVariables>) {
          return Apollo.useLazyQuery<AllotmentEditQuery, AllotmentEditQueryVariables>(AllotmentEditDocument, baseOptions);
        }
export type AllotmentEditQueryHookResult = ReturnType<typeof useAllotmentEditQuery>;
export type AllotmentEditLazyQueryHookResult = ReturnType<typeof useAllotmentEditLazyQuery>;
export type AllotmentEditQueryResult = Apollo.QueryResult<AllotmentEditQuery, AllotmentEditQueryVariables>;
export const CreateAllotmentDocument = gql`
    mutation CreateAllotment($input: CreateAllotmentInput!) {
  createAllotment(input: $input) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;
export type CreateAllotmentMutationFn = Apollo.MutationFunction<CreateAllotmentMutation, CreateAllotmentMutationVariables>;

/**
 * __useCreateAllotmentMutation__
 *
 * To run a mutation, you first call `useCreateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAllotmentMutation, { data, loading, error }] = useCreateAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAllotmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateAllotmentMutation, CreateAllotmentMutationVariables>) {
        return Apollo.useMutation<CreateAllotmentMutation, CreateAllotmentMutationVariables>(CreateAllotmentDocument, baseOptions);
      }
export type CreateAllotmentMutationHookResult = ReturnType<typeof useCreateAllotmentMutation>;
export type CreateAllotmentMutationResult = Apollo.MutationResult<CreateAllotmentMutation>;
export type CreateAllotmentMutationOptions = Apollo.BaseMutationOptions<CreateAllotmentMutation, CreateAllotmentMutationVariables>;
export const UpdateAllotmentDocument = gql`
    mutation UpdateAllotment($input: UpdateAllotmentInput!) {
  updateAllotment(input: $input) {
    ...AllotmentDetailPage
  }
}
    ${AllotmentDetailPageFragmentDoc}`;
export type UpdateAllotmentMutationFn = Apollo.MutationFunction<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;

/**
 * __useUpdateAllotmentMutation__
 *
 * To run a mutation, you first call `useUpdateAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAllotmentMutation, { data, loading, error }] = useUpdateAllotmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAllotmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>) {
        return Apollo.useMutation<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>(UpdateAllotmentDocument, baseOptions);
      }
export type UpdateAllotmentMutationHookResult = ReturnType<typeof useUpdateAllotmentMutation>;
export type UpdateAllotmentMutationResult = Apollo.MutationResult<UpdateAllotmentMutation>;
export type UpdateAllotmentMutationOptions = Apollo.BaseMutationOptions<UpdateAllotmentMutation, UpdateAllotmentMutationVariables>;
export const DeleteAllotmentDocument = gql`
    mutation DeleteAllotment($id: ID!) {
  deleteAllotment(id: $id) {
    id
  }
}
    `;
export type DeleteAllotmentMutationFn = Apollo.MutationFunction<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>;

/**
 * __useDeleteAllotmentMutation__
 *
 * To run a mutation, you first call `useDeleteAllotmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAllotmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAllotmentMutation, { data, loading, error }] = useDeleteAllotmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAllotmentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>) {
        return Apollo.useMutation<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>(DeleteAllotmentDocument, baseOptions);
      }
export type DeleteAllotmentMutationHookResult = ReturnType<typeof useDeleteAllotmentMutation>;
export type DeleteAllotmentMutationResult = Apollo.MutationResult<DeleteAllotmentMutation>;
export type DeleteAllotmentMutationOptions = Apollo.BaseMutationOptions<DeleteAllotmentMutation, DeleteAllotmentMutationVariables>;
export const IsUserAuthenticatedDocument = gql`
    query IsUserAuthenticated {
  isAuthenticated @client
}
    `;

/**
 * __useIsUserAuthenticatedQuery__
 *
 * To run a query within a React component, call `useIsUserAuthenticatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserAuthenticatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserAuthenticatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsUserAuthenticatedQuery(baseOptions?: Apollo.QueryHookOptions<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>) {
        return Apollo.useQuery<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>(IsUserAuthenticatedDocument, baseOptions);
      }
export function useIsUserAuthenticatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>) {
          return Apollo.useLazyQuery<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>(IsUserAuthenticatedDocument, baseOptions);
        }
export type IsUserAuthenticatedQueryHookResult = ReturnType<typeof useIsUserAuthenticatedQuery>;
export type IsUserAuthenticatedLazyQueryHookResult = ReturnType<typeof useIsUserAuthenticatedLazyQuery>;
export type IsUserAuthenticatedQueryResult = Apollo.QueryResult<IsUserAuthenticatedQuery, IsUserAuthenticatedQueryVariables>;
export const CurrentTenantDocument = gql`
    query CurrentTenant {
  currentTenant {
    id
    subdomain
    name
    use_legacy_newsletter
  }
}
    `;

/**
 * __useCurrentTenantQuery__
 *
 * To run a query within a React component, call `useCurrentTenantQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTenantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTenantQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTenantQuery(baseOptions?: Apollo.QueryHookOptions<CurrentTenantQuery, CurrentTenantQueryVariables>) {
        return Apollo.useQuery<CurrentTenantQuery, CurrentTenantQueryVariables>(CurrentTenantDocument, baseOptions);
      }
export function useCurrentTenantLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentTenantQuery, CurrentTenantQueryVariables>) {
          return Apollo.useLazyQuery<CurrentTenantQuery, CurrentTenantQueryVariables>(CurrentTenantDocument, baseOptions);
        }
export type CurrentTenantQueryHookResult = ReturnType<typeof useCurrentTenantQuery>;
export type CurrentTenantLazyQueryHookResult = ReturnType<typeof useCurrentTenantLazyQuery>;
export type CurrentTenantQueryResult = Apollo.QueryResult<CurrentTenantQuery, CurrentTenantQueryVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    user {
      id
      email
      display_name
      permissions {
        id
        name
      }
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    status
    message
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    status
    message
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const UpdateForgottenPasswordDocument = gql`
    mutation UpdateForgottenPassword($input: NewPasswordWithCodeInput!) {
  updateForgottenPassword(input: $input) {
    status
    message
  }
}
    `;
export type UpdateForgottenPasswordMutationFn = Apollo.MutationFunction<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>;

/**
 * __useUpdateForgottenPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateForgottenPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateForgottenPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateForgottenPasswordMutation, { data, loading, error }] = useUpdateForgottenPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateForgottenPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>) {
        return Apollo.useMutation<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>(UpdateForgottenPasswordDocument, baseOptions);
      }
export type UpdateForgottenPasswordMutationHookResult = ReturnType<typeof useUpdateForgottenPasswordMutation>;
export type UpdateForgottenPasswordMutationResult = Apollo.MutationResult<UpdateForgottenPasswordMutation>;
export type UpdateForgottenPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>;
export const CategoryListDocument = gql`
    query CategoryList($trashed: Trashed) {
  categories(trashed: $trashed) {
    ...CategoryListItem
  }
}
    ${CategoryListItemFragmentDoc}`;

/**
 * __useCategoryListQuery__
 *
 * To run a query within a React component, call `useCategoryListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryListQuery({
 *   variables: {
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useCategoryListQuery(baseOptions?: Apollo.QueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
        return Apollo.useQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
      }
export function useCategoryListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryListQuery, CategoryListQueryVariables>) {
          return Apollo.useLazyQuery<CategoryListQuery, CategoryListQueryVariables>(CategoryListDocument, baseOptions);
        }
export type CategoryListQueryHookResult = ReturnType<typeof useCategoryListQuery>;
export type CategoryListLazyQueryHookResult = ReturnType<typeof useCategoryListLazyQuery>;
export type CategoryListQueryResult = Apollo.QueryResult<CategoryListQuery, CategoryListQueryVariables>;
export const CategoryDetailDocument = gql`
    query CategoryDetail($id: ID!) {
  category(id: $id) {
    id
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;

/**
 * __useCategoryDetailQuery__
 *
 * To run a query within a React component, call `useCategoryDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryDetailQuery(baseOptions?: Apollo.QueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
        return Apollo.useQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, baseOptions);
      }
export function useCategoryDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryDetailQuery, CategoryDetailQueryVariables>) {
          return Apollo.useLazyQuery<CategoryDetailQuery, CategoryDetailQueryVariables>(CategoryDetailDocument, baseOptions);
        }
export type CategoryDetailQueryHookResult = ReturnType<typeof useCategoryDetailQuery>;
export type CategoryDetailLazyQueryHookResult = ReturnType<typeof useCategoryDetailLazyQuery>;
export type CategoryDetailQueryResult = Apollo.QueryResult<CategoryDetailQuery, CategoryDetailQueryVariables>;
export const CategoryEditDocument = gql`
    query CategoryEdit($id: ID!) {
  category(id: $id) {
    id
    ...CategoryEditForm
  }
}
    ${CategoryEditFormFragmentDoc}`;

/**
 * __useCategoryEditQuery__
 *
 * To run a query within a React component, call `useCategoryEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCategoryEditQuery(baseOptions?: Apollo.QueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
        return Apollo.useQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
      }
export function useCategoryEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryEditQuery, CategoryEditQueryVariables>) {
          return Apollo.useLazyQuery<CategoryEditQuery, CategoryEditQueryVariables>(CategoryEditDocument, baseOptions);
        }
export type CategoryEditQueryHookResult = ReturnType<typeof useCategoryEditQuery>;
export type CategoryEditLazyQueryHookResult = ReturnType<typeof useCategoryEditLazyQuery>;
export type CategoryEditQueryResult = Apollo.QueryResult<CategoryEditQuery, CategoryEditQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    ...CategoryDetailPage
  }
}
    ${CategoryDetailPageFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: ID!) {
  deleteCategory(id: $id) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const RestoreCategoriesDocument = gql`
    mutation RestoreCategories($ids: [ID!]!) {
  restoreCategories(ids: $ids) {
    id
  }
}
    `;
export type RestoreCategoriesMutationFn = Apollo.MutationFunction<RestoreCategoriesMutation, RestoreCategoriesMutationVariables>;

/**
 * __useRestoreCategoriesMutation__
 *
 * To run a mutation, you first call `useRestoreCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCategoriesMutation, { data, loading, error }] = useRestoreCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<RestoreCategoriesMutation, RestoreCategoriesMutationVariables>) {
        return Apollo.useMutation<RestoreCategoriesMutation, RestoreCategoriesMutationVariables>(RestoreCategoriesDocument, baseOptions);
      }
export type RestoreCategoriesMutationHookResult = ReturnType<typeof useRestoreCategoriesMutation>;
export type RestoreCategoriesMutationResult = Apollo.MutationResult<RestoreCategoriesMutation>;
export type RestoreCategoriesMutationOptions = Apollo.BaseMutationOptions<RestoreCategoriesMutation, RestoreCategoriesMutationVariables>;
export const ForceDeleteCategoriesDocument = gql`
    mutation ForceDeleteCategories($ids: [ID!]!) {
  forceDeleteCategories(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteCategoriesMutationFn = Apollo.MutationFunction<ForceDeleteCategoriesMutation, ForceDeleteCategoriesMutationVariables>;

/**
 * __useForceDeleteCategoriesMutation__
 *
 * To run a mutation, you first call `useForceDeleteCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteCategoriesMutation, { data, loading, error }] = useForceDeleteCategoriesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteCategoriesMutation, ForceDeleteCategoriesMutationVariables>) {
        return Apollo.useMutation<ForceDeleteCategoriesMutation, ForceDeleteCategoriesMutationVariables>(ForceDeleteCategoriesDocument, baseOptions);
      }
export type ForceDeleteCategoriesMutationHookResult = ReturnType<typeof useForceDeleteCategoriesMutation>;
export type ForceDeleteCategoriesMutationResult = Apollo.MutationResult<ForceDeleteCategoriesMutation>;
export type ForceDeleteCategoriesMutationOptions = Apollo.BaseMutationOptions<ForceDeleteCategoriesMutation, ForceDeleteCategoriesMutationVariables>;
export const EmptyCategoryTrashDocument = gql`
    mutation EmptyCategoryTrash {
  emptyCategoryTrash {
    id
  }
}
    `;
export type EmptyCategoryTrashMutationFn = Apollo.MutationFunction<EmptyCategoryTrashMutation, EmptyCategoryTrashMutationVariables>;

/**
 * __useEmptyCategoryTrashMutation__
 *
 * To run a mutation, you first call `useEmptyCategoryTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyCategoryTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyCategoryTrashMutation, { data, loading, error }] = useEmptyCategoryTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyCategoryTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyCategoryTrashMutation, EmptyCategoryTrashMutationVariables>) {
        return Apollo.useMutation<EmptyCategoryTrashMutation, EmptyCategoryTrashMutationVariables>(EmptyCategoryTrashDocument, baseOptions);
      }
export type EmptyCategoryTrashMutationHookResult = ReturnType<typeof useEmptyCategoryTrashMutation>;
export type EmptyCategoryTrashMutationResult = Apollo.MutationResult<EmptyCategoryTrashMutation>;
export type EmptyCategoryTrashMutationOptions = Apollo.BaseMutationOptions<EmptyCategoryTrashMutation, EmptyCategoryTrashMutationVariables>;
export const CompanionListDocument = gql`
    query CompanionList($status: UserStatus, $page: Int, $trashed: Trashed = WITHOUT) {
  companions(status: $status, first: 20, page: $page, trashed: $trashed) {
    data {
      ...CompanionListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${CompanionListItemFragmentDoc}`;

/**
 * __useCompanionListQuery__
 *
 * To run a query within a React component, call `useCompanionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useCompanionListQuery(baseOptions?: Apollo.QueryHookOptions<CompanionListQuery, CompanionListQueryVariables>) {
        return Apollo.useQuery<CompanionListQuery, CompanionListQueryVariables>(CompanionListDocument, baseOptions);
      }
export function useCompanionListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionListQuery, CompanionListQueryVariables>) {
          return Apollo.useLazyQuery<CompanionListQuery, CompanionListQueryVariables>(CompanionListDocument, baseOptions);
        }
export type CompanionListQueryHookResult = ReturnType<typeof useCompanionListQuery>;
export type CompanionListLazyQueryHookResult = ReturnType<typeof useCompanionListLazyQuery>;
export type CompanionListQueryResult = Apollo.QueryResult<CompanionListQuery, CompanionListQueryVariables>;
export const CompanionDetailDocument = gql`
    query CompanionDetail($id: ID!) {
  companion(id: $id) {
    id
    ...CompanionInfo
  }
}
    ${CompanionInfoFragmentDoc}`;

/**
 * __useCompanionDetailQuery__
 *
 * To run a query within a React component, call `useCompanionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionDetailQuery(baseOptions?: Apollo.QueryHookOptions<CompanionDetailQuery, CompanionDetailQueryVariables>) {
        return Apollo.useQuery<CompanionDetailQuery, CompanionDetailQueryVariables>(CompanionDetailDocument, baseOptions);
      }
export function useCompanionDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionDetailQuery, CompanionDetailQueryVariables>) {
          return Apollo.useLazyQuery<CompanionDetailQuery, CompanionDetailQueryVariables>(CompanionDetailDocument, baseOptions);
        }
export type CompanionDetailQueryHookResult = ReturnType<typeof useCompanionDetailQuery>;
export type CompanionDetailLazyQueryHookResult = ReturnType<typeof useCompanionDetailLazyQuery>;
export type CompanionDetailQueryResult = Apollo.QueryResult<CompanionDetailQuery, CompanionDetailQueryVariables>;
export const CompanionEmailsDocument = gql`
    query CompanionEmails($id: ID!, $page: Int) {
  companion(id: $id) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useCompanionEmailsQuery__
 *
 * To run a query within a React component, call `useCompanionEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionEmailsQuery(baseOptions?: Apollo.QueryHookOptions<CompanionEmailsQuery, CompanionEmailsQueryVariables>) {
        return Apollo.useQuery<CompanionEmailsQuery, CompanionEmailsQueryVariables>(CompanionEmailsDocument, baseOptions);
      }
export function useCompanionEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionEmailsQuery, CompanionEmailsQueryVariables>) {
          return Apollo.useLazyQuery<CompanionEmailsQuery, CompanionEmailsQueryVariables>(CompanionEmailsDocument, baseOptions);
        }
export type CompanionEmailsQueryHookResult = ReturnType<typeof useCompanionEmailsQuery>;
export type CompanionEmailsLazyQueryHookResult = ReturnType<typeof useCompanionEmailsLazyQuery>;
export type CompanionEmailsQueryResult = Apollo.QueryResult<CompanionEmailsQuery, CompanionEmailsQueryVariables>;
export const CompanionEditDocument = gql`
    query CompanionEdit($id: ID!) {
  companion(id: $id) {
    ...CompanionEditForm
  }
}
    ${CompanionEditFormFragmentDoc}`;

/**
 * __useCompanionEditQuery__
 *
 * To run a query within a React component, call `useCompanionEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionEditQuery(baseOptions?: Apollo.QueryHookOptions<CompanionEditQuery, CompanionEditQueryVariables>) {
        return Apollo.useQuery<CompanionEditQuery, CompanionEditQueryVariables>(CompanionEditDocument, baseOptions);
      }
export function useCompanionEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionEditQuery, CompanionEditQueryVariables>) {
          return Apollo.useLazyQuery<CompanionEditQuery, CompanionEditQueryVariables>(CompanionEditDocument, baseOptions);
        }
export type CompanionEditQueryHookResult = ReturnType<typeof useCompanionEditQuery>;
export type CompanionEditLazyQueryHookResult = ReturnType<typeof useCompanionEditLazyQuery>;
export type CompanionEditQueryResult = Apollo.QueryResult<CompanionEditQuery, CompanionEditQueryVariables>;
export const CreateCompanionDocument = gql`
    mutation CreateCompanion($input: CreateCompanionInput!) {
  createCompanion(input: $input) {
    ...CompanionInfo
  }
}
    ${CompanionInfoFragmentDoc}`;
export type CreateCompanionMutationFn = Apollo.MutationFunction<CreateCompanionMutation, CreateCompanionMutationVariables>;

/**
 * __useCreateCompanionMutation__
 *
 * To run a mutation, you first call `useCreateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanionMutation, { data, loading, error }] = useCreateCompanionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanionMutation, CreateCompanionMutationVariables>) {
        return Apollo.useMutation<CreateCompanionMutation, CreateCompanionMutationVariables>(CreateCompanionDocument, baseOptions);
      }
export type CreateCompanionMutationHookResult = ReturnType<typeof useCreateCompanionMutation>;
export type CreateCompanionMutationResult = Apollo.MutationResult<CreateCompanionMutation>;
export type CreateCompanionMutationOptions = Apollo.BaseMutationOptions<CreateCompanionMutation, CreateCompanionMutationVariables>;
export const UpdateCompanionDocument = gql`
    mutation UpdateCompanion($input: UpdateCompanionInput!) {
  updateCompanion(input: $input) {
    ...CompanionInfo
  }
}
    ${CompanionInfoFragmentDoc}`;
export type UpdateCompanionMutationFn = Apollo.MutationFunction<UpdateCompanionMutation, UpdateCompanionMutationVariables>;

/**
 * __useUpdateCompanionMutation__
 *
 * To run a mutation, you first call `useUpdateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanionMutation, { data, loading, error }] = useUpdateCompanionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanionMutation, UpdateCompanionMutationVariables>) {
        return Apollo.useMutation<UpdateCompanionMutation, UpdateCompanionMutationVariables>(UpdateCompanionDocument, baseOptions);
      }
export type UpdateCompanionMutationHookResult = ReturnType<typeof useUpdateCompanionMutation>;
export type UpdateCompanionMutationResult = Apollo.MutationResult<UpdateCompanionMutation>;
export type UpdateCompanionMutationOptions = Apollo.BaseMutationOptions<UpdateCompanionMutation, UpdateCompanionMutationVariables>;
export const ApproveCompanionDocument = gql`
    mutation ApproveCompanion($id: ID!) {
  approveCompanion(id: $id) {
    id
    status
  }
}
    `;
export type ApproveCompanionMutationFn = Apollo.MutationFunction<ApproveCompanionMutation, ApproveCompanionMutationVariables>;

/**
 * __useApproveCompanionMutation__
 *
 * To run a mutation, you first call `useApproveCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCompanionMutation, { data, loading, error }] = useApproveCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveCompanionMutation(baseOptions?: Apollo.MutationHookOptions<ApproveCompanionMutation, ApproveCompanionMutationVariables>) {
        return Apollo.useMutation<ApproveCompanionMutation, ApproveCompanionMutationVariables>(ApproveCompanionDocument, baseOptions);
      }
export type ApproveCompanionMutationHookResult = ReturnType<typeof useApproveCompanionMutation>;
export type ApproveCompanionMutationResult = Apollo.MutationResult<ApproveCompanionMutation>;
export type ApproveCompanionMutationOptions = Apollo.BaseMutationOptions<ApproveCompanionMutation, ApproveCompanionMutationVariables>;
export const DenyCompanionDocument = gql`
    mutation DenyCompanion($id: ID!) {
  denyCompanion(id: $id) {
    id
    status
  }
}
    `;
export type DenyCompanionMutationFn = Apollo.MutationFunction<DenyCompanionMutation, DenyCompanionMutationVariables>;

/**
 * __useDenyCompanionMutation__
 *
 * To run a mutation, you first call `useDenyCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenyCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denyCompanionMutation, { data, loading, error }] = useDenyCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDenyCompanionMutation(baseOptions?: Apollo.MutationHookOptions<DenyCompanionMutation, DenyCompanionMutationVariables>) {
        return Apollo.useMutation<DenyCompanionMutation, DenyCompanionMutationVariables>(DenyCompanionDocument, baseOptions);
      }
export type DenyCompanionMutationHookResult = ReturnType<typeof useDenyCompanionMutation>;
export type DenyCompanionMutationResult = Apollo.MutationResult<DenyCompanionMutation>;
export type DenyCompanionMutationOptions = Apollo.BaseMutationOptions<DenyCompanionMutation, DenyCompanionMutationVariables>;
export const ActivateCompanionDocument = gql`
    mutation ActivateCompanion($id: ID!) {
  activateCompanion(id: $id) {
    id
    status
  }
}
    `;
export type ActivateCompanionMutationFn = Apollo.MutationFunction<ActivateCompanionMutation, ActivateCompanionMutationVariables>;

/**
 * __useActivateCompanionMutation__
 *
 * To run a mutation, you first call `useActivateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanionMutation, { data, loading, error }] = useActivateCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateCompanionMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCompanionMutation, ActivateCompanionMutationVariables>) {
        return Apollo.useMutation<ActivateCompanionMutation, ActivateCompanionMutationVariables>(ActivateCompanionDocument, baseOptions);
      }
export type ActivateCompanionMutationHookResult = ReturnType<typeof useActivateCompanionMutation>;
export type ActivateCompanionMutationResult = Apollo.MutationResult<ActivateCompanionMutation>;
export type ActivateCompanionMutationOptions = Apollo.BaseMutationOptions<ActivateCompanionMutation, ActivateCompanionMutationVariables>;
export const DeactivateCompanionDocument = gql`
    mutation DeactivateCompanion($id: ID!) {
  deactivateCompanion(id: $id) {
    id
    status
  }
}
    `;
export type DeactivateCompanionMutationFn = Apollo.MutationFunction<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>;

/**
 * __useDeactivateCompanionMutation__
 *
 * To run a mutation, you first call `useDeactivateCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateCompanionMutation, { data, loading, error }] = useDeactivateCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateCompanionMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>) {
        return Apollo.useMutation<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>(DeactivateCompanionDocument, baseOptions);
      }
export type DeactivateCompanionMutationHookResult = ReturnType<typeof useDeactivateCompanionMutation>;
export type DeactivateCompanionMutationResult = Apollo.MutationResult<DeactivateCompanionMutation>;
export type DeactivateCompanionMutationOptions = Apollo.BaseMutationOptions<DeactivateCompanionMutation, DeactivateCompanionMutationVariables>;
export const DeleteCompanionDocument = gql`
    mutation DeleteCompanion($id: ID!) {
  deleteCompanion(id: $id) {
    id
  }
}
    `;
export type DeleteCompanionMutationFn = Apollo.MutationFunction<DeleteCompanionMutation, DeleteCompanionMutationVariables>;

/**
 * __useDeleteCompanionMutation__
 *
 * To run a mutation, you first call `useDeleteCompanionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanionMutation, { data, loading, error }] = useDeleteCompanionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanionMutation, DeleteCompanionMutationVariables>) {
        return Apollo.useMutation<DeleteCompanionMutation, DeleteCompanionMutationVariables>(DeleteCompanionDocument, baseOptions);
      }
export type DeleteCompanionMutationHookResult = ReturnType<typeof useDeleteCompanionMutation>;
export type DeleteCompanionMutationResult = Apollo.MutationResult<DeleteCompanionMutation>;
export type DeleteCompanionMutationOptions = Apollo.BaseMutationOptions<DeleteCompanionMutation, DeleteCompanionMutationVariables>;
export const RestoreCompanionsDocument = gql`
    mutation RestoreCompanions($ids: [ID!]!) {
  restoreCompanions(ids: $ids) {
    id
  }
}
    `;
export type RestoreCompanionsMutationFn = Apollo.MutationFunction<RestoreCompanionsMutation, RestoreCompanionsMutationVariables>;

/**
 * __useRestoreCompanionsMutation__
 *
 * To run a mutation, you first call `useRestoreCompanionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreCompanionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreCompanionsMutation, { data, loading, error }] = useRestoreCompanionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreCompanionsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreCompanionsMutation, RestoreCompanionsMutationVariables>) {
        return Apollo.useMutation<RestoreCompanionsMutation, RestoreCompanionsMutationVariables>(RestoreCompanionsDocument, baseOptions);
      }
export type RestoreCompanionsMutationHookResult = ReturnType<typeof useRestoreCompanionsMutation>;
export type RestoreCompanionsMutationResult = Apollo.MutationResult<RestoreCompanionsMutation>;
export type RestoreCompanionsMutationOptions = Apollo.BaseMutationOptions<RestoreCompanionsMutation, RestoreCompanionsMutationVariables>;
export const ForceDeleteCompanionsDocument = gql`
    mutation ForceDeleteCompanions($ids: [ID!]!) {
  forceDeleteCompanions(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteCompanionsMutationFn = Apollo.MutationFunction<ForceDeleteCompanionsMutation, ForceDeleteCompanionsMutationVariables>;

/**
 * __useForceDeleteCompanionsMutation__
 *
 * To run a mutation, you first call `useForceDeleteCompanionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteCompanionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteCompanionsMutation, { data, loading, error }] = useForceDeleteCompanionsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteCompanionsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteCompanionsMutation, ForceDeleteCompanionsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteCompanionsMutation, ForceDeleteCompanionsMutationVariables>(ForceDeleteCompanionsDocument, baseOptions);
      }
export type ForceDeleteCompanionsMutationHookResult = ReturnType<typeof useForceDeleteCompanionsMutation>;
export type ForceDeleteCompanionsMutationResult = Apollo.MutationResult<ForceDeleteCompanionsMutation>;
export type ForceDeleteCompanionsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteCompanionsMutation, ForceDeleteCompanionsMutationVariables>;
export const EmptyCompanionTrashDocument = gql`
    mutation EmptyCompanionTrash {
  emptyCompanionTrash {
    id
  }
}
    `;
export type EmptyCompanionTrashMutationFn = Apollo.MutationFunction<EmptyCompanionTrashMutation, EmptyCompanionTrashMutationVariables>;

/**
 * __useEmptyCompanionTrashMutation__
 *
 * To run a mutation, you first call `useEmptyCompanionTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyCompanionTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyCompanionTrashMutation, { data, loading, error }] = useEmptyCompanionTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyCompanionTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyCompanionTrashMutation, EmptyCompanionTrashMutationVariables>) {
        return Apollo.useMutation<EmptyCompanionTrashMutation, EmptyCompanionTrashMutationVariables>(EmptyCompanionTrashDocument, baseOptions);
      }
export type EmptyCompanionTrashMutationHookResult = ReturnType<typeof useEmptyCompanionTrashMutation>;
export type EmptyCompanionTrashMutationResult = Apollo.MutationResult<EmptyCompanionTrashMutation>;
export type EmptyCompanionTrashMutationOptions = Apollo.BaseMutationOptions<EmptyCompanionTrashMutation, EmptyCompanionTrashMutationVariables>;
export const ContactListDocument = gql`
    query ContactList($trashed: Trashed) {
  contacts(trashed: $trashed) {
    ...ContactListItem
  }
}
    ${ContactListItemFragmentDoc}`;

/**
 * __useContactListQuery__
 *
 * To run a query within a React component, call `useContactListQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactListQuery({
 *   variables: {
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useContactListQuery(baseOptions?: Apollo.QueryHookOptions<ContactListQuery, ContactListQueryVariables>) {
        return Apollo.useQuery<ContactListQuery, ContactListQueryVariables>(ContactListDocument, baseOptions);
      }
export function useContactListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactListQuery, ContactListQueryVariables>) {
          return Apollo.useLazyQuery<ContactListQuery, ContactListQueryVariables>(ContactListDocument, baseOptions);
        }
export type ContactListQueryHookResult = ReturnType<typeof useContactListQuery>;
export type ContactListLazyQueryHookResult = ReturnType<typeof useContactListLazyQuery>;
export type ContactListQueryResult = Apollo.QueryResult<ContactListQuery, ContactListQueryVariables>;
export const ContactDetailDocument = gql`
    query ContactDetail($id: ID!) {
  contact(id: $id) {
    id
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;

/**
 * __useContactDetailQuery__
 *
 * To run a query within a React component, call `useContactDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactDetailQuery(baseOptions?: Apollo.QueryHookOptions<ContactDetailQuery, ContactDetailQueryVariables>) {
        return Apollo.useQuery<ContactDetailQuery, ContactDetailQueryVariables>(ContactDetailDocument, baseOptions);
      }
export function useContactDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactDetailQuery, ContactDetailQueryVariables>) {
          return Apollo.useLazyQuery<ContactDetailQuery, ContactDetailQueryVariables>(ContactDetailDocument, baseOptions);
        }
export type ContactDetailQueryHookResult = ReturnType<typeof useContactDetailQuery>;
export type ContactDetailLazyQueryHookResult = ReturnType<typeof useContactDetailLazyQuery>;
export type ContactDetailQueryResult = Apollo.QueryResult<ContactDetailQuery, ContactDetailQueryVariables>;
export const ContactEditDocument = gql`
    query ContactEdit($id: ID!) {
  contact(id: $id) {
    id
    ...ContactEditForm
  }
}
    ${ContactEditFormFragmentDoc}`;

/**
 * __useContactEditQuery__
 *
 * To run a query within a React component, call `useContactEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContactEditQuery(baseOptions?: Apollo.QueryHookOptions<ContactEditQuery, ContactEditQueryVariables>) {
        return Apollo.useQuery<ContactEditQuery, ContactEditQueryVariables>(ContactEditDocument, baseOptions);
      }
export function useContactEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactEditQuery, ContactEditQueryVariables>) {
          return Apollo.useLazyQuery<ContactEditQuery, ContactEditQueryVariables>(ContactEditDocument, baseOptions);
        }
export type ContactEditQueryHookResult = ReturnType<typeof useContactEditQuery>;
export type ContactEditLazyQueryHookResult = ReturnType<typeof useContactEditLazyQuery>;
export type ContactEditQueryResult = Apollo.QueryResult<ContactEditQuery, ContactEditQueryVariables>;
export const ContactEmailsDocument = gql`
    query ContactEmails($id: ID!, $page: Int) {
  contact(id: $id) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useContactEmailsQuery__
 *
 * To run a query within a React component, call `useContactEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContactEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContactEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useContactEmailsQuery(baseOptions?: Apollo.QueryHookOptions<ContactEmailsQuery, ContactEmailsQueryVariables>) {
        return Apollo.useQuery<ContactEmailsQuery, ContactEmailsQueryVariables>(ContactEmailsDocument, baseOptions);
      }
export function useContactEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactEmailsQuery, ContactEmailsQueryVariables>) {
          return Apollo.useLazyQuery<ContactEmailsQuery, ContactEmailsQueryVariables>(ContactEmailsDocument, baseOptions);
        }
export type ContactEmailsQueryHookResult = ReturnType<typeof useContactEmailsQuery>;
export type ContactEmailsLazyQueryHookResult = ReturnType<typeof useContactEmailsLazyQuery>;
export type ContactEmailsQueryResult = Apollo.QueryResult<ContactEmailsQuery, ContactEmailsQueryVariables>;
export const CreateContactDocument = gql`
    mutation CreateContact($input: CreateContactInput!) {
  createContact(input: $input) {
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, baseOptions);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const UpdateContactDocument = gql`
    mutation UpdateContact($input: UpdateContactInput!) {
  updateContact(input: $input) {
    ...ContactInfo
  }
}
    ${ContactInfoFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, baseOptions);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation DeleteContact($id: ID!) {
  deleteContact(id: $id) {
    id
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const RestoreContactsDocument = gql`
    mutation RestoreContacts($ids: [ID!]!) {
  restoreContacts(ids: $ids) {
    id
  }
}
    `;
export type RestoreContactsMutationFn = Apollo.MutationFunction<RestoreContactsMutation, RestoreContactsMutationVariables>;

/**
 * __useRestoreContactsMutation__
 *
 * To run a mutation, you first call `useRestoreContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreContactsMutation, { data, loading, error }] = useRestoreContactsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreContactsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreContactsMutation, RestoreContactsMutationVariables>) {
        return Apollo.useMutation<RestoreContactsMutation, RestoreContactsMutationVariables>(RestoreContactsDocument, baseOptions);
      }
export type RestoreContactsMutationHookResult = ReturnType<typeof useRestoreContactsMutation>;
export type RestoreContactsMutationResult = Apollo.MutationResult<RestoreContactsMutation>;
export type RestoreContactsMutationOptions = Apollo.BaseMutationOptions<RestoreContactsMutation, RestoreContactsMutationVariables>;
export const ForceDeleteContactsDocument = gql`
    mutation ForceDeleteContacts($ids: [ID!]!) {
  forceDeleteContacts(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteContactsMutationFn = Apollo.MutationFunction<ForceDeleteContactsMutation, ForceDeleteContactsMutationVariables>;

/**
 * __useForceDeleteContactsMutation__
 *
 * To run a mutation, you first call `useForceDeleteContactsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteContactsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteContactsMutation, { data, loading, error }] = useForceDeleteContactsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteContactsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteContactsMutation, ForceDeleteContactsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteContactsMutation, ForceDeleteContactsMutationVariables>(ForceDeleteContactsDocument, baseOptions);
      }
export type ForceDeleteContactsMutationHookResult = ReturnType<typeof useForceDeleteContactsMutation>;
export type ForceDeleteContactsMutationResult = Apollo.MutationResult<ForceDeleteContactsMutation>;
export type ForceDeleteContactsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteContactsMutation, ForceDeleteContactsMutationVariables>;
export const EmptyContactTrashDocument = gql`
    mutation EmptyContactTrash {
  emptyContactTrash {
    id
  }
}
    `;
export type EmptyContactTrashMutationFn = Apollo.MutationFunction<EmptyContactTrashMutation, EmptyContactTrashMutationVariables>;

/**
 * __useEmptyContactTrashMutation__
 *
 * To run a mutation, you first call `useEmptyContactTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyContactTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyContactTrashMutation, { data, loading, error }] = useEmptyContactTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyContactTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyContactTrashMutation, EmptyContactTrashMutationVariables>) {
        return Apollo.useMutation<EmptyContactTrashMutation, EmptyContactTrashMutationVariables>(EmptyContactTrashDocument, baseOptions);
      }
export type EmptyContactTrashMutationHookResult = ReturnType<typeof useEmptyContactTrashMutation>;
export type EmptyContactTrashMutationResult = Apollo.MutationResult<EmptyContactTrashMutation>;
export type EmptyContactTrashMutationOptions = Apollo.BaseMutationOptions<EmptyContactTrashMutation, EmptyContactTrashMutationVariables>;
export const DonorListDocument = gql`
    query DonorList($page: Int, $trashed: Trashed) {
  donors(first: 20, page: $page, trashed: $trashed) {
    data {
      ...DonorListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${DonorListItemFragmentDoc}`;

/**
 * __useDonorListQuery__
 *
 * To run a query within a React component, call `useDonorListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useDonorListQuery(baseOptions?: Apollo.QueryHookOptions<DonorListQuery, DonorListQueryVariables>) {
        return Apollo.useQuery<DonorListQuery, DonorListQueryVariables>(DonorListDocument, baseOptions);
      }
export function useDonorListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorListQuery, DonorListQueryVariables>) {
          return Apollo.useLazyQuery<DonorListQuery, DonorListQueryVariables>(DonorListDocument, baseOptions);
        }
export type DonorListQueryHookResult = ReturnType<typeof useDonorListQuery>;
export type DonorListLazyQueryHookResult = ReturnType<typeof useDonorListLazyQuery>;
export type DonorListQueryResult = Apollo.QueryResult<DonorListQuery, DonorListQueryVariables>;
export const DonorDetailDocument = gql`
    query DonorDetail($id: ID!) {
  donor(id: $id) {
    id
    ...DonorInfo
  }
}
    ${DonorInfoFragmentDoc}`;

/**
 * __useDonorDetailQuery__
 *
 * To run a query within a React component, call `useDonorDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorDetailQuery(baseOptions?: Apollo.QueryHookOptions<DonorDetailQuery, DonorDetailQueryVariables>) {
        return Apollo.useQuery<DonorDetailQuery, DonorDetailQueryVariables>(DonorDetailDocument, baseOptions);
      }
export function useDonorDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorDetailQuery, DonorDetailQueryVariables>) {
          return Apollo.useLazyQuery<DonorDetailQuery, DonorDetailQueryVariables>(DonorDetailDocument, baseOptions);
        }
export type DonorDetailQueryHookResult = ReturnType<typeof useDonorDetailQuery>;
export type DonorDetailLazyQueryHookResult = ReturnType<typeof useDonorDetailLazyQuery>;
export type DonorDetailQueryResult = Apollo.QueryResult<DonorDetailQuery, DonorDetailQueryVariables>;
export const DonorContactsDocument = gql`
    query DonorContacts($id: ID!) {
  donor(id: $id) {
    id
    contacts {
      ...ContactListItem
    }
  }
}
    ${ContactListItemFragmentDoc}`;

/**
 * __useDonorContactsQuery__
 *
 * To run a query within a React component, call `useDonorContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorContactsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorContactsQuery(baseOptions?: Apollo.QueryHookOptions<DonorContactsQuery, DonorContactsQueryVariables>) {
        return Apollo.useQuery<DonorContactsQuery, DonorContactsQueryVariables>(DonorContactsDocument, baseOptions);
      }
export function useDonorContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorContactsQuery, DonorContactsQueryVariables>) {
          return Apollo.useLazyQuery<DonorContactsQuery, DonorContactsQueryVariables>(DonorContactsDocument, baseOptions);
        }
export type DonorContactsQueryHookResult = ReturnType<typeof useDonorContactsQuery>;
export type DonorContactsLazyQueryHookResult = ReturnType<typeof useDonorContactsLazyQuery>;
export type DonorContactsQueryResult = Apollo.QueryResult<DonorContactsQuery, DonorContactsQueryVariables>;
export const DonorContactSelectDocument = gql`
    query DonorContactSelect($id: ID!) {
  donor(id: $id) {
    id
    contacts {
      ...ContactSelect
    }
  }
}
    ${ContactSelectFragmentDoc}`;

/**
 * __useDonorContactSelectQuery__
 *
 * To run a query within a React component, call `useDonorContactSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorContactSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorContactSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorContactSelectQuery(baseOptions?: Apollo.QueryHookOptions<DonorContactSelectQuery, DonorContactSelectQueryVariables>) {
        return Apollo.useQuery<DonorContactSelectQuery, DonorContactSelectQueryVariables>(DonorContactSelectDocument, baseOptions);
      }
export function useDonorContactSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorContactSelectQuery, DonorContactSelectQueryVariables>) {
          return Apollo.useLazyQuery<DonorContactSelectQuery, DonorContactSelectQueryVariables>(DonorContactSelectDocument, baseOptions);
        }
export type DonorContactSelectQueryHookResult = ReturnType<typeof useDonorContactSelectQuery>;
export type DonorContactSelectLazyQueryHookResult = ReturnType<typeof useDonorContactSelectLazyQuery>;
export type DonorContactSelectQueryResult = Apollo.QueryResult<DonorContactSelectQuery, DonorContactSelectQueryVariables>;
export const DonorLocationsDocument = gql`
    query DonorLocations($id: ID!) {
  donor(id: $id) {
    id
    locations {
      ...LocationListItem
    }
  }
}
    ${LocationListItemFragmentDoc}`;

/**
 * __useDonorLocationsQuery__
 *
 * To run a query within a React component, call `useDonorLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorLocationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorLocationsQuery(baseOptions?: Apollo.QueryHookOptions<DonorLocationsQuery, DonorLocationsQueryVariables>) {
        return Apollo.useQuery<DonorLocationsQuery, DonorLocationsQueryVariables>(DonorLocationsDocument, baseOptions);
      }
export function useDonorLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorLocationsQuery, DonorLocationsQueryVariables>) {
          return Apollo.useLazyQuery<DonorLocationsQuery, DonorLocationsQueryVariables>(DonorLocationsDocument, baseOptions);
        }
export type DonorLocationsQueryHookResult = ReturnType<typeof useDonorLocationsQuery>;
export type DonorLocationsLazyQueryHookResult = ReturnType<typeof useDonorLocationsLazyQuery>;
export type DonorLocationsQueryResult = Apollo.QueryResult<DonorLocationsQuery, DonorLocationsQueryVariables>;
export const DonorLocationSelectDocument = gql`
    query DonorLocationSelect($id: ID!) {
  donor(id: $id) {
    id
    locations {
      ...LocationSelect
    }
  }
}
    ${LocationSelectFragmentDoc}`;

/**
 * __useDonorLocationSelectQuery__
 *
 * To run a query within a React component, call `useDonorLocationSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorLocationSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorLocationSelectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorLocationSelectQuery(baseOptions?: Apollo.QueryHookOptions<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>) {
        return Apollo.useQuery<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>(DonorLocationSelectDocument, baseOptions);
      }
export function useDonorLocationSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>) {
          return Apollo.useLazyQuery<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>(DonorLocationSelectDocument, baseOptions);
        }
export type DonorLocationSelectQueryHookResult = ReturnType<typeof useDonorLocationSelectQuery>;
export type DonorLocationSelectLazyQueryHookResult = ReturnType<typeof useDonorLocationSelectLazyQuery>;
export type DonorLocationSelectQueryResult = Apollo.QueryResult<DonorLocationSelectQuery, DonorLocationSelectQueryVariables>;
export const CreateDonorDocument = gql`
    mutation CreateDonor($input: CreateDonorInput!) {
  createDonor(input: $input) {
    ...DonorInfo
  }
}
    ${DonorInfoFragmentDoc}`;
export type CreateDonorMutationFn = Apollo.MutationFunction<CreateDonorMutation, CreateDonorMutationVariables>;

/**
 * __useCreateDonorMutation__
 *
 * To run a mutation, you first call `useCreateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDonorMutation, { data, loading, error }] = useCreateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDonorMutation(baseOptions?: Apollo.MutationHookOptions<CreateDonorMutation, CreateDonorMutationVariables>) {
        return Apollo.useMutation<CreateDonorMutation, CreateDonorMutationVariables>(CreateDonorDocument, baseOptions);
      }
export type CreateDonorMutationHookResult = ReturnType<typeof useCreateDonorMutation>;
export type CreateDonorMutationResult = Apollo.MutationResult<CreateDonorMutation>;
export type CreateDonorMutationOptions = Apollo.BaseMutationOptions<CreateDonorMutation, CreateDonorMutationVariables>;
export const DonorEditDocument = gql`
    query DonorEdit($id: ID!) {
  donor(id: $id) {
    id
    ...DonorEditForm
  }
}
    ${DonorEditFormFragmentDoc}`;

/**
 * __useDonorEditQuery__
 *
 * To run a query within a React component, call `useDonorEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useDonorEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDonorEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDonorEditQuery(baseOptions?: Apollo.QueryHookOptions<DonorEditQuery, DonorEditQueryVariables>) {
        return Apollo.useQuery<DonorEditQuery, DonorEditQueryVariables>(DonorEditDocument, baseOptions);
      }
export function useDonorEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DonorEditQuery, DonorEditQueryVariables>) {
          return Apollo.useLazyQuery<DonorEditQuery, DonorEditQueryVariables>(DonorEditDocument, baseOptions);
        }
export type DonorEditQueryHookResult = ReturnType<typeof useDonorEditQuery>;
export type DonorEditLazyQueryHookResult = ReturnType<typeof useDonorEditLazyQuery>;
export type DonorEditQueryResult = Apollo.QueryResult<DonorEditQuery, DonorEditQueryVariables>;
export const UpdateDonorDocument = gql`
    mutation UpdateDonor($input: UpdateDonorInput!) {
  updateDonor(input: $input) {
    ...DonorInfo
  }
}
    ${DonorInfoFragmentDoc}`;
export type UpdateDonorMutationFn = Apollo.MutationFunction<UpdateDonorMutation, UpdateDonorMutationVariables>;

/**
 * __useUpdateDonorMutation__
 *
 * To run a mutation, you first call `useUpdateDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDonorMutation, { data, loading, error }] = useUpdateDonorMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDonorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDonorMutation, UpdateDonorMutationVariables>) {
        return Apollo.useMutation<UpdateDonorMutation, UpdateDonorMutationVariables>(UpdateDonorDocument, baseOptions);
      }
export type UpdateDonorMutationHookResult = ReturnType<typeof useUpdateDonorMutation>;
export type UpdateDonorMutationResult = Apollo.MutationResult<UpdateDonorMutation>;
export type UpdateDonorMutationOptions = Apollo.BaseMutationOptions<UpdateDonorMutation, UpdateDonorMutationVariables>;
export const SetDonorLogoDocument = gql`
    mutation SetDonorLogo($input: SetDonorLogoInput!) {
  setDonorLogo(input: $input) {
    id
    logo {
      name
      thumb_url
    }
  }
}
    `;
export type SetDonorLogoMutationFn = Apollo.MutationFunction<SetDonorLogoMutation, SetDonorLogoMutationVariables>;

/**
 * __useSetDonorLogoMutation__
 *
 * To run a mutation, you first call `useSetDonorLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDonorLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDonorLogoMutation, { data, loading, error }] = useSetDonorLogoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetDonorLogoMutation(baseOptions?: Apollo.MutationHookOptions<SetDonorLogoMutation, SetDonorLogoMutationVariables>) {
        return Apollo.useMutation<SetDonorLogoMutation, SetDonorLogoMutationVariables>(SetDonorLogoDocument, baseOptions);
      }
export type SetDonorLogoMutationHookResult = ReturnType<typeof useSetDonorLogoMutation>;
export type SetDonorLogoMutationResult = Apollo.MutationResult<SetDonorLogoMutation>;
export type SetDonorLogoMutationOptions = Apollo.BaseMutationOptions<SetDonorLogoMutation, SetDonorLogoMutationVariables>;
export const DeleteDonorDocument = gql`
    mutation DeleteDonor($id: ID!) {
  deleteDonor(id: $id) {
    id
  }
}
    `;
export type DeleteDonorMutationFn = Apollo.MutationFunction<DeleteDonorMutation, DeleteDonorMutationVariables>;

/**
 * __useDeleteDonorMutation__
 *
 * To run a mutation, you first call `useDeleteDonorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDonorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDonorMutation, { data, loading, error }] = useDeleteDonorMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDonorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDonorMutation, DeleteDonorMutationVariables>) {
        return Apollo.useMutation<DeleteDonorMutation, DeleteDonorMutationVariables>(DeleteDonorDocument, baseOptions);
      }
export type DeleteDonorMutationHookResult = ReturnType<typeof useDeleteDonorMutation>;
export type DeleteDonorMutationResult = Apollo.MutationResult<DeleteDonorMutation>;
export type DeleteDonorMutationOptions = Apollo.BaseMutationOptions<DeleteDonorMutation, DeleteDonorMutationVariables>;
export const RestoreDonorsDocument = gql`
    mutation RestoreDonors($ids: [ID!]!) {
  restoreDonors(ids: $ids) {
    id
  }
}
    `;
export type RestoreDonorsMutationFn = Apollo.MutationFunction<RestoreDonorsMutation, RestoreDonorsMutationVariables>;

/**
 * __useRestoreDonorsMutation__
 *
 * To run a mutation, you first call `useRestoreDonorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreDonorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreDonorsMutation, { data, loading, error }] = useRestoreDonorsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreDonorsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreDonorsMutation, RestoreDonorsMutationVariables>) {
        return Apollo.useMutation<RestoreDonorsMutation, RestoreDonorsMutationVariables>(RestoreDonorsDocument, baseOptions);
      }
export type RestoreDonorsMutationHookResult = ReturnType<typeof useRestoreDonorsMutation>;
export type RestoreDonorsMutationResult = Apollo.MutationResult<RestoreDonorsMutation>;
export type RestoreDonorsMutationOptions = Apollo.BaseMutationOptions<RestoreDonorsMutation, RestoreDonorsMutationVariables>;
export const ForceDeleteDonorsDocument = gql`
    mutation ForceDeleteDonors($ids: [ID!]!) {
  forceDeleteDonors(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteDonorsMutationFn = Apollo.MutationFunction<ForceDeleteDonorsMutation, ForceDeleteDonorsMutationVariables>;

/**
 * __useForceDeleteDonorsMutation__
 *
 * To run a mutation, you first call `useForceDeleteDonorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteDonorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteDonorsMutation, { data, loading, error }] = useForceDeleteDonorsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteDonorsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteDonorsMutation, ForceDeleteDonorsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteDonorsMutation, ForceDeleteDonorsMutationVariables>(ForceDeleteDonorsDocument, baseOptions);
      }
export type ForceDeleteDonorsMutationHookResult = ReturnType<typeof useForceDeleteDonorsMutation>;
export type ForceDeleteDonorsMutationResult = Apollo.MutationResult<ForceDeleteDonorsMutation>;
export type ForceDeleteDonorsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteDonorsMutation, ForceDeleteDonorsMutationVariables>;
export const EmptyDonorTrashDocument = gql`
    mutation EmptyDonorTrash {
  emptyDonorTrash {
    id
  }
}
    `;
export type EmptyDonorTrashMutationFn = Apollo.MutationFunction<EmptyDonorTrashMutation, EmptyDonorTrashMutationVariables>;

/**
 * __useEmptyDonorTrashMutation__
 *
 * To run a mutation, you first call `useEmptyDonorTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyDonorTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyDonorTrashMutation, { data, loading, error }] = useEmptyDonorTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyDonorTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyDonorTrashMutation, EmptyDonorTrashMutationVariables>) {
        return Apollo.useMutation<EmptyDonorTrashMutation, EmptyDonorTrashMutationVariables>(EmptyDonorTrashDocument, baseOptions);
      }
export type EmptyDonorTrashMutationHookResult = ReturnType<typeof useEmptyDonorTrashMutation>;
export type EmptyDonorTrashMutationResult = Apollo.MutationResult<EmptyDonorTrashMutation>;
export type EmptyDonorTrashMutationOptions = Apollo.BaseMutationOptions<EmptyDonorTrashMutation, EmptyDonorTrashMutationVariables>;
export const EmailDetailsDocument = gql`
    query EmailDetails($id: ID!) {
  email(id: $id) {
    id
    ...EmailOverlay
  }
}
    ${EmailOverlayFragmentDoc}`;

/**
 * __useEmailDetailsQuery__
 *
 * To run a query within a React component, call `useEmailDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailDetailsQuery(baseOptions?: Apollo.QueryHookOptions<EmailDetailsQuery, EmailDetailsQueryVariables>) {
        return Apollo.useQuery<EmailDetailsQuery, EmailDetailsQueryVariables>(EmailDetailsDocument, baseOptions);
      }
export function useEmailDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailDetailsQuery, EmailDetailsQueryVariables>) {
          return Apollo.useLazyQuery<EmailDetailsQuery, EmailDetailsQueryVariables>(EmailDetailsDocument, baseOptions);
        }
export type EmailDetailsQueryHookResult = ReturnType<typeof useEmailDetailsQuery>;
export type EmailDetailsLazyQueryHookResult = ReturnType<typeof useEmailDetailsLazyQuery>;
export type EmailDetailsQueryResult = Apollo.QueryResult<EmailDetailsQuery, EmailDetailsQueryVariables>;
export const EmployeeListDocument = gql`
    query EmployeeList($status: UserStatus, $page: Int, $trashed: Trashed) {
  employees(status: $status, first: 20, page: $page, trashed: $trashed) {
    data {
      ...EmployeeListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${EmployeeListItemFragmentDoc}`;

/**
 * __useEmployeeListQuery__
 *
 * To run a query within a React component, call `useEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useEmployeeListQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
        return Apollo.useQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
      }
export function useEmployeeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeListQuery, EmployeeListQueryVariables>) {
          return Apollo.useLazyQuery<EmployeeListQuery, EmployeeListQueryVariables>(EmployeeListDocument, baseOptions);
        }
export type EmployeeListQueryHookResult = ReturnType<typeof useEmployeeListQuery>;
export type EmployeeListLazyQueryHookResult = ReturnType<typeof useEmployeeListLazyQuery>;
export type EmployeeListQueryResult = Apollo.QueryResult<EmployeeListQuery, EmployeeListQueryVariables>;
export const EmployeeDetailDocument = gql`
    query EmployeeDetail($id: ID!) {
  employee(id: $id) {
    id
    ...EmployeeInfo
  }
}
    ${EmployeeInfoFragmentDoc}`;

/**
 * __useEmployeeDetailQuery__
 *
 * To run a query within a React component, call `useEmployeeDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeDetailQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
        return Apollo.useQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, baseOptions);
      }
export function useEmployeeDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeDetailQuery, EmployeeDetailQueryVariables>) {
          return Apollo.useLazyQuery<EmployeeDetailQuery, EmployeeDetailQueryVariables>(EmployeeDetailDocument, baseOptions);
        }
export type EmployeeDetailQueryHookResult = ReturnType<typeof useEmployeeDetailQuery>;
export type EmployeeDetailLazyQueryHookResult = ReturnType<typeof useEmployeeDetailLazyQuery>;
export type EmployeeDetailQueryResult = Apollo.QueryResult<EmployeeDetailQuery, EmployeeDetailQueryVariables>;
export const EmployeeEmailsDocument = gql`
    query EmployeeEmails($id: ID!, $page: Int) {
  employee(id: $id) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useEmployeeEmailsQuery__
 *
 * To run a query within a React component, call `useEmployeeEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEmployeeEmailsQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeEmailsQuery, EmployeeEmailsQueryVariables>) {
        return Apollo.useQuery<EmployeeEmailsQuery, EmployeeEmailsQueryVariables>(EmployeeEmailsDocument, baseOptions);
      }
export function useEmployeeEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeEmailsQuery, EmployeeEmailsQueryVariables>) {
          return Apollo.useLazyQuery<EmployeeEmailsQuery, EmployeeEmailsQueryVariables>(EmployeeEmailsDocument, baseOptions);
        }
export type EmployeeEmailsQueryHookResult = ReturnType<typeof useEmployeeEmailsQuery>;
export type EmployeeEmailsLazyQueryHookResult = ReturnType<typeof useEmployeeEmailsLazyQuery>;
export type EmployeeEmailsQueryResult = Apollo.QueryResult<EmployeeEmailsQuery, EmployeeEmailsQueryVariables>;
export const EmployeeEditDocument = gql`
    query EmployeeEdit($id: ID!) {
  employee(id: $id) {
    id
    ...EmployeeEditForm
  }
}
    ${EmployeeEditFormFragmentDoc}`;

/**
 * __useEmployeeEditQuery__
 *
 * To run a query within a React component, call `useEmployeeEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmployeeEditQuery(baseOptions?: Apollo.QueryHookOptions<EmployeeEditQuery, EmployeeEditQueryVariables>) {
        return Apollo.useQuery<EmployeeEditQuery, EmployeeEditQueryVariables>(EmployeeEditDocument, baseOptions);
      }
export function useEmployeeEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeEditQuery, EmployeeEditQueryVariables>) {
          return Apollo.useLazyQuery<EmployeeEditQuery, EmployeeEditQueryVariables>(EmployeeEditDocument, baseOptions);
        }
export type EmployeeEditQueryHookResult = ReturnType<typeof useEmployeeEditQuery>;
export type EmployeeEditLazyQueryHookResult = ReturnType<typeof useEmployeeEditLazyQuery>;
export type EmployeeEditQueryResult = Apollo.QueryResult<EmployeeEditQuery, EmployeeEditQueryVariables>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($input: CreateEmployeeInput!) {
  createEmployee(input: $input) {
    ...EmployeeInfo
  }
}
    ${EmployeeInfoFragmentDoc}`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, baseOptions);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateEmployeeDocument = gql`
    mutation UpdateEmployee($input: UpdateEmployeeInput!) {
  updateEmployee(input: $input) {
    ...EmployeeInfo
  }
}
    ${EmployeeInfoFragmentDoc}`;
export type UpdateEmployeeMutationFn = Apollo.MutationFunction<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;

/**
 * __useUpdateEmployeeMutation__
 *
 * To run a mutation, you first call `useUpdateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmployeeMutation, { data, loading, error }] = useUpdateEmployeeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>) {
        return Apollo.useMutation<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>(UpdateEmployeeDocument, baseOptions);
      }
export type UpdateEmployeeMutationHookResult = ReturnType<typeof useUpdateEmployeeMutation>;
export type UpdateEmployeeMutationResult = Apollo.MutationResult<UpdateEmployeeMutation>;
export type UpdateEmployeeMutationOptions = Apollo.BaseMutationOptions<UpdateEmployeeMutation, UpdateEmployeeMutationVariables>;
export const DeleteEmployeeDocument = gql`
    mutation DeleteEmployee($id: ID!) {
  deleteEmployee(id: $id) {
    id
  }
}
    `;
export type DeleteEmployeeMutationFn = Apollo.MutationFunction<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;

/**
 * __useDeleteEmployeeMutation__
 *
 * To run a mutation, you first call `useDeleteEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEmployeeMutation, { data, loading, error }] = useDeleteEmployeeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>) {
        return Apollo.useMutation<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>(DeleteEmployeeDocument, baseOptions);
      }
export type DeleteEmployeeMutationHookResult = ReturnType<typeof useDeleteEmployeeMutation>;
export type DeleteEmployeeMutationResult = Apollo.MutationResult<DeleteEmployeeMutation>;
export type DeleteEmployeeMutationOptions = Apollo.BaseMutationOptions<DeleteEmployeeMutation, DeleteEmployeeMutationVariables>;
export const RestoreEmployeesDocument = gql`
    mutation RestoreEmployees($ids: [ID!]!) {
  restoreEmployees(ids: $ids) {
    id
  }
}
    `;
export type RestoreEmployeesMutationFn = Apollo.MutationFunction<RestoreEmployeesMutation, RestoreEmployeesMutationVariables>;

/**
 * __useRestoreEmployeesMutation__
 *
 * To run a mutation, you first call `useRestoreEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEmployeesMutation, { data, loading, error }] = useRestoreEmployeesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<RestoreEmployeesMutation, RestoreEmployeesMutationVariables>) {
        return Apollo.useMutation<RestoreEmployeesMutation, RestoreEmployeesMutationVariables>(RestoreEmployeesDocument, baseOptions);
      }
export type RestoreEmployeesMutationHookResult = ReturnType<typeof useRestoreEmployeesMutation>;
export type RestoreEmployeesMutationResult = Apollo.MutationResult<RestoreEmployeesMutation>;
export type RestoreEmployeesMutationOptions = Apollo.BaseMutationOptions<RestoreEmployeesMutation, RestoreEmployeesMutationVariables>;
export const ForceDeleteEmployeesDocument = gql`
    mutation ForceDeleteEmployees($ids: [ID!]!) {
  forceDeleteEmployees(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteEmployeesMutationFn = Apollo.MutationFunction<ForceDeleteEmployeesMutation, ForceDeleteEmployeesMutationVariables>;

/**
 * __useForceDeleteEmployeesMutation__
 *
 * To run a mutation, you first call `useForceDeleteEmployeesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteEmployeesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteEmployeesMutation, { data, loading, error }] = useForceDeleteEmployeesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteEmployeesMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteEmployeesMutation, ForceDeleteEmployeesMutationVariables>) {
        return Apollo.useMutation<ForceDeleteEmployeesMutation, ForceDeleteEmployeesMutationVariables>(ForceDeleteEmployeesDocument, baseOptions);
      }
export type ForceDeleteEmployeesMutationHookResult = ReturnType<typeof useForceDeleteEmployeesMutation>;
export type ForceDeleteEmployeesMutationResult = Apollo.MutationResult<ForceDeleteEmployeesMutation>;
export type ForceDeleteEmployeesMutationOptions = Apollo.BaseMutationOptions<ForceDeleteEmployeesMutation, ForceDeleteEmployeesMutationVariables>;
export const EmptyEmployeeTrashDocument = gql`
    mutation EmptyEmployeeTrash {
  emptyEmployeeTrash {
    id
  }
}
    `;
export type EmptyEmployeeTrashMutationFn = Apollo.MutationFunction<EmptyEmployeeTrashMutation, EmptyEmployeeTrashMutationVariables>;

/**
 * __useEmptyEmployeeTrashMutation__
 *
 * To run a mutation, you first call `useEmptyEmployeeTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyEmployeeTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyEmployeeTrashMutation, { data, loading, error }] = useEmptyEmployeeTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyEmployeeTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyEmployeeTrashMutation, EmptyEmployeeTrashMutationVariables>) {
        return Apollo.useMutation<EmptyEmployeeTrashMutation, EmptyEmployeeTrashMutationVariables>(EmptyEmployeeTrashDocument, baseOptions);
      }
export type EmptyEmployeeTrashMutationHookResult = ReturnType<typeof useEmptyEmployeeTrashMutation>;
export type EmptyEmployeeTrashMutationResult = Apollo.MutationResult<EmptyEmployeeTrashMutation>;
export type EmptyEmployeeTrashMutationOptions = Apollo.BaseMutationOptions<EmptyEmployeeTrashMutation, EmptyEmployeeTrashMutationVariables>;
export const EventListDocument = gql`
    query EventList($page: Int, $status: EventStatus, $start: DateTimeRange, $donor: Int, $archived: Archived, $orderByColumn: QueryEventsOrderByColumn = START, $orderByOrder: SortOrder = ASC, $trashed: Trashed) {
  events(first: 20, page: $page, status: $status, start: $start, donor: $donor, archived: $archived, orderBy: [{column: $orderByColumn, order: $orderByOrder}], trashed: $trashed) {
    data {
      ...EventListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${EventListItemFragmentDoc}`;

/**
 * __useEventListQuery__
 *
 * To run a query within a React component, call `useEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      status: // value for 'status'
 *      start: // value for 'start'
 *      donor: // value for 'donor'
 *      archived: // value for 'archived'
 *      orderByColumn: // value for 'orderByColumn'
 *      orderByOrder: // value for 'orderByOrder'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useEventListQuery(baseOptions?: Apollo.QueryHookOptions<EventListQuery, EventListQueryVariables>) {
        return Apollo.useQuery<EventListQuery, EventListQueryVariables>(EventListDocument, baseOptions);
      }
export function useEventListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventListQuery, EventListQueryVariables>) {
          return Apollo.useLazyQuery<EventListQuery, EventListQueryVariables>(EventListDocument, baseOptions);
        }
export type EventListQueryHookResult = ReturnType<typeof useEventListQuery>;
export type EventListLazyQueryHookResult = ReturnType<typeof useEventListLazyQuery>;
export type EventListQueryResult = Apollo.QueryResult<EventListQuery, EventListQueryVariables>;
export const EventDetailDocument = gql`
    query EventDetail($id: ID!) {
  event(id: $id) {
    id
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;

/**
 * __useEventDetailQuery__
 *
 * To run a query within a React component, call `useEventDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventDetailQuery(baseOptions?: Apollo.QueryHookOptions<EventDetailQuery, EventDetailQueryVariables>) {
        return Apollo.useQuery<EventDetailQuery, EventDetailQueryVariables>(EventDetailDocument, baseOptions);
      }
export function useEventDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventDetailQuery, EventDetailQueryVariables>) {
          return Apollo.useLazyQuery<EventDetailQuery, EventDetailQueryVariables>(EventDetailDocument, baseOptions);
        }
export type EventDetailQueryHookResult = ReturnType<typeof useEventDetailQuery>;
export type EventDetailLazyQueryHookResult = ReturnType<typeof useEventDetailLazyQuery>;
export type EventDetailQueryResult = Apollo.QueryResult<EventDetailQuery, EventDetailQueryVariables>;
export const EventEditDocument = gql`
    query EventEdit($id: ID!) {
  event(id: $id) {
    id
    ...EventEditForm
  }
}
    ${EventEditFormFragmentDoc}`;

/**
 * __useEventEditQuery__
 *
 * To run a query within a React component, call `useEventEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventEditQuery(baseOptions?: Apollo.QueryHookOptions<EventEditQuery, EventEditQueryVariables>) {
        return Apollo.useQuery<EventEditQuery, EventEditQueryVariables>(EventEditDocument, baseOptions);
      }
export function useEventEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventEditQuery, EventEditQueryVariables>) {
          return Apollo.useLazyQuery<EventEditQuery, EventEditQueryVariables>(EventEditDocument, baseOptions);
        }
export type EventEditQueryHookResult = ReturnType<typeof useEventEditQuery>;
export type EventEditLazyQueryHookResult = ReturnType<typeof useEventEditLazyQuery>;
export type EventEditQueryResult = Apollo.QueryResult<EventEditQuery, EventEditQueryVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($input: UpdateEventInput!) {
  updateEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, baseOptions);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CancelEventDocument = gql`
    mutation CancelEvent($input: CancelEventInput!) {
  cancelEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type CancelEventMutationFn = Apollo.MutationFunction<CancelEventMutation, CancelEventMutationVariables>;

/**
 * __useCancelEventMutation__
 *
 * To run a mutation, you first call `useCancelEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelEventMutation, { data, loading, error }] = useCancelEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelEventMutation(baseOptions?: Apollo.MutationHookOptions<CancelEventMutation, CancelEventMutationVariables>) {
        return Apollo.useMutation<CancelEventMutation, CancelEventMutationVariables>(CancelEventDocument, baseOptions);
      }
export type CancelEventMutationHookResult = ReturnType<typeof useCancelEventMutation>;
export type CancelEventMutationResult = Apollo.MutationResult<CancelEventMutation>;
export type CancelEventMutationOptions = Apollo.BaseMutationOptions<CancelEventMutation, CancelEventMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, baseOptions);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const PublishEventDocument = gql`
    mutation PublishEvent($id: ID!) {
  publishEvent(id: $id) {
    id
    status
  }
}
    `;
export type PublishEventMutationFn = Apollo.MutationFunction<PublishEventMutation, PublishEventMutationVariables>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishEventMutation(baseOptions?: Apollo.MutationHookOptions<PublishEventMutation, PublishEventMutationVariables>) {
        return Apollo.useMutation<PublishEventMutation, PublishEventMutationVariables>(PublishEventDocument, baseOptions);
      }
export type PublishEventMutationHookResult = ReturnType<typeof usePublishEventMutation>;
export type PublishEventMutationResult = Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<PublishEventMutation, PublishEventMutationVariables>;
export const DeleteSignupDocument = gql`
    mutation DeleteSignup($id: ID!) {
  deleteSignup(id: $id) {
    id
  }
}
    `;
export type DeleteSignupMutationFn = Apollo.MutationFunction<DeleteSignupMutation, DeleteSignupMutationVariables>;

/**
 * __useDeleteSignupMutation__
 *
 * To run a mutation, you first call `useDeleteSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSignupMutation, { data, loading, error }] = useDeleteSignupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSignupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSignupMutation, DeleteSignupMutationVariables>) {
        return Apollo.useMutation<DeleteSignupMutation, DeleteSignupMutationVariables>(DeleteSignupDocument, baseOptions);
      }
export type DeleteSignupMutationHookResult = ReturnType<typeof useDeleteSignupMutation>;
export type DeleteSignupMutationResult = Apollo.MutationResult<DeleteSignupMutation>;
export type DeleteSignupMutationOptions = Apollo.BaseMutationOptions<DeleteSignupMutation, DeleteSignupMutationVariables>;
export const AcceptSignupsDocument = gql`
    mutation AcceptSignups($id: ID!, $token: String!) {
  acceptSignups(id: $id, token: $token) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type AcceptSignupsMutationFn = Apollo.MutationFunction<AcceptSignupsMutation, AcceptSignupsMutationVariables>;

/**
 * __useAcceptSignupsMutation__
 *
 * To run a mutation, you first call `useAcceptSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSignupsMutation, { data, loading, error }] = useAcceptSignupsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useAcceptSignupsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptSignupsMutation, AcceptSignupsMutationVariables>) {
        return Apollo.useMutation<AcceptSignupsMutation, AcceptSignupsMutationVariables>(AcceptSignupsDocument, baseOptions);
      }
export type AcceptSignupsMutationHookResult = ReturnType<typeof useAcceptSignupsMutation>;
export type AcceptSignupsMutationResult = Apollo.MutationResult<AcceptSignupsMutation>;
export type AcceptSignupsMutationOptions = Apollo.BaseMutationOptions<AcceptSignupsMutation, AcceptSignupsMutationVariables>;
export const AddSignupsDocument = gql`
    mutation AddSignups($input: AddSignupsInput!) {
  addSignups(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type AddSignupsMutationFn = Apollo.MutationFunction<AddSignupsMutation, AddSignupsMutationVariables>;

/**
 * __useAddSignupsMutation__
 *
 * To run a mutation, you first call `useAddSignupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSignupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSignupsMutation, { data, loading, error }] = useAddSignupsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddSignupsMutation(baseOptions?: Apollo.MutationHookOptions<AddSignupsMutation, AddSignupsMutationVariables>) {
        return Apollo.useMutation<AddSignupsMutation, AddSignupsMutationVariables>(AddSignupsDocument, baseOptions);
      }
export type AddSignupsMutationHookResult = ReturnType<typeof useAddSignupsMutation>;
export type AddSignupsMutationResult = Apollo.MutationResult<AddSignupsMutation>;
export type AddSignupsMutationOptions = Apollo.BaseMutationOptions<AddSignupsMutation, AddSignupsMutationVariables>;
export const InviteCandidatesDocument = gql`
    mutation InviteCandidates($id: ID!) {
  inviteCandidates(id: $id) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type InviteCandidatesMutationFn = Apollo.MutationFunction<InviteCandidatesMutation, InviteCandidatesMutationVariables>;

/**
 * __useInviteCandidatesMutation__
 *
 * To run a mutation, you first call `useInviteCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCandidatesMutation, { data, loading, error }] = useInviteCandidatesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInviteCandidatesMutation(baseOptions?: Apollo.MutationHookOptions<InviteCandidatesMutation, InviteCandidatesMutationVariables>) {
        return Apollo.useMutation<InviteCandidatesMutation, InviteCandidatesMutationVariables>(InviteCandidatesDocument, baseOptions);
      }
export type InviteCandidatesMutationHookResult = ReturnType<typeof useInviteCandidatesMutation>;
export type InviteCandidatesMutationResult = Apollo.MutationResult<InviteCandidatesMutation>;
export type InviteCandidatesMutationOptions = Apollo.BaseMutationOptions<InviteCandidatesMutation, InviteCandidatesMutationVariables>;
export const DeleteEventDocument = gql`
    mutation DeleteEvent($id: ID!) {
  deleteEvent(id: $id) {
    id
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, baseOptions);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const RestoreEventsDocument = gql`
    mutation RestoreEvents($ids: [ID!]!) {
  restoreEvents(ids: $ids) {
    id
  }
}
    `;
export type RestoreEventsMutationFn = Apollo.MutationFunction<RestoreEventsMutation, RestoreEventsMutationVariables>;

/**
 * __useRestoreEventsMutation__
 *
 * To run a mutation, you first call `useRestoreEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreEventsMutation, { data, loading, error }] = useRestoreEventsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreEventsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreEventsMutation, RestoreEventsMutationVariables>) {
        return Apollo.useMutation<RestoreEventsMutation, RestoreEventsMutationVariables>(RestoreEventsDocument, baseOptions);
      }
export type RestoreEventsMutationHookResult = ReturnType<typeof useRestoreEventsMutation>;
export type RestoreEventsMutationResult = Apollo.MutationResult<RestoreEventsMutation>;
export type RestoreEventsMutationOptions = Apollo.BaseMutationOptions<RestoreEventsMutation, RestoreEventsMutationVariables>;
export const ForceDeleteEventsDocument = gql`
    mutation ForceDeleteEvents($ids: [ID!]!) {
  forceDeleteEvents(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteEventsMutationFn = Apollo.MutationFunction<ForceDeleteEventsMutation, ForceDeleteEventsMutationVariables>;

/**
 * __useForceDeleteEventsMutation__
 *
 * To run a mutation, you first call `useForceDeleteEventsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteEventsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteEventsMutation, { data, loading, error }] = useForceDeleteEventsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteEventsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteEventsMutation, ForceDeleteEventsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteEventsMutation, ForceDeleteEventsMutationVariables>(ForceDeleteEventsDocument, baseOptions);
      }
export type ForceDeleteEventsMutationHookResult = ReturnType<typeof useForceDeleteEventsMutation>;
export type ForceDeleteEventsMutationResult = Apollo.MutationResult<ForceDeleteEventsMutation>;
export type ForceDeleteEventsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteEventsMutation, ForceDeleteEventsMutationVariables>;
export const EmptyEventTrashDocument = gql`
    mutation EmptyEventTrash {
  emptyEventTrash {
    id
  }
}
    `;
export type EmptyEventTrashMutationFn = Apollo.MutationFunction<EmptyEventTrashMutation, EmptyEventTrashMutationVariables>;

/**
 * __useEmptyEventTrashMutation__
 *
 * To run a mutation, you first call `useEmptyEventTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyEventTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyEventTrashMutation, { data, loading, error }] = useEmptyEventTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyEventTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyEventTrashMutation, EmptyEventTrashMutationVariables>) {
        return Apollo.useMutation<EmptyEventTrashMutation, EmptyEventTrashMutationVariables>(EmptyEventTrashDocument, baseOptions);
      }
export type EmptyEventTrashMutationHookResult = ReturnType<typeof useEmptyEventTrashMutation>;
export type EmptyEventTrashMutationResult = Apollo.MutationResult<EmptyEventTrashMutation>;
export type EmptyEventTrashMutationOptions = Apollo.BaseMutationOptions<EmptyEventTrashMutation, EmptyEventTrashMutationVariables>;
export const LocationListDocument = gql`
    query LocationList($page: Int, $trashed: Trashed) {
  locations(first: 20, page: $page, trashed: $trashed) {
    data {
      ...LocationListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${LocationListItemFragmentDoc}`;

/**
 * __useLocationListQuery__
 *
 * To run a query within a React component, call `useLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useLocationListQuery(baseOptions?: Apollo.QueryHookOptions<LocationListQuery, LocationListQueryVariables>) {
        return Apollo.useQuery<LocationListQuery, LocationListQueryVariables>(LocationListDocument, baseOptions);
      }
export function useLocationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationListQuery, LocationListQueryVariables>) {
          return Apollo.useLazyQuery<LocationListQuery, LocationListQueryVariables>(LocationListDocument, baseOptions);
        }
export type LocationListQueryHookResult = ReturnType<typeof useLocationListQuery>;
export type LocationListLazyQueryHookResult = ReturnType<typeof useLocationListLazyQuery>;
export type LocationListQueryResult = Apollo.QueryResult<LocationListQuery, LocationListQueryVariables>;
export const LocationDetailDocument = gql`
    query LocationDetail($id: ID!) {
  location(id: $id) {
    id
    ...LocationInfo
  }
}
    ${LocationInfoFragmentDoc}`;

/**
 * __useLocationDetailQuery__
 *
 * To run a query within a React component, call `useLocationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationDetailQuery(baseOptions?: Apollo.QueryHookOptions<LocationDetailQuery, LocationDetailQueryVariables>) {
        return Apollo.useQuery<LocationDetailQuery, LocationDetailQueryVariables>(LocationDetailDocument, baseOptions);
      }
export function useLocationDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationDetailQuery, LocationDetailQueryVariables>) {
          return Apollo.useLazyQuery<LocationDetailQuery, LocationDetailQueryVariables>(LocationDetailDocument, baseOptions);
        }
export type LocationDetailQueryHookResult = ReturnType<typeof useLocationDetailQuery>;
export type LocationDetailLazyQueryHookResult = ReturnType<typeof useLocationDetailLazyQuery>;
export type LocationDetailQueryResult = Apollo.QueryResult<LocationDetailQuery, LocationDetailQueryVariables>;
export const LocationDonorsDocument = gql`
    query LocationDonors($id: ID!) {
  location(id: $id) {
    id
    donors {
      ...DonorListItem
    }
  }
}
    ${DonorListItemFragmentDoc}`;

/**
 * __useLocationDonorsQuery__
 *
 * To run a query within a React component, call `useLocationDonorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationDonorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationDonorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationDonorsQuery(baseOptions?: Apollo.QueryHookOptions<LocationDonorsQuery, LocationDonorsQueryVariables>) {
        return Apollo.useQuery<LocationDonorsQuery, LocationDonorsQueryVariables>(LocationDonorsDocument, baseOptions);
      }
export function useLocationDonorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationDonorsQuery, LocationDonorsQueryVariables>) {
          return Apollo.useLazyQuery<LocationDonorsQuery, LocationDonorsQueryVariables>(LocationDonorsDocument, baseOptions);
        }
export type LocationDonorsQueryHookResult = ReturnType<typeof useLocationDonorsQuery>;
export type LocationDonorsLazyQueryHookResult = ReturnType<typeof useLocationDonorsLazyQuery>;
export type LocationDonorsQueryResult = Apollo.QueryResult<LocationDonorsQuery, LocationDonorsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    ...LocationInfo
  }
}
    ${LocationInfoFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, baseOptions);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const LocationEditDocument = gql`
    query LocationEdit($id: ID!) {
  location(id: $id) {
    id
    ...LocationEditForm
  }
}
    ${LocationEditFormFragmentDoc}`;

/**
 * __useLocationEditQuery__
 *
 * To run a query within a React component, call `useLocationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationEditQuery(baseOptions?: Apollo.QueryHookOptions<LocationEditQuery, LocationEditQueryVariables>) {
        return Apollo.useQuery<LocationEditQuery, LocationEditQueryVariables>(LocationEditDocument, baseOptions);
      }
export function useLocationEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationEditQuery, LocationEditQueryVariables>) {
          return Apollo.useLazyQuery<LocationEditQuery, LocationEditQueryVariables>(LocationEditDocument, baseOptions);
        }
export type LocationEditQueryHookResult = ReturnType<typeof useLocationEditQuery>;
export type LocationEditLazyQueryHookResult = ReturnType<typeof useLocationEditLazyQuery>;
export type LocationEditQueryResult = Apollo.QueryResult<LocationEditQuery, LocationEditQueryVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($input: UpdateLocationInput!) {
  updateLocation(input: $input) {
    ...LocationInfo
  }
}
    ${LocationInfoFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, baseOptions);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    id
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, baseOptions);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const RestoreLocationsDocument = gql`
    mutation RestoreLocations($ids: [ID!]!) {
  restoreLocations(ids: $ids) {
    id
  }
}
    `;
export type RestoreLocationsMutationFn = Apollo.MutationFunction<RestoreLocationsMutation, RestoreLocationsMutationVariables>;

/**
 * __useRestoreLocationsMutation__
 *
 * To run a mutation, you first call `useRestoreLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreLocationsMutation, { data, loading, error }] = useRestoreLocationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreLocationsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreLocationsMutation, RestoreLocationsMutationVariables>) {
        return Apollo.useMutation<RestoreLocationsMutation, RestoreLocationsMutationVariables>(RestoreLocationsDocument, baseOptions);
      }
export type RestoreLocationsMutationHookResult = ReturnType<typeof useRestoreLocationsMutation>;
export type RestoreLocationsMutationResult = Apollo.MutationResult<RestoreLocationsMutation>;
export type RestoreLocationsMutationOptions = Apollo.BaseMutationOptions<RestoreLocationsMutation, RestoreLocationsMutationVariables>;
export const ForceDeleteLocationsDocument = gql`
    mutation ForceDeleteLocations($ids: [ID!]!) {
  forceDeleteLocations(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteLocationsMutationFn = Apollo.MutationFunction<ForceDeleteLocationsMutation, ForceDeleteLocationsMutationVariables>;

/**
 * __useForceDeleteLocationsMutation__
 *
 * To run a mutation, you first call `useForceDeleteLocationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteLocationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteLocationsMutation, { data, loading, error }] = useForceDeleteLocationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteLocationsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteLocationsMutation, ForceDeleteLocationsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteLocationsMutation, ForceDeleteLocationsMutationVariables>(ForceDeleteLocationsDocument, baseOptions);
      }
export type ForceDeleteLocationsMutationHookResult = ReturnType<typeof useForceDeleteLocationsMutation>;
export type ForceDeleteLocationsMutationResult = Apollo.MutationResult<ForceDeleteLocationsMutation>;
export type ForceDeleteLocationsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteLocationsMutation, ForceDeleteLocationsMutationVariables>;
export const EmptyLocationTrashDocument = gql`
    mutation EmptyLocationTrash {
  emptyLocationTrash {
    id
  }
}
    `;
export type EmptyLocationTrashMutationFn = Apollo.MutationFunction<EmptyLocationTrashMutation, EmptyLocationTrashMutationVariables>;

/**
 * __useEmptyLocationTrashMutation__
 *
 * To run a mutation, you first call `useEmptyLocationTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyLocationTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyLocationTrashMutation, { data, loading, error }] = useEmptyLocationTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyLocationTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyLocationTrashMutation, EmptyLocationTrashMutationVariables>) {
        return Apollo.useMutation<EmptyLocationTrashMutation, EmptyLocationTrashMutationVariables>(EmptyLocationTrashDocument, baseOptions);
      }
export type EmptyLocationTrashMutationHookResult = ReturnType<typeof useEmptyLocationTrashMutation>;
export type EmptyLocationTrashMutationResult = Apollo.MutationResult<EmptyLocationTrashMutation>;
export type EmptyLocationTrashMutationOptions = Apollo.BaseMutationOptions<EmptyLocationTrashMutation, EmptyLocationTrashMutationVariables>;
export const EventMatchingDocument = gql`
    query EventMatching($id: ID!) {
  event(id: $id) {
    id
    ...MatchingPage
  }
}
    ${MatchingPageFragmentDoc}`;

/**
 * __useEventMatchingQuery__
 *
 * To run a query within a React component, call `useEventMatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventMatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventMatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEventMatchingQuery(baseOptions?: Apollo.QueryHookOptions<EventMatchingQuery, EventMatchingQueryVariables>) {
        return Apollo.useQuery<EventMatchingQuery, EventMatchingQueryVariables>(EventMatchingDocument, baseOptions);
      }
export function useEventMatchingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventMatchingQuery, EventMatchingQueryVariables>) {
          return Apollo.useLazyQuery<EventMatchingQuery, EventMatchingQueryVariables>(EventMatchingDocument, baseOptions);
        }
export type EventMatchingQueryHookResult = ReturnType<typeof useEventMatchingQuery>;
export type EventMatchingLazyQueryHookResult = ReturnType<typeof useEventMatchingLazyQuery>;
export type EventMatchingQueryResult = Apollo.QueryResult<EventMatchingQuery, EventMatchingQueryVariables>;
export const UpdateEventCandidatesDocument = gql`
    mutation UpdateEventCandidates($input: UpdateCandidatesInput!) {
  updateCandidates(input: $input) {
    ...EventDetailPage
  }
}
    ${EventDetailPageFragmentDoc}`;
export type UpdateEventCandidatesMutationFn = Apollo.MutationFunction<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>;

/**
 * __useUpdateEventCandidatesMutation__
 *
 * To run a mutation, you first call `useUpdateEventCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventCandidatesMutation, { data, loading, error }] = useUpdateEventCandidatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEventCandidatesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>) {
        return Apollo.useMutation<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>(UpdateEventCandidatesDocument, baseOptions);
      }
export type UpdateEventCandidatesMutationHookResult = ReturnType<typeof useUpdateEventCandidatesMutation>;
export type UpdateEventCandidatesMutationResult = Apollo.MutationResult<UpdateEventCandidatesMutation>;
export type UpdateEventCandidatesMutationOptions = Apollo.BaseMutationOptions<UpdateEventCandidatesMutation, UpdateEventCandidatesMutationVariables>;
export const CandidateSignupDocument = gql`
    query CandidateSignup($id: ID!) {
  signup(id: $id) {
    id
    ...CandidateListItem
  }
}
    ${CandidateListItemFragmentDoc}`;

/**
 * __useCandidateSignupQuery__
 *
 * To run a query within a React component, call `useCandidateSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useCandidateSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCandidateSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCandidateSignupQuery(baseOptions?: Apollo.QueryHookOptions<CandidateSignupQuery, CandidateSignupQueryVariables>) {
        return Apollo.useQuery<CandidateSignupQuery, CandidateSignupQueryVariables>(CandidateSignupDocument, baseOptions);
      }
export function useCandidateSignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CandidateSignupQuery, CandidateSignupQueryVariables>) {
          return Apollo.useLazyQuery<CandidateSignupQuery, CandidateSignupQueryVariables>(CandidateSignupDocument, baseOptions);
        }
export type CandidateSignupQueryHookResult = ReturnType<typeof useCandidateSignupQuery>;
export type CandidateSignupLazyQueryHookResult = ReturnType<typeof useCandidateSignupLazyQuery>;
export type CandidateSignupQueryResult = Apollo.QueryResult<CandidateSignupQuery, CandidateSignupQueryVariables>;
export const ProspectSignupDocument = gql`
    query ProspectSignup($id: ID!) {
  signup(id: $id) {
    id
    ...ProspectListItem
  }
}
    ${ProspectListItemFragmentDoc}`;

/**
 * __useProspectSignupQuery__
 *
 * To run a query within a React component, call `useProspectSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectSignupQuery(baseOptions?: Apollo.QueryHookOptions<ProspectSignupQuery, ProspectSignupQueryVariables>) {
        return Apollo.useQuery<ProspectSignupQuery, ProspectSignupQueryVariables>(ProspectSignupDocument, baseOptions);
      }
export function useProspectSignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProspectSignupQuery, ProspectSignupQueryVariables>) {
          return Apollo.useLazyQuery<ProspectSignupQuery, ProspectSignupQueryVariables>(ProspectSignupDocument, baseOptions);
        }
export type ProspectSignupQueryHookResult = ReturnType<typeof useProspectSignupQuery>;
export type ProspectSignupLazyQueryHookResult = ReturnType<typeof useProspectSignupLazyQuery>;
export type ProspectSignupQueryResult = Apollo.QueryResult<ProspectSignupQuery, ProspectSignupQueryVariables>;
export const ProspectGroupSignupDocument = gql`
    query ProspectGroupSignup($id: ID!) {
  signup(id: $id) {
    id
    ...ProspectGroupListItem
  }
}
    ${ProspectGroupListItemFragmentDoc}`;

/**
 * __useProspectGroupSignupQuery__
 *
 * To run a query within a React component, call `useProspectGroupSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useProspectGroupSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProspectGroupSignupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProspectGroupSignupQuery(baseOptions?: Apollo.QueryHookOptions<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>) {
        return Apollo.useQuery<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>(ProspectGroupSignupDocument, baseOptions);
      }
export function useProspectGroupSignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>) {
          return Apollo.useLazyQuery<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>(ProspectGroupSignupDocument, baseOptions);
        }
export type ProspectGroupSignupQueryHookResult = ReturnType<typeof useProspectGroupSignupQuery>;
export type ProspectGroupSignupLazyQueryHookResult = ReturnType<typeof useProspectGroupSignupLazyQuery>;
export type ProspectGroupSignupQueryResult = Apollo.QueryResult<ProspectGroupSignupQuery, ProspectGroupSignupQueryVariables>;
export const CompanionMatchingDocument = gql`
    query CompanionMatching($id: ID!) {
  event(id: $id) {
    id
    ...CompanionMatchingTab
  }
}
    ${CompanionMatchingTabFragmentDoc}`;

/**
 * __useCompanionMatchingQuery__
 *
 * To run a query within a React component, call `useCompanionMatchingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionMatchingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionMatchingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanionMatchingQuery(baseOptions?: Apollo.QueryHookOptions<CompanionMatchingQuery, CompanionMatchingQueryVariables>) {
        return Apollo.useQuery<CompanionMatchingQuery, CompanionMatchingQueryVariables>(CompanionMatchingDocument, baseOptions);
      }
export function useCompanionMatchingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionMatchingQuery, CompanionMatchingQueryVariables>) {
          return Apollo.useLazyQuery<CompanionMatchingQuery, CompanionMatchingQueryVariables>(CompanionMatchingDocument, baseOptions);
        }
export type CompanionMatchingQueryHookResult = ReturnType<typeof useCompanionMatchingQuery>;
export type CompanionMatchingLazyQueryHookResult = ReturnType<typeof useCompanionMatchingLazyQuery>;
export type CompanionMatchingQueryResult = Apollo.QueryResult<CompanionMatchingQuery, CompanionMatchingQueryVariables>;
export const SoulAttendsDocument = gql`
    query SoulAttends($id: ID!, $first: Int = 20, $page: Int) {
  soul(id: $id) {
    id
    attends(first: $first, page: $page) {
      data {
        ...AttendListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${AttendListItemFragmentDoc}`;

/**
 * __useSoulAttendsQuery__
 *
 * To run a query within a React component, call `useSoulAttendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulAttendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulAttendsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulAttendsQuery(baseOptions?: Apollo.QueryHookOptions<SoulAttendsQuery, SoulAttendsQueryVariables>) {
        return Apollo.useQuery<SoulAttendsQuery, SoulAttendsQueryVariables>(SoulAttendsDocument, baseOptions);
      }
export function useSoulAttendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoulAttendsQuery, SoulAttendsQueryVariables>) {
          return Apollo.useLazyQuery<SoulAttendsQuery, SoulAttendsQueryVariables>(SoulAttendsDocument, baseOptions);
        }
export type SoulAttendsQueryHookResult = ReturnType<typeof useSoulAttendsQuery>;
export type SoulAttendsLazyQueryHookResult = ReturnType<typeof useSoulAttendsLazyQuery>;
export type SoulAttendsQueryResult = Apollo.QueryResult<SoulAttendsQuery, SoulAttendsQueryVariables>;
export const CompanionAttendsDocument = gql`
    query CompanionAttends($id: ID!, $first: Int = 20, $page: Int) {
  companion(id: $id) {
    id
    attends(first: $first, page: $page) {
      data {
        ...AttendListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${AttendListItemFragmentDoc}`;

/**
 * __useCompanionAttendsQuery__
 *
 * To run a query within a React component, call `useCompanionAttendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionAttendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionAttendsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionAttendsQuery(baseOptions?: Apollo.QueryHookOptions<CompanionAttendsQuery, CompanionAttendsQueryVariables>) {
        return Apollo.useQuery<CompanionAttendsQuery, CompanionAttendsQueryVariables>(CompanionAttendsDocument, baseOptions);
      }
export function useCompanionAttendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionAttendsQuery, CompanionAttendsQueryVariables>) {
          return Apollo.useLazyQuery<CompanionAttendsQuery, CompanionAttendsQueryVariables>(CompanionAttendsDocument, baseOptions);
        }
export type CompanionAttendsQueryHookResult = ReturnType<typeof useCompanionAttendsQuery>;
export type CompanionAttendsLazyQueryHookResult = ReturnType<typeof useCompanionAttendsLazyQuery>;
export type CompanionAttendsQueryResult = Apollo.QueryResult<CompanionAttendsQuery, CompanionAttendsQueryVariables>;
export const SoulFutureSignupsDocument = gql`
    query SoulFutureSignups($id: ID!, $first: Int = 20, $page: Int) {
  soul(id: $id) {
    id
    future_signups(first: $first, page: $page) {
      data {
        ...FutureSignupListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${FutureSignupListItemFragmentDoc}`;

/**
 * __useSoulFutureSignupsQuery__
 *
 * To run a query within a React component, call `useSoulFutureSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulFutureSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulFutureSignupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulFutureSignupsQuery(baseOptions?: Apollo.QueryHookOptions<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>) {
        return Apollo.useQuery<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>(SoulFutureSignupsDocument, baseOptions);
      }
export function useSoulFutureSignupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>) {
          return Apollo.useLazyQuery<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>(SoulFutureSignupsDocument, baseOptions);
        }
export type SoulFutureSignupsQueryHookResult = ReturnType<typeof useSoulFutureSignupsQuery>;
export type SoulFutureSignupsLazyQueryHookResult = ReturnType<typeof useSoulFutureSignupsLazyQuery>;
export type SoulFutureSignupsQueryResult = Apollo.QueryResult<SoulFutureSignupsQuery, SoulFutureSignupsQueryVariables>;
export const CompanionFutureSignupsDocument = gql`
    query CompanionFutureSignups($id: ID!, $first: Int = 20, $page: Int) {
  companion(id: $id) {
    id
    future_signups(first: $first, page: $page) {
      data {
        ...FutureSignupListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
}
    ${FutureSignupListItemFragmentDoc}`;

/**
 * __useCompanionFutureSignupsQuery__
 *
 * To run a query within a React component, call `useCompanionFutureSignupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanionFutureSignupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanionFutureSignupsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useCompanionFutureSignupsQuery(baseOptions?: Apollo.QueryHookOptions<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>) {
        return Apollo.useQuery<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>(CompanionFutureSignupsDocument, baseOptions);
      }
export function useCompanionFutureSignupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>) {
          return Apollo.useLazyQuery<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>(CompanionFutureSignupsDocument, baseOptions);
        }
export type CompanionFutureSignupsQueryHookResult = ReturnType<typeof useCompanionFutureSignupsQuery>;
export type CompanionFutureSignupsLazyQueryHookResult = ReturnType<typeof useCompanionFutureSignupsLazyQuery>;
export type CompanionFutureSignupsQueryResult = Apollo.QueryResult<CompanionFutureSignupsQuery, CompanionFutureSignupsQueryVariables>;
export const NewsletterListDocument = gql`
    query NewsletterList($page: Int, $sentAt: DateTimeRange, $trashed: Trashed) {
  newsletters(first: 20, page: $page, sent_at: $sentAt, orderBy: [{column: "created_at", order: ASC}], trashed: $trashed) {
    data {
      ...NewsletterListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${NewsletterListItemFragmentDoc}`;

/**
 * __useNewsletterListQuery__
 *
 * To run a query within a React component, call `useNewsletterListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      sentAt: // value for 'sentAt'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useNewsletterListQuery(baseOptions?: Apollo.QueryHookOptions<NewsletterListQuery, NewsletterListQueryVariables>) {
        return Apollo.useQuery<NewsletterListQuery, NewsletterListQueryVariables>(NewsletterListDocument, baseOptions);
      }
export function useNewsletterListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsletterListQuery, NewsletterListQueryVariables>) {
          return Apollo.useLazyQuery<NewsletterListQuery, NewsletterListQueryVariables>(NewsletterListDocument, baseOptions);
        }
export type NewsletterListQueryHookResult = ReturnType<typeof useNewsletterListQuery>;
export type NewsletterListLazyQueryHookResult = ReturnType<typeof useNewsletterListLazyQuery>;
export type NewsletterListQueryResult = Apollo.QueryResult<NewsletterListQuery, NewsletterListQueryVariables>;
export const NewsletterDetailDocument = gql`
    query NewsletterDetail($id: ID!) {
  newsletter(id: $id) {
    id
    ...NewsletterDetailPage
  }
}
    ${NewsletterDetailPageFragmentDoc}`;

/**
 * __useNewsletterDetailQuery__
 *
 * To run a query within a React component, call `useNewsletterDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewsletterDetailQuery(baseOptions?: Apollo.QueryHookOptions<NewsletterDetailQuery, NewsletterDetailQueryVariables>) {
        return Apollo.useQuery<NewsletterDetailQuery, NewsletterDetailQueryVariables>(NewsletterDetailDocument, baseOptions);
      }
export function useNewsletterDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NewsletterDetailQuery, NewsletterDetailQueryVariables>) {
          return Apollo.useLazyQuery<NewsletterDetailQuery, NewsletterDetailQueryVariables>(NewsletterDetailDocument, baseOptions);
        }
export type NewsletterDetailQueryHookResult = ReturnType<typeof useNewsletterDetailQuery>;
export type NewsletterDetailLazyQueryHookResult = ReturnType<typeof useNewsletterDetailLazyQuery>;
export type NewsletterDetailQueryResult = Apollo.QueryResult<NewsletterDetailQuery, NewsletterDetailQueryVariables>;
export const CreateNewsletterDocument = gql`
    mutation CreateNewsletter($input: CreateNewsletterInput!) {
  createNewsletter(input: $input) {
    ...NewsletterDetailPage
  }
}
    ${NewsletterDetailPageFragmentDoc}`;
export type CreateNewsletterMutationFn = Apollo.MutationFunction<CreateNewsletterMutation, CreateNewsletterMutationVariables>;

/**
 * __useCreateNewsletterMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterMutation, { data, loading, error }] = useCreateNewsletterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNewsletterMutation(baseOptions?: Apollo.MutationHookOptions<CreateNewsletterMutation, CreateNewsletterMutationVariables>) {
        return Apollo.useMutation<CreateNewsletterMutation, CreateNewsletterMutationVariables>(CreateNewsletterDocument, baseOptions);
      }
export type CreateNewsletterMutationHookResult = ReturnType<typeof useCreateNewsletterMutation>;
export type CreateNewsletterMutationResult = Apollo.MutationResult<CreateNewsletterMutation>;
export type CreateNewsletterMutationOptions = Apollo.BaseMutationOptions<CreateNewsletterMutation, CreateNewsletterMutationVariables>;
export const OrganisationListDocument = gql`
    query OrganisationList($trashed: Trashed) {
  organisations(trashed: $trashed) {
    ...OrganisationListItem
  }
}
    ${OrganisationListItemFragmentDoc}`;

/**
 * __useOrganisationListQuery__
 *
 * To run a query within a React component, call `useOrganisationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationListQuery({
 *   variables: {
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useOrganisationListQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
        return Apollo.useQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, baseOptions);
      }
export function useOrganisationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationListQuery, OrganisationListQueryVariables>) {
          return Apollo.useLazyQuery<OrganisationListQuery, OrganisationListQueryVariables>(OrganisationListDocument, baseOptions);
        }
export type OrganisationListQueryHookResult = ReturnType<typeof useOrganisationListQuery>;
export type OrganisationListLazyQueryHookResult = ReturnType<typeof useOrganisationListLazyQuery>;
export type OrganisationListQueryResult = Apollo.QueryResult<OrganisationListQuery, OrganisationListQueryVariables>;
export const OrganisationDetailDocument = gql`
    query OrganisationDetail($id: ID!) {
  organisation(id: $id) {
    id
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;

/**
 * __useOrganisationDetailQuery__
 *
 * To run a query within a React component, call `useOrganisationDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationDetailQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationDetailQuery, OrganisationDetailQueryVariables>) {
        return Apollo.useQuery<OrganisationDetailQuery, OrganisationDetailQueryVariables>(OrganisationDetailDocument, baseOptions);
      }
export function useOrganisationDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationDetailQuery, OrganisationDetailQueryVariables>) {
          return Apollo.useLazyQuery<OrganisationDetailQuery, OrganisationDetailQueryVariables>(OrganisationDetailDocument, baseOptions);
        }
export type OrganisationDetailQueryHookResult = ReturnType<typeof useOrganisationDetailQuery>;
export type OrganisationDetailLazyQueryHookResult = ReturnType<typeof useOrganisationDetailLazyQuery>;
export type OrganisationDetailQueryResult = Apollo.QueryResult<OrganisationDetailQuery, OrganisationDetailQueryVariables>;
export const OrganisationEditDocument = gql`
    query OrganisationEdit($id: ID!) {
  organisation(id: $id) {
    id
    ...OrganisationEditForm
  }
}
    ${OrganisationEditFormFragmentDoc}`;

/**
 * __useOrganisationEditQuery__
 *
 * To run a query within a React component, call `useOrganisationEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrganisationEditQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationEditQuery, OrganisationEditQueryVariables>) {
        return Apollo.useQuery<OrganisationEditQuery, OrganisationEditQueryVariables>(OrganisationEditDocument, baseOptions);
      }
export function useOrganisationEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationEditQuery, OrganisationEditQueryVariables>) {
          return Apollo.useLazyQuery<OrganisationEditQuery, OrganisationEditQueryVariables>(OrganisationEditDocument, baseOptions);
        }
export type OrganisationEditQueryHookResult = ReturnType<typeof useOrganisationEditQuery>;
export type OrganisationEditLazyQueryHookResult = ReturnType<typeof useOrganisationEditLazyQuery>;
export type OrganisationEditQueryResult = Apollo.QueryResult<OrganisationEditQuery, OrganisationEditQueryVariables>;
export const CreateOrganisationDocument = gql`
    mutation CreateOrganisation($input: CreateOrganisationInput!) {
  createOrganisation(input: $input) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;
export type CreateOrganisationMutationFn = Apollo.MutationFunction<CreateOrganisationMutation, CreateOrganisationMutationVariables>;

/**
 * __useCreateOrganisationMutation__
 *
 * To run a mutation, you first call `useCreateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganisationMutation, { data, loading, error }] = useCreateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>) {
        return Apollo.useMutation<CreateOrganisationMutation, CreateOrganisationMutationVariables>(CreateOrganisationDocument, baseOptions);
      }
export type CreateOrganisationMutationHookResult = ReturnType<typeof useCreateOrganisationMutation>;
export type CreateOrganisationMutationResult = Apollo.MutationResult<CreateOrganisationMutation>;
export type CreateOrganisationMutationOptions = Apollo.BaseMutationOptions<CreateOrganisationMutation, CreateOrganisationMutationVariables>;
export const UpdateOrganisationDocument = gql`
    mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
  updateOrganisation(input: $input) {
    ...OrganisationDetailPage
  }
}
    ${OrganisationDetailPageFragmentDoc}`;
export type UpdateOrganisationMutationFn = Apollo.MutationFunction<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;

/**
 * __useUpdateOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganisationMutation, { data, loading, error }] = useUpdateOrganisationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>) {
        return Apollo.useMutation<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>(UpdateOrganisationDocument, baseOptions);
      }
export type UpdateOrganisationMutationHookResult = ReturnType<typeof useUpdateOrganisationMutation>;
export type UpdateOrganisationMutationResult = Apollo.MutationResult<UpdateOrganisationMutation>;
export type UpdateOrganisationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganisationMutation, UpdateOrganisationMutationVariables>;
export const DeleteOrganisationDocument = gql`
    mutation DeleteOrganisation($id: ID!) {
  deleteOrganisation(id: $id) {
    id
  }
}
    `;
export type DeleteOrganisationMutationFn = Apollo.MutationFunction<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;

/**
 * __useDeleteOrganisationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganisationMutation, { data, loading, error }] = useDeleteOrganisationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrganisationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>) {
        return Apollo.useMutation<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>(DeleteOrganisationDocument, baseOptions);
      }
export type DeleteOrganisationMutationHookResult = ReturnType<typeof useDeleteOrganisationMutation>;
export type DeleteOrganisationMutationResult = Apollo.MutationResult<DeleteOrganisationMutation>;
export type DeleteOrganisationMutationOptions = Apollo.BaseMutationOptions<DeleteOrganisationMutation, DeleteOrganisationMutationVariables>;
export const RestoreOrganisationsDocument = gql`
    mutation RestoreOrganisations($ids: [ID!]!) {
  restoreOrganisations(ids: $ids) {
    id
  }
}
    `;
export type RestoreOrganisationsMutationFn = Apollo.MutationFunction<RestoreOrganisationsMutation, RestoreOrganisationsMutationVariables>;

/**
 * __useRestoreOrganisationsMutation__
 *
 * To run a mutation, you first call `useRestoreOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreOrganisationsMutation, { data, loading, error }] = useRestoreOrganisationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreOrganisationsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreOrganisationsMutation, RestoreOrganisationsMutationVariables>) {
        return Apollo.useMutation<RestoreOrganisationsMutation, RestoreOrganisationsMutationVariables>(RestoreOrganisationsDocument, baseOptions);
      }
export type RestoreOrganisationsMutationHookResult = ReturnType<typeof useRestoreOrganisationsMutation>;
export type RestoreOrganisationsMutationResult = Apollo.MutationResult<RestoreOrganisationsMutation>;
export type RestoreOrganisationsMutationOptions = Apollo.BaseMutationOptions<RestoreOrganisationsMutation, RestoreOrganisationsMutationVariables>;
export const ForceDeleteOrganisationsDocument = gql`
    mutation ForceDeleteOrganisations($ids: [ID!]!) {
  forceDeleteOrganisations(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteOrganisationsMutationFn = Apollo.MutationFunction<ForceDeleteOrganisationsMutation, ForceDeleteOrganisationsMutationVariables>;

/**
 * __useForceDeleteOrganisationsMutation__
 *
 * To run a mutation, you first call `useForceDeleteOrganisationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteOrganisationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteOrganisationsMutation, { data, loading, error }] = useForceDeleteOrganisationsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteOrganisationsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteOrganisationsMutation, ForceDeleteOrganisationsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteOrganisationsMutation, ForceDeleteOrganisationsMutationVariables>(ForceDeleteOrganisationsDocument, baseOptions);
      }
export type ForceDeleteOrganisationsMutationHookResult = ReturnType<typeof useForceDeleteOrganisationsMutation>;
export type ForceDeleteOrganisationsMutationResult = Apollo.MutationResult<ForceDeleteOrganisationsMutation>;
export type ForceDeleteOrganisationsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteOrganisationsMutation, ForceDeleteOrganisationsMutationVariables>;
export const EmptyOrganisationTrashDocument = gql`
    mutation EmptyOrganisationTrash {
  emptyOrganisationTrash {
    id
  }
}
    `;
export type EmptyOrganisationTrashMutationFn = Apollo.MutationFunction<EmptyOrganisationTrashMutation, EmptyOrganisationTrashMutationVariables>;

/**
 * __useEmptyOrganisationTrashMutation__
 *
 * To run a mutation, you first call `useEmptyOrganisationTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptyOrganisationTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptyOrganisationTrashMutation, { data, loading, error }] = useEmptyOrganisationTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptyOrganisationTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptyOrganisationTrashMutation, EmptyOrganisationTrashMutationVariables>) {
        return Apollo.useMutation<EmptyOrganisationTrashMutation, EmptyOrganisationTrashMutationVariables>(EmptyOrganisationTrashDocument, baseOptions);
      }
export type EmptyOrganisationTrashMutationHookResult = ReturnType<typeof useEmptyOrganisationTrashMutation>;
export type EmptyOrganisationTrashMutationResult = Apollo.MutationResult<EmptyOrganisationTrashMutation>;
export type EmptyOrganisationTrashMutationOptions = Apollo.BaseMutationOptions<EmptyOrganisationTrashMutation, EmptyOrganisationTrashMutationVariables>;
export const RoleListDocument = gql`
    query RoleList {
  roles {
    ...RoleListItem
  }
}
    ${RoleListItemFragmentDoc}`;

/**
 * __useRoleListQuery__
 *
 * To run a query within a React component, call `useRoleListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleListQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoleListQuery(baseOptions?: Apollo.QueryHookOptions<RoleListQuery, RoleListQueryVariables>) {
        return Apollo.useQuery<RoleListQuery, RoleListQueryVariables>(RoleListDocument, baseOptions);
      }
export function useRoleListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleListQuery, RoleListQueryVariables>) {
          return Apollo.useLazyQuery<RoleListQuery, RoleListQueryVariables>(RoleListDocument, baseOptions);
        }
export type RoleListQueryHookResult = ReturnType<typeof useRoleListQuery>;
export type RoleListLazyQueryHookResult = ReturnType<typeof useRoleListLazyQuery>;
export type RoleListQueryResult = Apollo.QueryResult<RoleListQuery, RoleListQueryVariables>;
export const RoleDetailDocument = gql`
    query RoleDetail($id: ID!) {
  role(id: $id) {
    id
    ...RoleDetailPage
  }
}
    ${RoleDetailPageFragmentDoc}`;

/**
 * __useRoleDetailQuery__
 *
 * To run a query within a React component, call `useRoleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleDetailQuery(baseOptions?: Apollo.QueryHookOptions<RoleDetailQuery, RoleDetailQueryVariables>) {
        return Apollo.useQuery<RoleDetailQuery, RoleDetailQueryVariables>(RoleDetailDocument, baseOptions);
      }
export function useRoleDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleDetailQuery, RoleDetailQueryVariables>) {
          return Apollo.useLazyQuery<RoleDetailQuery, RoleDetailQueryVariables>(RoleDetailDocument, baseOptions);
        }
export type RoleDetailQueryHookResult = ReturnType<typeof useRoleDetailQuery>;
export type RoleDetailLazyQueryHookResult = ReturnType<typeof useRoleDetailLazyQuery>;
export type RoleDetailQueryResult = Apollo.QueryResult<RoleDetailQuery, RoleDetailQueryVariables>;
export const CreateRoleDocument = gql`
    mutation CreateRole($input: CreateRoleInput!) {
  createRole(input: $input) {
    ...RoleDetailPage
  }
}
    ${RoleDetailPageFragmentDoc}`;
export type CreateRoleMutationFn = Apollo.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const RoleEditDocument = gql`
    query RoleEdit($id: ID!) {
  role(id: $id) {
    id
    ...RoleEditForm
  }
}
    ${RoleEditFormFragmentDoc}`;

/**
 * __useRoleEditQuery__
 *
 * To run a query within a React component, call `useRoleEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRoleEditQuery(baseOptions?: Apollo.QueryHookOptions<RoleEditQuery, RoleEditQueryVariables>) {
        return Apollo.useQuery<RoleEditQuery, RoleEditQueryVariables>(RoleEditDocument, baseOptions);
      }
export function useRoleEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleEditQuery, RoleEditQueryVariables>) {
          return Apollo.useLazyQuery<RoleEditQuery, RoleEditQueryVariables>(RoleEditDocument, baseOptions);
        }
export type RoleEditQueryHookResult = ReturnType<typeof useRoleEditQuery>;
export type RoleEditLazyQueryHookResult = ReturnType<typeof useRoleEditLazyQuery>;
export type RoleEditQueryResult = Apollo.QueryResult<RoleEditQuery, RoleEditQueryVariables>;
export const UpdateRoleDocument = gql`
    mutation UpdateRole($input: UpdateRoleInput!) {
  updateRole(input: $input) {
    ...RoleDetailPage
  }
}
    ${RoleDetailPageFragmentDoc}`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation DeleteRole($id: ID!) {
  deleteRole(id: $id) {
    id
  }
}
    `;
export type DeleteRoleMutationFn = Apollo.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const CurrentTenantDetailDocument = gql`
    query CurrentTenantDetail {
  currentTenant {
    id
    ...SettingsDetailPage
  }
}
    ${SettingsDetailPageFragmentDoc}`;

/**
 * __useCurrentTenantDetailQuery__
 *
 * To run a query within a React component, call `useCurrentTenantDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentTenantDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentTenantDetailQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentTenantDetailQuery(baseOptions?: Apollo.QueryHookOptions<CurrentTenantDetailQuery, CurrentTenantDetailQueryVariables>) {
        return Apollo.useQuery<CurrentTenantDetailQuery, CurrentTenantDetailQueryVariables>(CurrentTenantDetailDocument, baseOptions);
      }
export function useCurrentTenantDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentTenantDetailQuery, CurrentTenantDetailQueryVariables>) {
          return Apollo.useLazyQuery<CurrentTenantDetailQuery, CurrentTenantDetailQueryVariables>(CurrentTenantDetailDocument, baseOptions);
        }
export type CurrentTenantDetailQueryHookResult = ReturnType<typeof useCurrentTenantDetailQuery>;
export type CurrentTenantDetailLazyQueryHookResult = ReturnType<typeof useCurrentTenantDetailLazyQuery>;
export type CurrentTenantDetailQueryResult = Apollo.QueryResult<CurrentTenantDetailQuery, CurrentTenantDetailQueryVariables>;
export const UpdateTenantDocument = gql`
    mutation UpdateTenant($input: UpdateTenantInput!) {
  updateTenant(input: $input) {
    ...SettingsDetailPage
  }
}
    ${SettingsDetailPageFragmentDoc}`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<UpdateTenantMutation, UpdateTenantMutationVariables>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTenantMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTenantMutation, UpdateTenantMutationVariables>) {
        return Apollo.useMutation<UpdateTenantMutation, UpdateTenantMutationVariables>(UpdateTenantDocument, baseOptions);
      }
export type UpdateTenantMutationHookResult = ReturnType<typeof useUpdateTenantMutation>;
export type UpdateTenantMutationResult = Apollo.MutationResult<UpdateTenantMutation>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<UpdateTenantMutation, UpdateTenantMutationVariables>;
export const RegenerateApiKeyDocument = gql`
    mutation RegenerateApiKey($id: ID!) {
  regenerateApiKey(id: $id) {
    ...SettingsDetailPage
  }
}
    ${SettingsDetailPageFragmentDoc}`;
export type RegenerateApiKeyMutationFn = Apollo.MutationFunction<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>;

/**
 * __useRegenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useRegenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateApiKeyMutation, { data, loading, error }] = useRegenerateApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegenerateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>) {
        return Apollo.useMutation<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>(RegenerateApiKeyDocument, baseOptions);
      }
export type RegenerateApiKeyMutationHookResult = ReturnType<typeof useRegenerateApiKeyMutation>;
export type RegenerateApiKeyMutationResult = Apollo.MutationResult<RegenerateApiKeyMutation>;
export type RegenerateApiKeyMutationOptions = Apollo.BaseMutationOptions<RegenerateApiKeyMutation, RegenerateApiKeyMutationVariables>;
export const SoulListDocument = gql`
    query SoulList($status: UserStatus, $page: Int, $trashed: Trashed) {
  souls(status: $status, first: 20, page: $page, trashed: $trashed) {
    data {
      ...SoulListItem
    }
    paginatorInfo {
      currentPage
      lastPage
      hasMorePages
    }
  }
}
    ${SoulListItemFragmentDoc}`;

/**
 * __useSoulListQuery__
 *
 * To run a query within a React component, call `useSoulListQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulListQuery({
 *   variables: {
 *      status: // value for 'status'
 *      page: // value for 'page'
 *      trashed: // value for 'trashed'
 *   },
 * });
 */
export function useSoulListQuery(baseOptions?: Apollo.QueryHookOptions<SoulListQuery, SoulListQueryVariables>) {
        return Apollo.useQuery<SoulListQuery, SoulListQueryVariables>(SoulListDocument, baseOptions);
      }
export function useSoulListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoulListQuery, SoulListQueryVariables>) {
          return Apollo.useLazyQuery<SoulListQuery, SoulListQueryVariables>(SoulListDocument, baseOptions);
        }
export type SoulListQueryHookResult = ReturnType<typeof useSoulListQuery>;
export type SoulListLazyQueryHookResult = ReturnType<typeof useSoulListLazyQuery>;
export type SoulListQueryResult = Apollo.QueryResult<SoulListQuery, SoulListQueryVariables>;
export const SoulDetailDocument = gql`
    query SoulDetail($id: ID!) {
  soul(id: $id) {
    id
    ...SoulInfo
  }
}
    ${SoulInfoFragmentDoc}`;

/**
 * __useSoulDetailQuery__
 *
 * To run a query within a React component, call `useSoulDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoulDetailQuery(baseOptions?: Apollo.QueryHookOptions<SoulDetailQuery, SoulDetailQueryVariables>) {
        return Apollo.useQuery<SoulDetailQuery, SoulDetailQueryVariables>(SoulDetailDocument, baseOptions);
      }
export function useSoulDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoulDetailQuery, SoulDetailQueryVariables>) {
          return Apollo.useLazyQuery<SoulDetailQuery, SoulDetailQueryVariables>(SoulDetailDocument, baseOptions);
        }
export type SoulDetailQueryHookResult = ReturnType<typeof useSoulDetailQuery>;
export type SoulDetailLazyQueryHookResult = ReturnType<typeof useSoulDetailLazyQuery>;
export type SoulDetailQueryResult = Apollo.QueryResult<SoulDetailQuery, SoulDetailQueryVariables>;
export const SoulEmailsDocument = gql`
    query SoulEmails($id: ID!, $page: Int) {
  soul(id: $id) {
    id
    emails(first: 10, page: $page) {
      data {
        ...EmailListItem
      }
      paginatorInfo {
        currentPage
        hasMorePages
        lastPage
      }
    }
  }
}
    ${EmailListItemFragmentDoc}`;

/**
 * __useSoulEmailsQuery__
 *
 * To run a query within a React component, call `useSoulEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulEmailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useSoulEmailsQuery(baseOptions?: Apollo.QueryHookOptions<SoulEmailsQuery, SoulEmailsQueryVariables>) {
        return Apollo.useQuery<SoulEmailsQuery, SoulEmailsQueryVariables>(SoulEmailsDocument, baseOptions);
      }
export function useSoulEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoulEmailsQuery, SoulEmailsQueryVariables>) {
          return Apollo.useLazyQuery<SoulEmailsQuery, SoulEmailsQueryVariables>(SoulEmailsDocument, baseOptions);
        }
export type SoulEmailsQueryHookResult = ReturnType<typeof useSoulEmailsQuery>;
export type SoulEmailsLazyQueryHookResult = ReturnType<typeof useSoulEmailsLazyQuery>;
export type SoulEmailsQueryResult = Apollo.QueryResult<SoulEmailsQuery, SoulEmailsQueryVariables>;
export const SoulEditDocument = gql`
    query SoulEdit($id: ID!) {
  soul(id: $id) {
    ...SoulEditForm
  }
}
    ${SoulEditFormFragmentDoc}`;

/**
 * __useSoulEditQuery__
 *
 * To run a query within a React component, call `useSoulEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoulEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoulEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoulEditQuery(baseOptions?: Apollo.QueryHookOptions<SoulEditQuery, SoulEditQueryVariables>) {
        return Apollo.useQuery<SoulEditQuery, SoulEditQueryVariables>(SoulEditDocument, baseOptions);
      }
export function useSoulEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoulEditQuery, SoulEditQueryVariables>) {
          return Apollo.useLazyQuery<SoulEditQuery, SoulEditQueryVariables>(SoulEditDocument, baseOptions);
        }
export type SoulEditQueryHookResult = ReturnType<typeof useSoulEditQuery>;
export type SoulEditLazyQueryHookResult = ReturnType<typeof useSoulEditLazyQuery>;
export type SoulEditQueryResult = Apollo.QueryResult<SoulEditQuery, SoulEditQueryVariables>;
export const CreateSoulDocument = gql`
    mutation CreateSoul($input: CreateSoulInput!) {
  createSoul(input: $input) {
    ...SoulInfo
  }
}
    ${SoulInfoFragmentDoc}`;
export type CreateSoulMutationFn = Apollo.MutationFunction<CreateSoulMutation, CreateSoulMutationVariables>;

/**
 * __useCreateSoulMutation__
 *
 * To run a mutation, you first call `useCreateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSoulMutation, { data, loading, error }] = useCreateSoulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSoulMutation(baseOptions?: Apollo.MutationHookOptions<CreateSoulMutation, CreateSoulMutationVariables>) {
        return Apollo.useMutation<CreateSoulMutation, CreateSoulMutationVariables>(CreateSoulDocument, baseOptions);
      }
export type CreateSoulMutationHookResult = ReturnType<typeof useCreateSoulMutation>;
export type CreateSoulMutationResult = Apollo.MutationResult<CreateSoulMutation>;
export type CreateSoulMutationOptions = Apollo.BaseMutationOptions<CreateSoulMutation, CreateSoulMutationVariables>;
export const UpdateSoulDocument = gql`
    mutation UpdateSoul($input: UpdateSoulInput!) {
  updateSoul(input: $input) {
    ...SoulInfo
  }
}
    ${SoulInfoFragmentDoc}`;
export type UpdateSoulMutationFn = Apollo.MutationFunction<UpdateSoulMutation, UpdateSoulMutationVariables>;

/**
 * __useUpdateSoulMutation__
 *
 * To run a mutation, you first call `useUpdateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoulMutation, { data, loading, error }] = useUpdateSoulMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSoulMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSoulMutation, UpdateSoulMutationVariables>) {
        return Apollo.useMutation<UpdateSoulMutation, UpdateSoulMutationVariables>(UpdateSoulDocument, baseOptions);
      }
export type UpdateSoulMutationHookResult = ReturnType<typeof useUpdateSoulMutation>;
export type UpdateSoulMutationResult = Apollo.MutationResult<UpdateSoulMutation>;
export type UpdateSoulMutationOptions = Apollo.BaseMutationOptions<UpdateSoulMutation, UpdateSoulMutationVariables>;
export const ApproveSoulDocument = gql`
    mutation ApproveSoul($id: ID!) {
  approveSoul(id: $id) {
    id
    status
  }
}
    `;
export type ApproveSoulMutationFn = Apollo.MutationFunction<ApproveSoulMutation, ApproveSoulMutationVariables>;

/**
 * __useApproveSoulMutation__
 *
 * To run a mutation, you first call `useApproveSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveSoulMutation, { data, loading, error }] = useApproveSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApproveSoulMutation(baseOptions?: Apollo.MutationHookOptions<ApproveSoulMutation, ApproveSoulMutationVariables>) {
        return Apollo.useMutation<ApproveSoulMutation, ApproveSoulMutationVariables>(ApproveSoulDocument, baseOptions);
      }
export type ApproveSoulMutationHookResult = ReturnType<typeof useApproveSoulMutation>;
export type ApproveSoulMutationResult = Apollo.MutationResult<ApproveSoulMutation>;
export type ApproveSoulMutationOptions = Apollo.BaseMutationOptions<ApproveSoulMutation, ApproveSoulMutationVariables>;
export const DenySoulDocument = gql`
    mutation DenySoul($id: ID!) {
  denySoul(id: $id) {
    id
    status
  }
}
    `;
export type DenySoulMutationFn = Apollo.MutationFunction<DenySoulMutation, DenySoulMutationVariables>;

/**
 * __useDenySoulMutation__
 *
 * To run a mutation, you first call `useDenySoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDenySoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [denySoulMutation, { data, loading, error }] = useDenySoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDenySoulMutation(baseOptions?: Apollo.MutationHookOptions<DenySoulMutation, DenySoulMutationVariables>) {
        return Apollo.useMutation<DenySoulMutation, DenySoulMutationVariables>(DenySoulDocument, baseOptions);
      }
export type DenySoulMutationHookResult = ReturnType<typeof useDenySoulMutation>;
export type DenySoulMutationResult = Apollo.MutationResult<DenySoulMutation>;
export type DenySoulMutationOptions = Apollo.BaseMutationOptions<DenySoulMutation, DenySoulMutationVariables>;
export const ActivateSoulDocument = gql`
    mutation ActivateSoul($id: ID!) {
  activateSoul(id: $id) {
    id
    status
  }
}
    `;
export type ActivateSoulMutationFn = Apollo.MutationFunction<ActivateSoulMutation, ActivateSoulMutationVariables>;

/**
 * __useActivateSoulMutation__
 *
 * To run a mutation, you first call `useActivateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateSoulMutation, { data, loading, error }] = useActivateSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateSoulMutation(baseOptions?: Apollo.MutationHookOptions<ActivateSoulMutation, ActivateSoulMutationVariables>) {
        return Apollo.useMutation<ActivateSoulMutation, ActivateSoulMutationVariables>(ActivateSoulDocument, baseOptions);
      }
export type ActivateSoulMutationHookResult = ReturnType<typeof useActivateSoulMutation>;
export type ActivateSoulMutationResult = Apollo.MutationResult<ActivateSoulMutation>;
export type ActivateSoulMutationOptions = Apollo.BaseMutationOptions<ActivateSoulMutation, ActivateSoulMutationVariables>;
export const DeactivateSoulDocument = gql`
    mutation DeactivateSoul($id: ID!) {
  deactivateSoul(id: $id) {
    id
    status
  }
}
    `;
export type DeactivateSoulMutationFn = Apollo.MutationFunction<DeactivateSoulMutation, DeactivateSoulMutationVariables>;

/**
 * __useDeactivateSoulMutation__
 *
 * To run a mutation, you first call `useDeactivateSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateSoulMutation, { data, loading, error }] = useDeactivateSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateSoulMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateSoulMutation, DeactivateSoulMutationVariables>) {
        return Apollo.useMutation<DeactivateSoulMutation, DeactivateSoulMutationVariables>(DeactivateSoulDocument, baseOptions);
      }
export type DeactivateSoulMutationHookResult = ReturnType<typeof useDeactivateSoulMutation>;
export type DeactivateSoulMutationResult = Apollo.MutationResult<DeactivateSoulMutation>;
export type DeactivateSoulMutationOptions = Apollo.BaseMutationOptions<DeactivateSoulMutation, DeactivateSoulMutationVariables>;
export const DeleteSoulDocument = gql`
    mutation DeleteSoul($id: ID!) {
  deleteSoul(id: $id) {
    id
  }
}
    `;
export type DeleteSoulMutationFn = Apollo.MutationFunction<DeleteSoulMutation, DeleteSoulMutationVariables>;

/**
 * __useDeleteSoulMutation__
 *
 * To run a mutation, you first call `useDeleteSoulMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoulMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoulMutation, { data, loading, error }] = useDeleteSoulMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSoulMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSoulMutation, DeleteSoulMutationVariables>) {
        return Apollo.useMutation<DeleteSoulMutation, DeleteSoulMutationVariables>(DeleteSoulDocument, baseOptions);
      }
export type DeleteSoulMutationHookResult = ReturnType<typeof useDeleteSoulMutation>;
export type DeleteSoulMutationResult = Apollo.MutationResult<DeleteSoulMutation>;
export type DeleteSoulMutationOptions = Apollo.BaseMutationOptions<DeleteSoulMutation, DeleteSoulMutationVariables>;
export const RestoreSoulsDocument = gql`
    mutation RestoreSouls($ids: [ID!]!) {
  restoreSouls(ids: $ids) {
    id
  }
}
    `;
export type RestoreSoulsMutationFn = Apollo.MutationFunction<RestoreSoulsMutation, RestoreSoulsMutationVariables>;

/**
 * __useRestoreSoulsMutation__
 *
 * To run a mutation, you first call `useRestoreSoulsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestoreSoulsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restoreSoulsMutation, { data, loading, error }] = useRestoreSoulsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRestoreSoulsMutation(baseOptions?: Apollo.MutationHookOptions<RestoreSoulsMutation, RestoreSoulsMutationVariables>) {
        return Apollo.useMutation<RestoreSoulsMutation, RestoreSoulsMutationVariables>(RestoreSoulsDocument, baseOptions);
      }
export type RestoreSoulsMutationHookResult = ReturnType<typeof useRestoreSoulsMutation>;
export type RestoreSoulsMutationResult = Apollo.MutationResult<RestoreSoulsMutation>;
export type RestoreSoulsMutationOptions = Apollo.BaseMutationOptions<RestoreSoulsMutation, RestoreSoulsMutationVariables>;
export const ForceDeleteSoulsDocument = gql`
    mutation ForceDeleteSouls($ids: [ID!]!) {
  forceDeleteSouls(ids: $ids) {
    id
  }
}
    `;
export type ForceDeleteSoulsMutationFn = Apollo.MutationFunction<ForceDeleteSoulsMutation, ForceDeleteSoulsMutationVariables>;

/**
 * __useForceDeleteSoulsMutation__
 *
 * To run a mutation, you first call `useForceDeleteSoulsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForceDeleteSoulsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forceDeleteSoulsMutation, { data, loading, error }] = useForceDeleteSoulsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useForceDeleteSoulsMutation(baseOptions?: Apollo.MutationHookOptions<ForceDeleteSoulsMutation, ForceDeleteSoulsMutationVariables>) {
        return Apollo.useMutation<ForceDeleteSoulsMutation, ForceDeleteSoulsMutationVariables>(ForceDeleteSoulsDocument, baseOptions);
      }
export type ForceDeleteSoulsMutationHookResult = ReturnType<typeof useForceDeleteSoulsMutation>;
export type ForceDeleteSoulsMutationResult = Apollo.MutationResult<ForceDeleteSoulsMutation>;
export type ForceDeleteSoulsMutationOptions = Apollo.BaseMutationOptions<ForceDeleteSoulsMutation, ForceDeleteSoulsMutationVariables>;
export const EmptySoulTrashDocument = gql`
    mutation EmptySoulTrash {
  emptySoulTrash {
    id
  }
}
    `;
export type EmptySoulTrashMutationFn = Apollo.MutationFunction<EmptySoulTrashMutation, EmptySoulTrashMutationVariables>;

/**
 * __useEmptySoulTrashMutation__
 *
 * To run a mutation, you first call `useEmptySoulTrashMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmptySoulTrashMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emptySoulTrashMutation, { data, loading, error }] = useEmptySoulTrashMutation({
 *   variables: {
 *   },
 * });
 */
export function useEmptySoulTrashMutation(baseOptions?: Apollo.MutationHookOptions<EmptySoulTrashMutation, EmptySoulTrashMutationVariables>) {
        return Apollo.useMutation<EmptySoulTrashMutation, EmptySoulTrashMutationVariables>(EmptySoulTrashDocument, baseOptions);
      }
export type EmptySoulTrashMutationHookResult = ReturnType<typeof useEmptySoulTrashMutation>;
export type EmptySoulTrashMutationResult = Apollo.MutationResult<EmptySoulTrashMutation>;
export type EmptySoulTrashMutationOptions = Apollo.BaseMutationOptions<EmptySoulTrashMutation, EmptySoulTrashMutationVariables>;
export const OverviewStatsDocument = gql`
    query OverviewStats($input: OverviewStatsInput!) {
  overviewStats(input: $input) {
    tickets
    signups
    attends
  }
}
    `;

/**
 * __useOverviewStatsQuery__
 *
 * To run a query within a React component, call `useOverviewStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOverviewStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOverviewStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useOverviewStatsQuery(baseOptions?: Apollo.QueryHookOptions<OverviewStatsQuery, OverviewStatsQueryVariables>) {
        return Apollo.useQuery<OverviewStatsQuery, OverviewStatsQueryVariables>(OverviewStatsDocument, baseOptions);
      }
export function useOverviewStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OverviewStatsQuery, OverviewStatsQueryVariables>) {
          return Apollo.useLazyQuery<OverviewStatsQuery, OverviewStatsQueryVariables>(OverviewStatsDocument, baseOptions);
        }
export type OverviewStatsQueryHookResult = ReturnType<typeof useOverviewStatsQuery>;
export type OverviewStatsLazyQueryHookResult = ReturnType<typeof useOverviewStatsLazyQuery>;
export type OverviewStatsQueryResult = Apollo.QueryResult<OverviewStatsQuery, OverviewStatsQueryVariables>;
export const TargetGroupStatsDocument = gql`
    query TargetGroupStats($input: TargetGroupStatsInput!) {
  targetGroupStats(input: $input) {
    target_group
    ...TargetGroupStats
  }
}
    ${TargetGroupStatsFragmentDoc}`;

/**
 * __useTargetGroupStatsQuery__
 *
 * To run a query within a React component, call `useTargetGroupStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTargetGroupStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTargetGroupStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTargetGroupStatsQuery(baseOptions?: Apollo.QueryHookOptions<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>) {
        return Apollo.useQuery<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>(TargetGroupStatsDocument, baseOptions);
      }
export function useTargetGroupStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>) {
          return Apollo.useLazyQuery<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>(TargetGroupStatsDocument, baseOptions);
        }
export type TargetGroupStatsQueryHookResult = ReturnType<typeof useTargetGroupStatsQuery>;
export type TargetGroupStatsLazyQueryHookResult = ReturnType<typeof useTargetGroupStatsLazyQuery>;
export type TargetGroupStatsQueryResult = Apollo.QueryResult<TargetGroupStatsQuery, TargetGroupStatsQueryVariables>;
export const CategoryStatsDocument = gql`
    query CategoryStats($input: CategoryStatsInput!) {
  categoryStats(input: $input) {
    category {
      id
    }
    ...CategoryStats
  }
}
    ${CategoryStatsFragmentDoc}`;

/**
 * __useCategoryStatsQuery__
 *
 * To run a query within a React component, call `useCategoryStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoryStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoryStatsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCategoryStatsQuery(baseOptions?: Apollo.QueryHookOptions<CategoryStatsQuery, CategoryStatsQueryVariables>) {
        return Apollo.useQuery<CategoryStatsQuery, CategoryStatsQueryVariables>(CategoryStatsDocument, baseOptions);
      }
export function useCategoryStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoryStatsQuery, CategoryStatsQueryVariables>) {
          return Apollo.useLazyQuery<CategoryStatsQuery, CategoryStatsQueryVariables>(CategoryStatsDocument, baseOptions);
        }
export type CategoryStatsQueryHookResult = ReturnType<typeof useCategoryStatsQuery>;
export type CategoryStatsLazyQueryHookResult = ReturnType<typeof useCategoryStatsLazyQuery>;
export type CategoryStatsQueryResult = Apollo.QueryResult<CategoryStatsQuery, CategoryStatsQueryVariables>;
export const TagListDocument = gql`
    query TagList {
  tags {
    ...TagListItem
  }
}
    ${TagListItemFragmentDoc}`;

/**
 * __useTagListQuery__
 *
 * To run a query within a React component, call `useTagListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagListQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagListQuery(baseOptions?: Apollo.QueryHookOptions<TagListQuery, TagListQueryVariables>) {
        return Apollo.useQuery<TagListQuery, TagListQueryVariables>(TagListDocument, baseOptions);
      }
export function useTagListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagListQuery, TagListQueryVariables>) {
          return Apollo.useLazyQuery<TagListQuery, TagListQueryVariables>(TagListDocument, baseOptions);
        }
export type TagListQueryHookResult = ReturnType<typeof useTagListQuery>;
export type TagListLazyQueryHookResult = ReturnType<typeof useTagListLazyQuery>;
export type TagListQueryResult = Apollo.QueryResult<TagListQuery, TagListQueryVariables>;
export const TagDetailDocument = gql`
    query TagDetail($id: ID!) {
  tag(id: $id) {
    id
    ...TagDetailPage
  }
}
    ${TagDetailPageFragmentDoc}`;

/**
 * __useTagDetailQuery__
 *
 * To run a query within a React component, call `useTagDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagDetailQuery(baseOptions?: Apollo.QueryHookOptions<TagDetailQuery, TagDetailQueryVariables>) {
        return Apollo.useQuery<TagDetailQuery, TagDetailQueryVariables>(TagDetailDocument, baseOptions);
      }
export function useTagDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagDetailQuery, TagDetailQueryVariables>) {
          return Apollo.useLazyQuery<TagDetailQuery, TagDetailQueryVariables>(TagDetailDocument, baseOptions);
        }
export type TagDetailQueryHookResult = ReturnType<typeof useTagDetailQuery>;
export type TagDetailLazyQueryHookResult = ReturnType<typeof useTagDetailLazyQuery>;
export type TagDetailQueryResult = Apollo.QueryResult<TagDetailQuery, TagDetailQueryVariables>;
export const TagEditDocument = gql`
    query TagEdit($id: ID!) {
  tag(id: $id) {
    id
    ...TagEditForm
  }
}
    ${TagEditFormFragmentDoc}`;

/**
 * __useTagEditQuery__
 *
 * To run a query within a React component, call `useTagEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagEditQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagEditQuery(baseOptions?: Apollo.QueryHookOptions<TagEditQuery, TagEditQueryVariables>) {
        return Apollo.useQuery<TagEditQuery, TagEditQueryVariables>(TagEditDocument, baseOptions);
      }
export function useTagEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagEditQuery, TagEditQueryVariables>) {
          return Apollo.useLazyQuery<TagEditQuery, TagEditQueryVariables>(TagEditDocument, baseOptions);
        }
export type TagEditQueryHookResult = ReturnType<typeof useTagEditQuery>;
export type TagEditLazyQueryHookResult = ReturnType<typeof useTagEditLazyQuery>;
export type TagEditQueryResult = Apollo.QueryResult<TagEditQuery, TagEditQueryVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($input: CreateTagInput!) {
  createTag(input: $input) {
    ...TagDetailPage
  }
}
    ${TagDetailPageFragmentDoc}`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($input: UpdateTagInput!) {
  updateTag(input: $input) {
    ...TagDetailPage
  }
}
    ${TagDetailPageFragmentDoc}`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, baseOptions);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
    id
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, baseOptions);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const TagsDocument = gql`
    query Tags {
  tags {
    ...PersonTagMultiSelect
  }
}
    ${PersonTagMultiSelectFragmentDoc}`;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const PermissionsDocument = gql`
    query Permissions {
  permissions {
    ...PermissionMultiSelect
  }
}
    ${PermissionMultiSelectFragmentDoc}`;

/**
 * __usePermissionsQuery__
 *
 * To run a query within a React component, call `usePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePermissionsQuery(baseOptions?: Apollo.QueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
        return Apollo.useQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
      }
export function usePermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PermissionsQuery, PermissionsQueryVariables>) {
          return Apollo.useLazyQuery<PermissionsQuery, PermissionsQueryVariables>(PermissionsDocument, baseOptions);
        }
export type PermissionsQueryHookResult = ReturnType<typeof usePermissionsQuery>;
export type PermissionsLazyQueryHookResult = ReturnType<typeof usePermissionsLazyQuery>;
export type PermissionsQueryResult = Apollo.QueryResult<PermissionsQuery, PermissionsQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  roles {
    ...RoleMultiSelect
  }
}
    ${RoleMultiSelectFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, baseOptions);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const CategoriesDocument = gql`
    query Categories {
  categories {
    ...CategoryMultiSelect
  }
}
    ${CategoryMultiSelectFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a React component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
        return Apollo.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
      }
export function useCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CategoriesQuery, CategoriesQueryVariables>) {
          return Apollo.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, baseOptions);
        }
export type CategoriesQueryHookResult = ReturnType<typeof useCategoriesQuery>;
export type CategoriesLazyQueryHookResult = ReturnType<typeof useCategoriesLazyQuery>;
export type CategoriesQueryResult = Apollo.QueryResult<CategoriesQuery, CategoriesQueryVariables>;
export const AllotmentsDocument = gql`
    query Allotments {
  allotments {
    ...AllotmentSelect
  }
}
    ${AllotmentSelectFragmentDoc}`;

/**
 * __useAllotmentsQuery__
 *
 * To run a query within a React component, call `useAllotmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllotmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllotmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllotmentsQuery(baseOptions?: Apollo.QueryHookOptions<AllotmentsQuery, AllotmentsQueryVariables>) {
        return Apollo.useQuery<AllotmentsQuery, AllotmentsQueryVariables>(AllotmentsDocument, baseOptions);
      }
export function useAllotmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllotmentsQuery, AllotmentsQueryVariables>) {
          return Apollo.useLazyQuery<AllotmentsQuery, AllotmentsQueryVariables>(AllotmentsDocument, baseOptions);
        }
export type AllotmentsQueryHookResult = ReturnType<typeof useAllotmentsQuery>;
export type AllotmentsLazyQueryHookResult = ReturnType<typeof useAllotmentsLazyQuery>;
export type AllotmentsQueryResult = Apollo.QueryResult<AllotmentsQuery, AllotmentsQueryVariables>;
export const OrganisationsDocument = gql`
    query Organisations {
  organisations {
    ...PersonOrganisationSelect
  }
}
    ${PersonOrganisationSelectFragmentDoc}`;

/**
 * __useOrganisationsQuery__
 *
 * To run a query within a React component, call `useOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
        return Apollo.useQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
      }
export function useOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganisationsQuery, OrganisationsQueryVariables>) {
          return Apollo.useLazyQuery<OrganisationsQuery, OrganisationsQueryVariables>(OrganisationsDocument, baseOptions);
        }
export type OrganisationsQueryHookResult = ReturnType<typeof useOrganisationsQuery>;
export type OrganisationsLazyQueryHookResult = ReturnType<typeof useOrganisationsLazyQuery>;
export type OrganisationsQueryResult = Apollo.QueryResult<OrganisationsQuery, OrganisationsQueryVariables>;
export const EmailUsersDocument = gql`
    mutation EmailUsers($input: EmailUsersInput!) {
  emailUsers(input: $input)
}
    `;
export type EmailUsersMutationFn = Apollo.MutationFunction<EmailUsersMutation, EmailUsersMutationVariables>;

/**
 * __useEmailUsersMutation__
 *
 * To run a mutation, you first call `useEmailUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailUsersMutation, { data, loading, error }] = useEmailUsersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailUsersMutation(baseOptions?: Apollo.MutationHookOptions<EmailUsersMutation, EmailUsersMutationVariables>) {
        return Apollo.useMutation<EmailUsersMutation, EmailUsersMutationVariables>(EmailUsersDocument, baseOptions);
      }
export type EmailUsersMutationHookResult = ReturnType<typeof useEmailUsersMutation>;
export type EmailUsersMutationResult = Apollo.MutationResult<EmailUsersMutation>;
export type EmailUsersMutationOptions = Apollo.BaseMutationOptions<EmailUsersMutation, EmailUsersMutationVariables>;
export const SendResetLinkForUserDocument = gql`
    mutation SendResetLinkForUser($id: ID!) {
  sendResetLinkForUser(id: $id)
}
    `;
export type SendResetLinkForUserMutationFn = Apollo.MutationFunction<SendResetLinkForUserMutation, SendResetLinkForUserMutationVariables>;

/**
 * __useSendResetLinkForUserMutation__
 *
 * To run a mutation, you first call `useSendResetLinkForUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetLinkForUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetLinkForUserMutation, { data, loading, error }] = useSendResetLinkForUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendResetLinkForUserMutation(baseOptions?: Apollo.MutationHookOptions<SendResetLinkForUserMutation, SendResetLinkForUserMutationVariables>) {
        return Apollo.useMutation<SendResetLinkForUserMutation, SendResetLinkForUserMutationVariables>(SendResetLinkForUserDocument, baseOptions);
      }
export type SendResetLinkForUserMutationHookResult = ReturnType<typeof useSendResetLinkForUserMutation>;
export type SendResetLinkForUserMutationResult = Apollo.MutationResult<SendResetLinkForUserMutation>;
export type SendResetLinkForUserMutationOptions = Apollo.BaseMutationOptions<SendResetLinkForUserMutation, SendResetLinkForUserMutationVariables>;