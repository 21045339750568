import { gql } from '@apollo/client';
import CompanionForm from './components/CompanionForm';
import CompanionListItem from './components/CompanionListItem';
import CompanionInfo from './components/CompanionInfo';
import EmailListItem from '../emails/components/EmailListItem';

export const COMPANION_LIST_QUERY = gql`
  query CompanionList($status: UserStatus, $page: Int, $trashed: Trashed = WITHOUT) {
    companions(status: $status, first: 20, page: $page, trashed: $trashed) {
      data {
        ...CompanionListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
  ${CompanionListItem.fragments.companion}
`;

export const COMPANION_DETAIL_QUERY = gql`
  query CompanionDetail($id: ID!) {
    companion(id: $id) {
      id
      ...CompanionInfo
    }
  }
  ${CompanionInfo.fragments.companion}
`;

export const COMPANION_EMAILS_QUERY = gql`
  query CompanionEmails($id: ID!, $page: Int) {
    companion(id: $id) {
      id
      emails(first: 10, page: $page) {
        data {
          ...EmailListItem
        }
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
        }
      }
    }
  }
  ${EmailListItem.fragments.emails}
`;

export const COMPANION_EDIT_QUERY = gql`
  query CompanionEdit($id: ID!) {
    companion(id: $id) {
      ...CompanionEditForm
    }
  }
  ${CompanionForm.fragments.edit}
`;

export const CREATE_COMPANION_MUTATION = gql`
  mutation CreateCompanion($input: CreateCompanionInput!) {
    createCompanion(input: $input) {
      ...CompanionInfo
    }
  }
  ${CompanionInfo.fragments.companion}
`;

export const UPDATE_COMPANION_MUTATION = gql`
  mutation UpdateCompanion($input: UpdateCompanionInput!) {
    updateCompanion(input: $input) {
      ...CompanionInfo
    }
  }
  ${CompanionInfo.fragments.companion}
`;

export const APPROVE_COMPANION_MUTATION = gql`
  mutation ApproveCompanion($id: ID!) {
    approveCompanion(id: $id) {
      id
      status
    }
  }
`;

export const DENY_COMPANION_MUTATION = gql`
  mutation DenyCompanion($id: ID!) {
    denyCompanion(id: $id) {
      id
      status
    }
  }
`;

export const ACTIVATE_COMPANION_MUTATION = gql`
  mutation ActivateCompanion($id: ID!) {
    activateCompanion(id: $id) {
      id
      status
    }
  }
`;

export const DEACTIVATE_COMPANION_MUTATION = gql`
  mutation DeactivateCompanion($id: ID!) {
    deactivateCompanion(id: $id) {
      id
      status
    }
  }
`;

export const DELETE_COMPANION_MUTATION = gql`
  mutation DeleteCompanion($id: ID!) {
    deleteCompanion(id: $id) {
      id
    }
  }
`;

export const RESTORE_COMPANIONS_MUTATION = gql`
  mutation RestoreCompanions($ids: [ID!]!) {
    restoreCompanions(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_COMPANIONS_MUTATION = gql`
  mutation ForceDeleteCompanions($ids: [ID!]!) {
    forceDeleteCompanions(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_COMPANION_TRASH_MUTATION = gql`
  mutation EmptyCompanionTrash {
    emptyCompanionTrash {
      id
    }
  }
`;
