import { gql } from '@apollo/client';
import { Alert, Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useCurrentTenantDetailQuery, useRegenerateApiKeyMutation } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';
import SettingsDetailMenu from '../components/SettingsDetailMenu';
import SettingsEditOverlay from '../components/SettingsEditOverlay';

const SettingsDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isRegenerateAlertOpen, setIsRegenerateAlertOpen] = useState(false);
  const { data, loading, error } = useCurrentTenantDetailQuery();
  const [regenerateApiKey, { loading: regenerating }] = useRegenerateApiKeyMutation({
    onError: showErrorMessage,
    variables: {
      id: data?.currentTenant?.id || '0',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.currentTenant) return <NotFoundCard resource="Einstellungen" />;

  const { currentTenant } = data;

  return (
    <>
      <Alert
        isOpen={isRegenerateAlertOpen}
        cancelButtonText="Abbrechen"
        confirmButtonText="Regenerieren"
        icon={IconNames.REFRESH}
        intent={Intent.DANGER}
        onClose={() => setIsRegenerateAlertOpen(false)}
        onConfirm={regenerateApiKey as any}
      >
        <p>
          Soll der API Key wirklich regeneriert werden? <br />
          Alle API Abfragen, die noch den alten API Key verwerden, werden fehlschlagen
        </p>
      </Alert>
      <SettingsEditOverlay isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={<Text large>Einstellungen</Text>}
          rightElement={<SettingsDetailMenu onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>API</PropertyListHeader>
            <PropertyListItem label="API (v2) Instance">
              <span>{currentTenant.subdomain}</span>
            </PropertyListItem>
            <PropertyListItem label="Legacy API (v1) Key">
              <span>{currentTenant.legacy_api_key}</span>
              <Button
                small
                text="Regenerieren"
                icon={IconNames.REFRESH}
                intent={Intent.DANGER}
                onClick={() => setIsRegenerateAlertOpen(true)}
                loading={regenerating}
              />
            </PropertyListItem>
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Webhooks</PropertyListHeader>
            <PropertyListItem
              label="Veranstaltungsliste"
              value={currentTenant.redirect_events || '—'}
              showValueDisabled={!currentTenant.redirect_events}
            />
            <PropertyListItem
              label="Rückmeldung erfolgreich"
              value={currentTenant.redirect_ticket_accept_ok || '—'}
              showValueDisabled={!currentTenant.redirect_ticket_accept_ok}
            />
            <PropertyListItem
              label="Rückmeldung nicht gefunden"
              value={currentTenant.redirect_ticket_accept_not_found || '—'}
              showValueDisabled={!currentTenant.redirect_ticket_accept_not_found}
            />
            <PropertyListItem
              label="Passwort Zurücksetzen"
              value={currentTenant.redirect_password_reset || '—'}
              showValueDisabled={!currentTenant.redirect_password_reset}
            />
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

SettingsDetailPage.fragments = {
  settings: gql`
    fragment SettingsDetailPage on Tenant {
      id
      subdomain
      legacy_api_key
      redirect_events
      redirect_ticket_accept_ok
      redirect_ticket_accept_not_found
      redirect_password_reset
    }
  `,
};

export default SettingsDetailPage;
