import { Position } from '@blueprintjs/core';
import { DateRange, DateRangeInput, IDateRangeInputProps } from '@blueprintjs/datetime';
import { endOfDay, format, isValid, isWithinInterval, parse, parseISO } from 'date-fns';
import React from 'react';
import { LocaleUtils } from '../helpers/dateTimeLocaleUtils';
import { defaultMaxDate, defaultMinDate } from '../helpers/dateTimeUtils';
import { DateTimeRange } from '../generated/graphql';

type DateRangePickerProps = {
  value: DateTimeRange | null | undefined;
  onChange: (value: DateTimeRange | null | undefined) => void;
  withTime?: boolean;
} & Partial<Omit<IDateRangeInputProps, 'value' | 'onChange'>>;

const DateRangePicker = ({ value, onChange, withTime = false, ...props }: DateRangePickerProps) => {
  const parseUserInput = (str: string) => {
    if (!str) return null;

    const date = parse(str, withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy', new Date());
    if (!isValid(date)) return false;

    if (
      !isWithinInterval(date, {
        start: props.minDate || defaultMinDate,
        end: props.maxDate || defaultMaxDate,
      })
    ) {
      return false;
    }

    return date;
  };

  const parseValue = (): DateRange => {
    const { from, to } = value || {};
    return [from ? parseISO(from) : null, to ? parseISO(to) : null];
  };

  return (
    <DateRangeInput
      {...props}
      localeUtils={LocaleUtils}
      formatDate={(selectedDate) => format(selectedDate, withTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy')}
      onChange={([selectedBeginDate, selectedEndDate]) =>
        onChange({
          from:
            !!selectedBeginDate && isValid(selectedBeginDate)
              ? format(selectedBeginDate, 'yyyy-MM-dd HH:mm:ss')
              : undefined,
          to:
            !!selectedEndDate && isValid(selectedEndDate)
              ? format(withTime ? selectedEndDate : endOfDay(selectedEndDate), 'yyyy-MM-dd HH:mm:ss')
              : undefined,
        })
      }
      invalidDateMessage="Ungültiges Datum"
      outOfRangeMessage="Außerhalb des gültigen Zeitraumes"
      parseDate={parseUserInput}
      placeholder={withTime ? 'TT.MM.JJJJ SS:MM' : 'TT.MM.JJJJ'}
      value={parseValue()}
      disabled={props.disabled}
      startInputProps={{
        placeholder: withTime ? 'Von (TT.MM.JJJJ SS:MM)' : 'Von (TT.MM.JJJJ)',
      }}
      endInputProps={{
        placeholder: withTime ? 'Bis (TT.MM.JJJJ SS:MM)' : 'Bis (TT.MM.JJJJ)',
      }}
      popoverProps={{
        position: Position.RIGHT,
        captureDismiss: true,
      }}
    />
  );
};

// Overriding BP DateRangeInput default props
DateRangePicker.defaultProps = {
  locale: 'de',
  allowSingleDayRange: true,
  minDate: defaultMinDate,
  maxDate: defaultMaxDate,
};

export default DateRangePicker;
