import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useAllotmentDetailQuery } from '../../../generated/graphql';
import { formatDay } from '../../../helpers/dateTimeUtils';
import AllotmentDetailMenu from '../components/AllotmentDetailMenu';
import AllotmentEditOverlay from '../components/AllotmentEditOverlay';

const AllotmentDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { allotmentId } = useParams();

  const options = {
    variables: {
      id: allotmentId || '',
    },
  };

  const { data, loading, error } = useAllotmentDetailQuery(options);

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.allotment) return <NotFoundCard resource="Spender" />;

  const { allotment } = data;

  return (
    <>
      <AllotmentEditOverlay id={allotment.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{allotment.name}</Text>
              <Tag minimal className="ml-2">
                Kontingent
              </Tag>
            </>
          }
          rightElement={<AllotmentDetailMenu allotment={allotment} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Karten</PropertyListHeader>
            <PropertyListItem label="Insgesamt" value={allotment.amount.toString()} />
            <PropertyListItem label="Verfügbar" value={allotment.amount_left.toString()} />
          </PropertyList>
          <PropertyListDivider />
          <PropertyList>
            <PropertyListHeader>Gültigkeit</PropertyListHeader>
            <PropertyListItem label="Start" value={formatDay(allotment.start)} />
            <PropertyListItem
              label="Ende"
              value={allotment.end ? formatDay(allotment.end) : 'Unbegrenzt Gültig!'}
              showValueDisabled={!allotment.end}
            />
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

AllotmentDetailPage.fragments = {
  allotment: gql`
    fragment AllotmentDetailPage on Allotment {
      id
      name
      status
      amount
      amount_left
      start
      end
    }
  `,
};

export default AllotmentDetailPage;
