import { gql } from '@apollo/client';
import { Classes, Elevation, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import UserStatusTag from '../../../components/PersonStatusTag';
import PersonTagIcon from '../../../components/PersonTagIcon';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import TooltipTag from '../../../components/TooltipTag';
import { GdprConsentSource, Intent, useCompanionDetailQuery } from '../../../generated/graphql';
import { getPersisted } from '../../../helpers/constants';
import { getAgeInYears } from '../../../helpers/dateTimeUtils';
import CompanionDetailMenu from './CompanionDetailMenu';

type CompanionInfoProps = {
  onEdit: () => void;
};

const CompanionInfo = ({ onEdit }: CompanionInfoProps) => {
  const { companionId } = useParams();
  const { data, loading, error } = useCompanionDetailQuery({
    variables: {
      id: companionId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.companion) return <NotFoundCard resource="Begleiter" />;

  const { companion } = data;

  const address: string | null =
    companion.street && companion.postal_code && companion.city
      ? `${companion.street}, ${companion.postal_code} ${companion.city}`
      : null;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={
          <>
            <Text large>{companion.display_name}</Text>
            <Tag minimal className="ml-2">
              Begleiter
            </Tag>
            <UserStatusTag status={companion.status} />
          </>
        }
        rightElement={<CompanionDetailMenu companion={companion} onEdit={onEdit} />}
      />
      <div className="flex py-4 px-5">
        <PropertyList>
          <PropertyListHeader>Person</PropertyListHeader>
          <PropertyListItem
            label="Alter"
            value={companion.birthdate ? getAgeInYears(companion.birthdate).toString() : '—'}
            showValueDisabled={!companion.birthdate}
          />
          <PropertyListItem label="Email">
            <EmailLink email={companion.email} />
          </PropertyListItem>
          {!getPersisted('useLegacyNewsletter') && !!companion.email && (
            <PropertyListItem label="Newsletter">
              {companion.gdpr_consent ? (
                <TooltipTag
                  tooltip="Für diesen Begleiter liegt die Zustimmung zum Newsletter-Versand vor"
                  label="angemeldet"
                  minimal
                  intent={Intent.Success}
                />
              ) : (
                <TooltipTag
                  tooltip="Für diesen Begleiter liegt die Zustimmung zum Newsletter-Versand NICHT vor"
                  label="abgemeldet"
                  minimal
                  intent={Intent.Danger}
                />
              )}
              {companion.gdpr_consent_source && (
                <TooltipTag
                  tooltip={`Erteilte/Widerrufene Zustimmung wurde ${
                    companion.gdpr_consent_source === GdprConsentSource.Employee
                      ? 'durch einen Mitarbeiter'
                      : 'vom Begleiter selbstständig'
                  } registriert`}
                  label={
                    companion.gdpr_consent_source === GdprConsentSource.Employee ? 'durch Mitarbeiter' : 'selbstständig'
                  }
                  minimal
                  intent={Intent.None}
                  marginLeft
                />
              )}
            </PropertyListItem>
          )}

          <PropertyListItem label="Telefon" value={companion.phone || '—'} showValueDisabled={!companion.phone} />
          <PropertyListItem label="Mobil" value={companion.mobile || '—'} showValueDisabled={!companion.mobile} />
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Begleiter Info</PropertyListHeader>
          <PropertyListItem label="Besonderheiten">
            {companion.meta.can_wheelchair && (
              <TooltipIcon label="Kann Rollstuhl schieben" icon={IconNames.SHOPPING_CART} marginRight />
            )}
            {companion.meta.can_wheeled_walker && (
              <TooltipIcon label="Kann Genießer mit Rollator begleiten" icon={IconNames.WALK} marginRight />
            )}
            {companion.meta.can_blind && <TooltipIcon label="Kann Blinde begleiten" icon={IconNames.EYE_OFF} />}
            {companion.meta.can_drive && (
              <TooltipIcon label="Kann Genießer von Zuhause abholen" icon={IconNames.DRIVE_TIME} marginRight />
            )}
          </PropertyListItem>
          <PropertyListItem label="Attribute">
            {!!companion.tags.length ? (
              companion.tags.map((tag: any) => <PersonTagIcon key={tag.id} tag={tag} />)
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
          <PropertyListItem
            label="Organisation"
            value={companion.organisation ? companion.organisation.name : '—'}
            showValueDisabled={!companion.organisation}
          />
          <PropertyListItem label="Interne Notizen">
            {companion.notes ? (
              <div className={[Classes.RUNNING_TEXT, Classes.TEXT_MUTED].join(' ')}>{companion.notes}</div>
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

CompanionInfo.fragments = {
  companion: gql`
    fragment CompanionInfo on Companion {
      id
      status
      display_name
      first_name
      last_name
      birthdate
      email
      gdpr_consent
      gdpr_consent_source
      phone
      mobile
      street
      city
      postal_code
      notes
      meta {
        can_wheelchair
        can_wheeled_walker
        can_drive
        can_blind
      }
      organisation {
        id
        name
      }
      tags {
        id
        name
        icon
        intent
      }
    }
  `,
};

export default CompanionInfo;
