import { Button, Callout, Card, Intent, Tooltip } from '@blueprintjs/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import React, { useCallback, useState } from 'react';
import * as Yup from 'yup';
import FormGroup from '../../../components/FormGroup';
import TextInput from '../../../components/TextInput';
import { NewPasswordWithCodeInput } from '../../../generated/graphql';

interface ILockButton {
  locked: boolean;
  disabled?: boolean;
  onClick: any;
}

const LockButton = ({ locked, disabled, onClick }: ILockButton) => (
  <Tooltip content={`Passwort ${locked ? 'zeigen' : 'verstecken'}`} disabled={disabled}>
    <Button
      disabled={disabled}
      icon={locked ? 'unlock' : 'lock'}
      intent={Intent.WARNING}
      minimal={true}
      onClick={onClick}
    />
  </Tooltip>
);

type ResetFormProps = {
  onSubmit: (values: NewPasswordWithCodeInput, formikHelpers: FormikHelpers<NewPasswordWithCodeInput>) => void;
  email: string | null;
  token: string | null;
};

const ResetSchema = Yup.object({
  password: Yup.string().min(8, 'Zu Kurz').max(50, 'Zu Lang').required('Erforderlich'),
  password_confirmation: Yup.string()
    .min(8, 'Zu Kurz')
    .max(50, 'Zu Lang')
    .oneOf([Yup.ref('password'), undefined], 'Passwörter stimmen nicht überein')
    .required('Erforderlich'),
});

const ResetForm = ({ onSubmit, email, token }: ResetFormProps) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordShown = useCallback(() => setPasswordShown(!passwordShown), [passwordShown]);

  const initialValues: NewPasswordWithCodeInput = {
    email: email ?? '',
    token: token ?? '',
    password: '',
    password_confirmation: '',
  };

  return (
    <Formik initialValues={initialValues} validationSchema={ResetSchema} onSubmit={onSubmit}>
      {({ isSubmitting, status }) => (
        <Card>
          {status && status.error && (
            <Callout intent={Intent.DANGER} className="mb-4">
              {status.error}
            </Callout>
          )}
          <Form>
            <FormGroup label="Passwort" labelFor="password" name="password">
              <TextInput
                name="password"
                type={passwordShown ? 'text' : 'password'}
                rightElement={
                  <LockButton locked={!passwordShown} disabled={isSubmitting} onClick={togglePasswordShown} />
                }
              />
            </FormGroup>
            <FormGroup label="Passwort wiederholen" labelFor="password_confirmation" name="password_confirmation">
              <TextInput
                name="password_confirmation"
                type={passwordShown ? 'text' : 'password'}
                rightElement={
                  <LockButton locked={!passwordShown} disabled={isSubmitting} onClick={togglePasswordShown} />
                }
              />
            </FormGroup>
            <Field name="email" type="hidden" />
            <Field name="token" type="hidden" />
            <Button type="submit" intent={Intent.SUCCESS} text="Passwort festlegen" loading={isSubmitting} fill />
          </Form>
        </Card>
      )}
    </Formik>
  );
};

export default ResetForm;
