import { Intent, Position, Toaster } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

/** Singleton toaster instance. Create separate instances for different options. */
const AppToaster = Toaster.create({
  className: 'recipe-toaster',
  position: Position.TOP,
});

const success = (message: React.ReactNode) =>
  AppToaster.show({ message, intent: Intent.SUCCESS, icon: IconNames.TICK });

const danger = (message: React.ReactNode) =>
  AppToaster.show({
    message,
    intent: Intent.DANGER,
    icon: IconNames.WARNING_SIGN,
  });

export default {
  show: AppToaster.show,
  success,
  danger,
};
