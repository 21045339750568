import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import PersonTagIcon from '../../../components/PersonTagIcon';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useTagDetailQuery } from '../../../generated/graphql';
import TagDetailMenu from '../components/TagDetailMenu';
import TagEditOverlay from '../components/TagEditOverlay';

const TagDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { tagId } = useParams();
  const { data, loading, error } = useTagDetailQuery({
    variables: {
      id: tagId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.tag) return <NotFoundCard resource="Attribut" />;

  const { tag } = data;

  return (
    <>
      <TagEditOverlay id={tag.id} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{tag.name}</Text>
              <Tag minimal className="ml-2">
                Attribut
              </Tag>
            </>
          }
          rightElement={<TagDetailMenu tag={tag} onEdit={() => setIsEditDialogOpen(true)} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Attribut</PropertyListHeader>
            <PropertyListItem label="Icon">
              <PersonTagIcon tag={tag} />
            </PropertyListItem>
            <PropertyListItem label="Name" value={tag.name} />
            <PropertyListItem
              label="Beschreibung"
              value={tag.description || '—'}
              showValueDisabled={!tag.description}
            />
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

TagDetailPage.fragments = {
  tag: gql`
    fragment TagDetailPage on Tag {
      id
      name
      icon
      intent
      description
    }
  `,
};

export default TagDetailPage;
