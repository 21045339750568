import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import EmployeeEditOverlay from '../components/EmployeeEditOverlay';
import EmployeeEmails from '../components/EmployeeEmails';
import EmployeeInfo from '../components/EmployeeInfo';

const EmployeeDetailPage = () => {
  const [isEditDialogOpen, setIsEditDialogOpen] = useState<boolean>(false);
  const { employeeId } = useParams();

  return (
    <>
      <EmployeeEditOverlay id={employeeId || ''} isOpen={isEditDialogOpen} onClose={() => setIsEditDialogOpen(false)} />
      <EmployeeInfo onEdit={() => setIsEditDialogOpen(true)} />
      <EmployeeEmails />
    </>
  );
};

export default EmployeeDetailPage;
