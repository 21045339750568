import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import NewsletterCreateOverlay from '../components/NewsletterCreateOverlay';
import NewsletterList from '../components/NewsletterList';

const NewsletterListPage = () => {
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Newsletter versendet!');
    history.push(`/newsletters/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <NewsletterCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <Text large className="mr-2">
            Newsletter
          </Text>
        }
        rightElement={
          <>
            <Button
              text="Neuer Newsletter"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              className="ml-2"
              onClick={() => setIsCreateDialogOpen(true)}
            />
          </>
        }
      />
      <NewsletterList />
    </ContentCard>
  );
};

export default NewsletterListPage;
