import { NetworkStatus } from '@apollo/client';
import React from 'react';
import { unserializeFilterValue } from '../../../components/Filter';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import {
  Archived,
  DateTimeRange,
  EventStatus,
  SortOrder,
  Trashed,
  useEventListQuery,
  QueryEventsOrderByColumn,
} from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { DonorHit, ListComponent } from '../../../types';
import { dummyEvents } from '../dummy';
import EventListItem from './EventListItem';

type EventListProps = {
  archive?: boolean;
} & ListComponent;

const EventList = ({ archive, trash, isSelected, onToggle }: EventListProps) => {
  const queryParams = useQueryParams();
  const status = unserializeFilterValue(queryParams.get('status')) as EventStatus | null;
  const donor = unserializeFilterValue(queryParams.get('donor'), 'name') as DonorHit | null;
  const start = unserializeFilterValue(queryParams.get('start')) as DateTimeRange | null;
  const orderBy = unserializeFilterValue(queryParams.get('orderBy')) as string | null;
  const filtersUsed = status !== null && donor !== null && start !== null;

  const { data, loading, error, fetchMore, networkStatus } = useEventListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      status: status || undefined,
      donor: donor?.id || undefined,
      start: start || undefined,
      archived: archive ? Archived.Only : undefined,
      orderByColumn: orderBy ? (orderBy.split('|')[0] as QueryEventsOrderByColumn) : undefined,
      orderByOrder: orderBy ? (orderBy.split('|')[1] as SortOrder) : undefined,
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
      trashed: trash ? Trashed.Only : Trashed.Without,
    },
  });

  const { data: events, paginatorInfo } = data?.events || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive={!onToggle}
      columns={[
        ...(onToggle ? [{ heading: '' }] : []),
        { heading: '' },
        { heading: 'Titel' },
        { heading: 'Datum / Uhrzeit', minWidth: 140 },
        { heading: 'Anmeldungen / Zusagen', minWidth: 250 },
      ]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={filtersUsed}
      empty={!events?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyEvents : events || []).map((item) => (
        <EventListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default EventList;
