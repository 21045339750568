import React from 'react';
import { useParams } from 'react-router-dom';
import PaginatedTable from '../../../components/PaginatedTable';
import { useDonorContactsQuery } from '../../../generated/graphql';
import ContactListItem from '../../contacts/components/ContactListItem';
import { dummyContacts } from '../dummy';

const DonorContactList = () => {
  const { donorId } = useParams();
  const { data, loading, error } = useDonorContactsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: donorId || '',
    },
  });

  const contacts = data?.donor?.contacts || [];

  return (
    <PaginatedTable
      columns={['Name', 'Email', 'Telefon']}
      interactive
      loading={loading}
      hasError={!!error}
      empty={!contacts.length}
    >
      {(loading ? dummyContacts : contacts).map((item) => (
        <ContactListItem key={item.id} item={item} skeleton={loading} />
      ))}
    </PaginatedTable>
  );
};

export default DonorContactList;
