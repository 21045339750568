import { gql } from '@apollo/client';
import SoulForm from './components/SoulForm';
import SoulListItem from './components/SoulListItem';
import SoulInfo from './components/SoulInfo';
import EmailListItem from '../emails/components/EmailListItem';

export const SOUL_LIST_QUERY = gql`
  query SoulList($status: UserStatus, $page: Int, $trashed: Trashed) {
    souls(status: $status, first: 20, page: $page, trashed: $trashed) {
      data {
        ...SoulListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
  ${SoulListItem.fragments.soul}
`;

export const SOUL_DETAIL_QUERY = gql`
  query SoulDetail($id: ID!) {
    soul(id: $id) {
      id
      ...SoulInfo
    }
  }
  ${SoulInfo.fragments.soul}
`;

export const SOUL_EMAILS_QUERY = gql`
  query SoulEmails($id: ID!, $page: Int) {
    soul(id: $id) {
      id
      emails(first: 10, page: $page) {
        data {
          ...EmailListItem
        }
        paginatorInfo {
          currentPage
          hasMorePages
          lastPage
        }
      }
    }
  }
  ${EmailListItem.fragments.emails}
`;

export const SOUL_EDIT_QUERY = gql`
  query SoulEdit($id: ID!) {
    soul(id: $id) {
      ...SoulEditForm
    }
  }
  ${SoulForm.fragments.edit}
`;

export const CREATE_SOUL_MUTATION = gql`
  mutation CreateSoul($input: CreateSoulInput!) {
    createSoul(input: $input) {
      ...SoulInfo
    }
  }
  ${SoulInfo.fragments.soul}
`;

export const UPDATE_SOUL_MUTATION = gql`
  mutation UpdateSoul($input: UpdateSoulInput!) {
    updateSoul(input: $input) {
      ...SoulInfo
    }
  }
  ${SoulInfo.fragments.soul}
`;

export const APPROVE_SOUL_MUTATION = gql`
  mutation ApproveSoul($id: ID!) {
    approveSoul(id: $id) {
      id
      status
    }
  }
`;

export const DENY_SOUL_MUTATION = gql`
  mutation DenySoul($id: ID!) {
    denySoul(id: $id) {
      id
      status
    }
  }
`;

export const ACTIVATE_SOUL_MUTATION = gql`
  mutation ActivateSoul($id: ID!) {
    activateSoul(id: $id) {
      id
      status
    }
  }
`;

export const DEACTIVATE_SOUL_MUTATION = gql`
  mutation DeactivateSoul($id: ID!) {
    deactivateSoul(id: $id) {
      id
      status
    }
  }
`;

export const DELETE_SOUL_MUTATION = gql`
  mutation DeleteSoul($id: ID!) {
    deleteSoul(id: $id) {
      id
    }
  }
`;

export const RESTORE_SOULS_MUTATION = gql`
  mutation RestoreSouls($ids: [ID!]!) {
    restoreSouls(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_SOULS_MUTATION = gql`
  mutation ForceDeleteSouls($ids: [ID!]!) {
    forceDeleteSouls(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_SOUL_TRASH_MUTATION = gql`
  mutation EmptySoulTrash {
    emptySoulTrash {
      id
    }
  }
`;
