import { Intent, Position } from '@blueprintjs/core';
import { DateInput, IDateInputProps } from '@blueprintjs/datetime';
import { format, isValid, isWithinInterval, parse, parseISO } from 'date-fns';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { useFocusOnError } from '../helpers/forms';
import { LocaleUtils } from '../helpers/dateTimeLocaleUtils';
import { defaultMinDate, defaultMaxDate } from '../helpers/dateTimeUtils';

interface IDatePickerInputProps extends Partial<IDateInputProps> {
  name: string;
}

const DatePickerInput = ({ name, ...props }: IDatePickerInputProps) => {
  const [field, meta] = useField(name);
  const fieldRef = useRef<HTMLInputElement>();
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name });

  const parseDate = (str: string) => {
    if (!str) {
      return null;
    }

    const date = parse(str, 'dd.MM.yyyy HH:mm', new Date());
    if (!isValid(date)) {
      return false;
    }

    if (
      !isWithinInterval(date, {
        start: props.minDate || defaultMinDate,
        end: props.maxDate || defaultMaxDate,
      })
    ) {
      return false;
    }

    return date;
  };

  return (
    <DateInput
      {...props}
      localeUtils={LocaleUtils}
      formatDate={(selectedDate) => format(selectedDate, 'dd.MM.yyyy HH:mm')}
      onChange={(selectedDate) =>
        formik.setFieldValue(name, isValid(selectedDate) ? format(selectedDate, 'yyyy-MM-dd HH:mm:ss') : selectedDate)
      }
      showActionsBar
      clearButtonText="Leeren"
      todayButtonText="Heute"
      invalidDateMessage="Ungültiges Datum"
      outOfRangeMessage="Außerhalb des gültigen Zeitraumes"
      parseDate={parseDate}
      placeholder={'TT.MM.JJJJ SS:MM'}
      value={field.value ? parseISO(field.value) : null}
      disabled={props.disabled || formik.isSubmitting}
      inputProps={{
        intent: meta.error && meta.touched ? Intent.DANGER : Intent.NONE,
        inputRef: (ref) => (fieldRef.current = ref || undefined),
      }}
      popoverProps={{
        position: Position.RIGHT,
      }}
    />
  );
};

// Overriding BP DateInput default props
DatePickerInput.defaultProps = {
  locale: 'de',
  minDate: defaultMinDate,
  maxDate: defaultMaxDate,
};

export default DatePickerInput;
