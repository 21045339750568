/** @jsx jsx */
import { Callout, Intent } from '@blueprintjs/core';
import { css, jsx } from '@emotion/core';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProspectListItemFragment, useCompanionMatchingQuery } from '../../../generated/graphql';
import { CompanionMarkerType, LocationMarkerType, SoulMarkerType } from '../types';
import CompanionMatchList from './CompanionMatchList';
import MatchingMap from './MatchingMap';

type MatchingTabProps = {
  soulSignup: ProspectListItemFragment;
  addTeam: (companionSignupId: string, soulSignupId: string, soulBuysTicket: boolean) => void;
};

const MatchingTab = ({ soulSignup, addTeam }: MatchingTabProps) => {
  const { eventId } = useParams();
  const { data, loading, error } = useCompanionMatchingQuery({
    variables: {
      id: eventId || '',
    },
  });
  const [highlightedCompanionId, setHighlightedCompanionId] = useState<string | null>(null);

  if (loading || error || !data || !data.event) return null;

  const companionSignups = data.event.signups
    .filter((signup) => signup.companion && !signup.companion.organisation)
    .sort((a, b) => b.priority - a.priority);

  const soulMarker: SoulMarkerType | null =
    soulSignup.soul!.lat && soulSignup.soul!.lng
      ? {
          type: 'soul',
          lat: soulSignup.soul!.lat,
          lng: soulSignup.soul!.lng,
        }
      : null;

  const locationMarker: LocationMarkerType = {
    type: 'location',
    lat: data.event.location.lat,
    lng: data.event.location.lng,
  };

  const companionMarkers: CompanionMarkerType[] = companionSignups
    .filter((companionSignup) => companionSignup.companion!.lat && companionSignup.companion!.lng)
    .map((companionSignup) => ({
      type: 'companion',
      id: companionSignup.id,
      lat: companionSignup.companion!.lat!,
      lng: companionSignup.companion!.lng!,
    }));

  return (
    <div css={styles.tabPanel}>
      <div css={styles.columContainer}>
        <div css={styles.columnLeft}>
          <CompanionMatchList
            soulSignupId={soulSignup.id}
            soulBuysTicket={!!soulSignup.soul?.meta.buys_ticket}
            companionSignups={companionSignups}
            highlightedCompanionId={highlightedCompanionId}
            onHighlightedCompanionIdChange={setHighlightedCompanionId}
            addTeam={addTeam}
          />
        </div>
        <div css={styles.columnRight}>
          {!!soulMarker ? (
            <MatchingMap
              soulMarker={soulMarker}
              locationMarker={locationMarker}
              companionMarkers={companionMarkers}
              highlightedCompanionId={highlightedCompanionId}
              onHighlightedCompanionIdChange={setHighlightedCompanionId}
            />
          ) : (
            <Callout intent={Intent.WARNING} css={styles.callout}>
              Karte kann nicht angezeigt werden, da keine Adresse für den Genießer hinterlegt ist
            </Callout>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchingTab;

const styles = {
  tabPanel: css`
    margin: 0 -20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
  `,
  columContainer: css`
    display: flex;
    align-items: stretch;
    flex: 1;
    min-height: 0;
  `,
  columnLeft: css`
    flex-grow: 1;
    flex-basis: 50%;
    overflow: auto;
  `,
  columnRight: css`
    flex-grow: 1;
  `,
  callout: css`
    margin: 16px 20px;
    width: auto !important;
  `,
};
