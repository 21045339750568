import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import CategoryCreateOverlay from '../components/CategoryCreateOverlay';
import CategoryList from '../components/CategoryList';

const CategoryListPage = () => {
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Kategorie hinzugefügt!');
    history.push(`/categories/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <CategoryCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <Text large className="mr-2">
            Kategorien
          </Text>
        }
        rightElement={
          <Button
            text="Hinzufügen"
            icon={IconNames.ADD}
            intent={Intent.PRIMARY}
            className="ml-2"
            onClick={() => setIsCreateDialogOpen(true)}
          />
        }
      />
      <CategoryList />
    </ContentCard>
  );
};

export default CategoryListPage;
