import { IProps } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import React from 'react';
import TooltipIcon from '../../../components/TooltipIcon';
import { EventStatus } from '../../../generated/graphql';

type EventSkeleton = {
  archived: boolean;
  status: EventStatus;
};

type EventStatusIconProps = {
  event: EventSkeleton;
  skeleton?: boolean;
} & IProps;

type StatusMap = {
  [key in EventStatus]: { label: string; icon: IconName };
};

const statusMap: StatusMap = {
  [EventStatus.Draft]: {
    label: 'Entwurf',
    icon: IconNames.DOCUMENT,
  },
  [EventStatus.Ready]: {
    label: 'Bereit zur Veröffentlichung',
    icon: IconNames.SAVED,
  },
  [EventStatus.Published]: {
    label: 'Veröffentlicht',
    icon: IconNames.GLOBE_NETWORK,
  },
  [EventStatus.Closed]: {
    label: 'Warte auf Antworten',
    icon: IconNames.HISTORY,
  },
  [EventStatus.Cancelled]: {
    label: 'Abgesagt',
    icon: IconNames.DISABLE,
  },
};

const EventStatusIcon = ({ event, skeleton, ...otherProps }: EventStatusIconProps) =>
  event.archived ? (
    <TooltipIcon {...otherProps} label="Archiviert" icon={IconNames.PROJECTS} skeleton={skeleton} />
  ) : (
    <TooltipIcon
      {...otherProps}
      label={statusMap[event.status].label}
      icon={statusMap[event.status].icon}
      skeleton={skeleton}
    />
  );

export default EventStatusIcon;
