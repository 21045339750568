import { PieDatumWithColor, ResponsivePie } from '@nivo/pie';
import React from 'react';
import { needsDarkText } from '../../../helpers/utils';
import { Colors } from '@blueprintjs/core';

type PieChartProps = {
  data: PieDatumWithColor[];
};

const PieChart = ({ data }: PieChartProps) => (
  <ResponsivePie
    data={data}
    margin={{ top: 40, right: 100, bottom: 40, left: 100 }}
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={2}
    tooltipFormat={(value) => `${Math.round(value * 100)}%`}
    colors={(d) => `${d.color}`}
    radialLabel="label"
    radialLabelsSkipAngle={10}
    radialLabelsTextXOffset={5}
    radialLabelsTextColor={Colors.DARK_GRAY1}
    radialLabelsLinkOffset={0}
    radialLabelsLinkDiagonalLength={5}
    radialLabelsLinkHorizontalLength={10}
    radialLabelsLinkStrokeWidth={2}
    radialLabelsLinkColor={{ from: 'color' }}
    sliceLabel={(d) => `${Math.round(d.value * 100)}%`}
    slicesLabelsSkipAngle={10}
    slicesLabelsTextColor={(d) => (needsDarkText(d.color) ? Colors.DARK_GRAY1 : Colors.WHITE)}
    animate={true}
    motionStiffness={90}
    motionDamping={15}
  />
);

export default PieChart;
