import React from 'react';
import { Classes, Colors, Icon, IconName, Position, Tooltip } from '@blueprintjs/core';
import { gql } from '@apollo/client';
import { Intent, PersonTagIconFragment } from '../generated/graphql';

type PersonTagIconProps = {
  tag: PersonTagIconFragment;
  position?: Position;
  skeleton?: boolean;
};

const PersonTagIcon = ({ tag, position, skeleton }: PersonTagIconProps) => (
  <Tooltip content={tag.name} position={position} disabled={skeleton}>
    <Icon
      icon={tag.icon as IconName}
      intent={tag.intent}
      color={tag.intent && tag.intent !== Intent.None ? undefined : Colors.GRAY1}
      className={[skeleton && Classes.SKELETON, 'mr-1'].join(' ')}
    />
  </Tooltip>
);

PersonTagIcon.fragments = {
  tag: gql`
    fragment PersonTagIcon on Tag {
      id
      name
      icon
      intent
    }
  `,
};

export default PersonTagIcon;
