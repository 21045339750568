import { Button, Elevation, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import Text from '../../../components/Text';
import config from '../../../helpers/config';
import { openTab, getSubdomain } from '../../../helpers/utils';
import DonorCreateOverlay from '../components/DonorCreateOverlay';
import DonorList from '../components/DonorList';

const DonorListPage = () => {
  const history = useHistory();
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const onCreated = (modelId: string) => {
    AppToaster.success('Spender hinzugefügt!');
    history.push(`/donors/${modelId}`);
  };

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <DonorCreateOverlay
        isOpen={isCreateDialogOpen}
        onSuccess={onCreated}
        onClose={() => setIsCreateDialogOpen(false)}
      />
      <ContentCardHeader
        leftElement={
          <Text large className="mr-2">
            Spender
          </Text>
        }
        rightElement={
          <>
            <Button
              text="Hinzufügen"
              icon={IconNames.ADD}
              intent={Intent.PRIMARY}
              className="ml-2"
              onClick={() => setIsCreateDialogOpen(true)}
            />
            <Button
              text="Exportieren"
              icon={IconNames.EXPORT}
              className="ml-2"
              onClick={() => openTab(`${config.baseUrl}/${getSubdomain()}/downloads/donors`)}
            />
          </>
        }
      />
      <DonorList />
    </ContentCard>
  );
};

export default DonorListPage;
