import { gql } from '@apollo/client';
import { Checkbox } from '@blueprintjs/core';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { EventListItemFragment } from '../../../generated/graphql';
import { formatDay, formatTime } from '../../../helpers/dateTimeUtils';
import { noop } from '../../../helpers/utils';
import { useNavigate } from '../../../hooks/useNavigate';
import { ListItemComponent } from '../../../types';
import AttendeeListStatus from '../../attendees/components/AttendeeListStatus';
import EventStatusIcon from './EventStatusIcon';

export type EventListItemProps = {
  item: EventListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const EventListItem = ({ item, skeleton, selected, onToggle }: EventListItemProps) => {
  const navigate = useNavigate();
  return (
    <tr onClick={onToggle || skeleton ? noop : navigate(`/events/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <EventStatusIcon event={item} skeleton={skeleton} />
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{item.name}</Text> <br />
        <Text skeleton={skeleton} small muted>
          {item.location && item.location.name}
        </Text>
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{formatDay(item.start)}</Text> <br />
        <Text skeleton={skeleton} muted>
          {formatTime(item.start)}
          {!!item.end && ` - ${formatTime(item.end)}`}
          {' Uhr'}
        </Text>
      </TableCell>
      <TableCell>
        <AttendeeListStatus event={item} skeleton={skeleton} />
      </TableCell>
    </tr>
  );
};

EventListItem.fragments = {
  event: gql`
    fragment EventListItem on Event {
      id
      name
      status
      start
      end
      available_tickets
      taken_tickets
      archived
      candidateCount @client
      attendeeCount @client
      attendees @client {
        id
        is_candidate
        is_invited
        is_soul
        has_accepted
        has_rejected
      }
      signups {
        id
        is_candidate
        is_invited
        is_soul
        has_accepted
        has_rejected
      }
      location {
        id
        name
        accessible
      }
    }
  `,
};

export default EventListItem;
