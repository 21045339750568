import { LocationState, Path } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const isModifiedEvent = (event: React.MouseEvent) =>
  !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);

export const useNavigate = () => {
  const history = useHistory();

  const navigate = (path: Path, state?: LocationState) => (event: React.MouseEvent) => {
    if (
      !event.defaultPrevented && // onClick prevented default
      event.button === 0 && // ignore everything but left clicks
      !isModifiedEvent(event) // ignore clicks with modifier keys
    ) {
      event.preventDefault();
      history.push(path, state);
    }
  };

  return useCallback(navigate, [history]);
};
