import { Elevation } from '@blueprintjs/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AttendListItem from '../../../components/AttendListItem';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import PaginatedTable from '../../../components/PaginatedTable';
import PaginationBar from '../../../components/PaginationBar';
import Text from '../../../components/Text';
import { useSoulAttendsQuery } from '../../../generated/graphql';
import { dummyAttends } from '../../matching/dummy';

const SoulAttends = () => {
  const [attendPage, setAttendPage] = useState(1);
  const { soulId } = useParams();

  const { data, loading, error } = useSoulAttendsQuery({
    fetchPolicy: 'network-only',
    variables: {
      id: soulId || '',
      first: 10,
      page: attendPage,
    },
  });

  const { data: attends, paginatorInfo } = data?.soul?.attends || {};
  const { currentPage, lastPage, hasMorePages } = paginatorInfo || {};

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader leftElement={<Text large>Teilnahmen</Text>} />
      <PaginatedTable
        columns={['Veranstaltung', 'Begleitung', 'Zeitpunkt']}
        loading={loading}
        hasError={!!error}
        empty={!attends?.length}
      >
        {(loading ? dummyAttends : attends || []).map((attend) => (
          <AttendListItem key={attend.id} attend={attend} skeleton={loading} />
        ))}
      </PaginatedTable>
      <PaginationBar
        loading={loading}
        empty={!attends?.length}
        currentPage={currentPage}
        lastPage={lastPage}
        hasMorePages={hasMorePages}
        onPrev={() => setAttendPage(currentPage && currentPage > 0 ? currentPage - 1 : 1)}
        onNext={() => setAttendPage(currentPage ? currentPage + 1 : 1)}
      />
    </ContentCard>
  );
};

export default SoulAttends;
