import { Button, ButtonGroup, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import {
  SoulInfoFragment,
  useActivateSoulMutation,
  useApproveSoulMutation,
  useDeactivateSoulMutation,
  useDeleteSoulMutation,
  useDenySoulMutation,
  useSendResetLinkForUserMutation,
  UserStatus,
} from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';

type SoulDetailMenuProps = {
  soul: SoulInfoFragment;
  onEdit: () => void;
};

const SoulDetailMenu = ({ soul, onEdit }: SoulDetailMenuProps) => {
  const history = useHistory();
  const options = {
    variables: {
      id: soul.id,
    },
    onError: showErrorMessage,
  };

  const [approveSoul, { loading: approving }] = useApproveSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${soul.display_name} wurde freigeschaltet`),
  });
  const [denySoul, { loading: denying }] = useDenySoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${soul.display_name} wurde abgelehnt`),
  });
  const [activateSoul, { loading: activating }] = useActivateSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${soul.display_name} wurde AKTIV geschaltet`),
  });
  const [deactivateSoul, { loading: deactivating }] = useDeactivateSoulMutation({
    ...options,
    onCompleted: () => AppToaster.success(`${soul.display_name} wurde PASSIV geschaltet`),
  });
  const [deleteSoul, { loading: deleting }] = useDeleteSoulMutation({
    ...options,
    onCompleted: () => {
      AppToaster.success(`${soul.display_name} wurde in den Papierkorb gelegt`);
      history.goBack();
    },
  });
  const [sendPasswordResetEmail, { loading: sendingPasswordResetEmail }] = useSendResetLinkForUserMutation({
    ...options,
    onCompleted: () =>
      AppToaster.success(`Anweisungen zum Zurücksetzen des Passwortes wurden an ${soul.display_name} verschickt!`),
  });

  if (soul.status === UserStatus.New) {
    return (
      <>
        <ButtonGroup className="ml-2">
          <Button text="Bestätigen" icon={IconNames.TICK} onClick={approveSoul as any} loading={approving} />
          <Button text="Ablehnen" icon={IconNames.CROSS} onClick={denySoul as any} loading={denying} />
        </ButtonGroup>
        <Popover
          position={Position.BOTTOM}
          content={
            <Menu>
              <MenuItem text="Bearbeiten" icon={IconNames.EDIT} onClick={onEdit} />
              <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteSoul as any} />
            </Menu>
          }
        >
          <Button icon={IconNames.MORE} className="ml-2" />
        </Popover>
      </>
    );
  }

  return (
    <>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            {soul.status === UserStatus.Active ? (
              <MenuItem text="Passiv schalten" icon={IconNames.OFFLINE} onClick={deactivateSoul as any} />
            ) : (
              <MenuItem text="Aktiv schalten" icon={IconNames.OFFLINE} onClick={activateSoul as any} />
            )}
            <MenuItem text="Passwort zurücksetzen" icon={IconNames.KEY} onClick={sendPasswordResetEmail as any} />
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteSoul as any} />
          </Menu>
        }
      >
        <Button
          icon={IconNames.MORE}
          className="ml-2"
          loading={activating || deactivating || deleting || sendingPasswordResetEmail}
        />
      </Popover>
    </>
  );
};

export default SoulDetailMenu;
