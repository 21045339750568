import { Icon, InputGroup, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';
import { ItemPredicate, ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { FieldArray, getIn } from 'formik';
import { gql } from '@apollo/client';
import React from 'react';
import { PersonTagMultiSelectFragment, useTagsQuery } from '../generated/graphql';

type Tag = PersonTagMultiSelectFragment;

type PersonTagMultiSelectProps = {
  name: string;
};

const renderTag = (tag: Tag) => tag.name;

const TagMultiSelect = MultiSelect.ofType<Tag>();

const PersonTagMultiSelect = (props: PersonTagMultiSelectProps) => {
  const { data, loading, error } = useTagsQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <FieldArray name={props.name}>
      {({ form, push, remove }) => {
        const selectedTags: Tag[] = getIn(form.values, props.name) || [];

        const isTagSelected = (tag: Tag) => getSelectedTagIndex(tag) !== -1;

        const getSelectedTagIndex = (tag: Tag) => selectedTags.findIndex((selectedTag) => selectedTag.id === tag.id);

        const filterTags: ItemPredicate<Tag> = (query, tag) => tag.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;

        // NOTE: not using Films.itemRenderer here so we can set icons.
        const renderTagItem: ItemRenderer<Tag> = (tag, { modifiers, handleClick }) => {
          if (!modifiers.matchesPredicate) {
            return null;
          }
          return (
            <MenuItem
              active={modifiers.active}
              icon={isTagSelected(tag) ? 'tick' : 'blank'}
              key={tag.id}
              labelElement={<Icon icon={tag.icon as IconName} intent={tag.intent} />}
              onClick={handleClick}
              text={tag.name}
              shouldDismissPopover={false}
            />
          );
        };

        const handleTagSelect = (tag: Tag) => {
          if (!isTagSelected(tag)) {
            push(tag);
          } else {
            remove(getSelectedTagIndex(tag));
          }
        };

        const handleTagRemove = (_tag: string, index: number) => {
          remove(index);
        };

        if (loading) {
          return <InputGroup rightElement={<Spinner size={16} />} value="Lade..." disabled />;
        }

        if (error || !data || !data.tags) {
          return (
            <InputGroup leftIcon={<Icon icon={IconNames.ERROR} intent={Intent.DANGER} />} value="Fehler" disabled />
          );
        }

        return (
          <TagMultiSelect
            fill
            itemRenderer={renderTagItem}
            itemPredicate={filterTags}
            tagRenderer={renderTag}
            resetOnSelect
            itemsEqual="id"
            items={data.tags}
            selectedItems={selectedTags}
            onItemSelect={handleTagSelect}
            noResults={<MenuItem disabled={true} text="Keine Resultate." />}
            popoverProps={{ minimal: true, boundary: 'viewport' }}
            tagInputProps={{
              onRemove: handleTagRemove,
              disabled: form.isSubmitting,
            }}
          />
        );
      }}
    </FieldArray>
  );
};

PersonTagMultiSelect.fragments = {
  tags: gql`
    fragment PersonTagMultiSelect on Tag {
      id
      name
      icon
      intent
    }
  `,
};

export default PersonTagMultiSelect;
