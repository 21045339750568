import React from 'react';
import { Classes, Colors, Tag, IProps } from '@blueprintjs/core';
import { needsDarkText } from '../helpers/utils';

type CategorySkeleton = {
  name: string;
  color: string;
};

type CategoryTagProps = {
  category: CategorySkeleton;
  skeleton?: boolean;
} & IProps;

const CategoryTag = ({ category, skeleton, className }: CategoryTagProps) => (
  <Tag
    className={[className, skeleton && Classes.SKELETON].join(' ')}
    style={{
      backgroundColor: category.color,
      color: needsDarkText(category.color) ? Colors.DARK_GRAY1 : Colors.WHITE,
    }}
  >
    {category.name}
  </Tag>
);

export default CategoryTag;
