import { gql } from '@apollo/client';
import ContactSelect from '../events/components/ContactSelect';
import LocationSelect from '../events/components/LocationSelect';
import DonorForm from './components/DonorForm';
import DonorListItem from './components/DonorListItem';
import DonorInfo from './components/DonorInfo';
import ContactListItem from '../contacts/components/ContactListItem';
import LocationListItem from '../locations/components/LocationListItem';

export const DONOR_LIST_QUERY = gql`
  query DonorList($page: Int, $trashed: Trashed) {
    donors(first: 20, page: $page, trashed: $trashed) {
      data {
        ...DonorListItem
      }
      paginatorInfo {
        currentPage
        lastPage
        hasMorePages
      }
    }
  }
  ${DonorListItem.fragments.donor}
`;

export const DONOR_DETAIL_QUERY = gql`
  query DonorDetail($id: ID!) {
    donor(id: $id) {
      id
      ...DonorInfo
    }
  }
  ${DonorInfo.fragments.donor}
`;

export const DONOR_CONTACTS_QUERY = gql`
  query DonorContacts($id: ID!) {
    donor(id: $id) {
      id
      contacts {
        ...ContactListItem
      }
    }
  }
  ${ContactListItem.fragments.contact}
`;

export const DONOR_CONTACT_SELECT_QUERY = gql`
  query DonorContactSelect($id: ID!) {
    donor(id: $id) {
      id
      contacts {
        ...ContactSelect
      }
    }
  }
  ${ContactSelect.fragments.contacts}
`;

export const DONOR_LOCATIONS_QUERY = gql`
  query DonorLocations($id: ID!) {
    donor(id: $id) {
      id
      locations {
        ...LocationListItem
      }
    }
  }
  ${LocationListItem.fragments.location}
`;

export const DONOR_LOCATION_SELECT_QUERY = gql`
  query DonorLocationSelect($id: ID!) {
    donor(id: $id) {
      id
      locations {
        ...LocationSelect
      }
    }
  }
  ${LocationSelect.fragments.locations}
`;

export const CREATE_DONOR_MUTATION = gql`
  mutation CreateDonor($input: CreateDonorInput!) {
    createDonor(input: $input) {
      ...DonorInfo
    }
  }
  ${DonorInfo.fragments.donor}
`;

export const DONOR_EDIT_QUERY = gql`
  query DonorEdit($id: ID!) {
    donor(id: $id) {
      id
      ...DonorEditForm
    }
  }
  ${DonorForm.fragments.edit}
`;

export const UPDATE_DONOR_MUTATION = gql`
  mutation UpdateDonor($input: UpdateDonorInput!) {
    updateDonor(input: $input) {
      ...DonorInfo
    }
  }
  ${DonorInfo.fragments.donor}
`;

export const SET_DONOR_LOGO_MUTATION = gql`
  mutation SetDonorLogo($input: SetDonorLogoInput!) {
    setDonorLogo(input: $input) {
      id
      logo {
        name
        thumb_url
      }
    }
  }
`;

export const DELETE_DONOR_MUTATION = gql`
  mutation DeleteDonor($id: ID!) {
    deleteDonor(id: $id) {
      id
    }
  }
`;

export const RESTORE_DONORS_MUTATION = gql`
  mutation RestoreDonors($ids: [ID!]!) {
    restoreDonors(ids: $ids) {
      id
    }
  }
`;

export const FORCE_DELETE_DONORS_MUTATION = gql`
  mutation ForceDeleteDonors($ids: [ID!]!) {
    forceDeleteDonors(ids: $ids) {
      id
    }
  }
`;

export const EMPTY_DONOR_TRASH_MUTATION = gql`
  mutation EmptyDonorTrash {
    emptyDonorTrash {
      id
    }
  }
`;
