import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import { PropertyList, PropertyListHeader, PropertyListItem } from '../../../components/PropertyList';
import RawHTML from '../../../components/RawHTML';
import Text from '../../../components/Text';
import { NewsletterStatus, useNewsletterDetailQuery } from '../../../generated/graphql';
import { getMailcoachSegmentText, getNewsletterStatusText } from '../../../helpers/dataUtils';
import { formatDateTime } from '../../../helpers/dateTimeUtils';
import NewsletterDetailMenu from '../components/NewsletterDetailMenu';

const NewsletterDetailPage = () => {
  const { newsletterId } = useParams();
  const { data, loading, error } = useNewsletterDetailQuery({
    variables: {
      id: newsletterId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.newsletter) return <NotFoundCard resource="Newsletter" />;

  const { newsletter } = data;

  return (
    <>
      <ContentCard elevation={Elevation.TWO} className="mb-5">
        <ContentCardHeader
          leftElement={
            <>
              <Text large>{newsletter.subject}</Text>
              <Tag minimal className="ml-2">
                Newsletter
              </Tag>
            </>
          }
          rightElement={<NewsletterDetailMenu newsletter={newsletter} />}
        />
        <div className="flex py-4 px-5">
          <PropertyList>
            <PropertyListHeader>Details</PropertyListHeader>
            <PropertyListItem label="Empfänger" value={getMailcoachSegmentText(newsletter.segment)} />
            <PropertyListItem label="Gesendet">
              {newsletter.status === NewsletterStatus.Sent ? (
                formatDateTime(newsletter.sent_at!, 'dd.MM.yyyy HH:mm')
              ) : (
                <Text muted>{getNewsletterStatusText(newsletter.status)} </Text>
              )}
            </PropertyListItem>
            <PropertyListItem label="Inhalt">
              <RawHTML>{newsletter.body}</RawHTML>
            </PropertyListItem>
          </PropertyList>
        </div>
      </ContentCard>
    </>
  );
};

NewsletterDetailPage.fragments = {
  newsletter: gql`
    fragment NewsletterDetailPage on Newsletter {
      id
      status
      subject
      body
      sent_at
      segment
      events {
        id
        name
      }
    }
  `,
};

export default NewsletterDetailPage;
