import { gql } from '@apollo/client';
import { Elevation, Icon, Tag } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import Text from '../../../components/Text';
import { useLocationDetailQuery } from '../../../generated/graphql';
import LocationDetailMenu from './LocationDetailMenu';

type LocationInfoProps = {
  onEdit: () => void;
};

const LocationInfo = ({ onEdit }: LocationInfoProps) => {
  const { locationId } = useParams();
  const { data, loading, error } = useLocationDetailQuery({
    variables: {
      id: locationId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data?.location) return <NotFoundCard resource="Lokation" />;

  const { location } = data;

  const address: string | null =
    location.street && location.postal_code && location.city
      ? `${location.street}, ${location.postal_code} ${location.city}`
      : null;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={
          <>
            <Text large>{location.name}</Text>
            <Tag minimal className="ml-2">
              Lokation
            </Tag>
          </>
        }
        rightElement={<LocationDetailMenu location={location} onEdit={onEdit} />}
      />
      <div className="flex py-4 px-5">
        <PropertyList>
          <PropertyListHeader>Details</PropertyListHeader>
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Lokation Info</PropertyListHeader>
          <PropertyListItem label="Barrierefrei">
            <Icon icon={location.accessible ? IconNames.TICK : IconNames.CROSS} />
          </PropertyListItem>
          <PropertyListItem label="Treffpunkt" value={location.meeting_point} />
          <PropertyListItem
            label="Öff. Nahverkehr"
            value={location.public_transport || '—'}
            showValueDisabled={!location.public_transport}
          />
        </PropertyList>
      </div>
    </ContentCard>
  );
};

LocationInfo.fragments = {
  location: gql`
    fragment LocationInfo on Location {
      id
      name
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
    }
  `,
};

export default LocationInfo;
