import { gql } from '@apollo/client';
import React from 'react';
import ResourceLink from '../../../components/ResourceLink';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { DonorListItemFragment } from '../../../generated/graphql';
import { ListItemComponent } from '../../../types';
import { useNavigate } from '../../../hooks/useNavigate';
import { noop } from '../../../helpers/utils';
import { Checkbox } from '@blueprintjs/core';

export type DonorListProps = {
  item: DonorListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const DonorListItem = ({ item, skeleton, selected, onToggle }: DonorListProps) => {
  const navigate = useNavigate();
  return (
    <tr onClick={onToggle || skeleton ? noop : navigate(`/donors/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <Text skeleton={skeleton}>{item.name}</Text>
      </TableCell>
      <TableCell className="space-x-1">
        {item.contacts.map((contact) => (
          <ResourceLink
            key={contact.id}
            path={`/contacts/${contact.id}`}
            text={contact.display_name}
            skeleton={skeleton}
          />
        ))}
      </TableCell>
    </tr>
  );
};

DonorListItem.fragments = {
  donor: gql`
    fragment DonorListItem on Donor {
      id
      name
      contacts {
        id
        display_name
      }
    }
  `,
};

export default DonorListItem;
