import { gql } from '@apollo/client';
import React from 'react';
import CategoryTag from '../../../components/CategoryTag';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { CategoryListItemFragment } from '../../../generated/graphql';
import { useNavigate } from '../../../hooks/useNavigate';
import { noop } from '../../../helpers/utils';
import { Checkbox } from '@blueprintjs/core';
import { ListItemComponent } from '../../../types';

export type CategoryListProps = {
  item: CategoryListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const CategoryListItem = ({ item, skeleton, selected, onToggle }: CategoryListProps) => {
  const navigate = useNavigate();
  return (
    <tr onClick={onToggle || skeleton ? noop : navigate(`/categories/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <CategoryTag category={item} skeleton={skeleton} className="mr-1" />
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{item.description}</Text>
      </TableCell>
    </tr>
  );
};

CategoryListItem.fragments = {
  category: gql`
    fragment CategoryListItem on Category {
      id
      name
      description
      color
    }
  `,
};

export default CategoryListItem;
