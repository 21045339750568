import { Button, Classes, Divider, FormGroup, IconName, InputGroup, Intent, Popover, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import {
  CompositeDecorator,
  ContentBlock,
  ContentState,
  DraftEditorCommand,
  DraftHandleValue,
  Editor,
  EditorState,
  RichUtils,
  RawDraftEntity,
} from 'draft-js';
import 'draft-js/dist/Draft.css';
import { useField, useFormikContext } from 'formik';
import React, { FormEvent, KeyboardEvent, MouseEvent, ReactNode, SyntheticEvent, useRef, useState } from 'react';
import { useFocusOnError } from '../helpers/forms';

type RichTextInputProps = {
  name: string;
};

type StyleButtonProps = {
  active?: boolean;
  icon: IconName;
  label: string;
  onClick: (event: MouseEvent<HTMLElement>) => void;
};

type StyleType = {
  icon: IconName;
  style: string;
  label: string;
};

type StyleControlsProps = {
  editorState: EditorState;
  onToggleBlock: (style: string) => void;
  onToggleInline: (style: string) => void;
  onToggleLink: (newEditorState: EditorState, entityKey: string | null) => void;
};

type LinkProps = {
  contentState: ContentState;
  entityKey: string;
  children: ReactNode;
};

type LinkButtonProps = {
  editorState: EditorState;
  onToggle: (newEditorState: EditorState, entityKey: string | null) => void;
};

const RichTextInput = ({ name }: RichTextInputProps) => {
  const [{ value: editorState }] = useField<EditorState>(name);
  const fieldRef = useRef<Editor>(null);
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name });

  const onChange = (editorState: EditorState) => {
    formik.setFieldValue(name, editorState);
  };

  const focus = () => fieldRef.current?.focus();

  const handleKeyCommand = (
    command: DraftEditorCommand,
    editorState: EditorState,
    eventTimeStamp: number,
  ): DraftHandleValue => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const onTab = (e: React.KeyboardEvent<{}>) => {
    onChange(RichUtils.onTab(e, editorState, 4));
    setImmediate(focus);
  };

  const toggleBlockType = (blockType: string) => {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
    setImmediate(focus);
  };

  const toggleInlineStyle = (inlineStyle: string) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
    setImmediate(focus);
  };

  const toggleLink = (newEditorState: EditorState, entityKey: string | null) => {
    onChange(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));
    setImmediate(focus);
  };

  return (
    <div className="RichEditor-root">
      <StyleControls
        editorState={editorState}
        onToggleBlock={toggleBlockType}
        onToggleInline={toggleInlineStyle}
        onToggleLink={toggleLink}
      />
      <div className={[Classes.INPUT, 'h-auto p-2 cursor-text'].join(' ')} style={{ minHeight: 120 }} onClick={focus}>
        <Editor
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={onChange}
          onTab={onTab} // Is deprecated but currently still working
          ref={fieldRef}
        />
      </div>
    </div>
  );
};

export default RichTextInput;

export const entityToHTML = (entity: RawDraftEntity, originalText: string) => {
  if (entity.type === 'LINK') {
    return <a href={entity.data.url}>{originalText}</a>;
  }
  return originalText;
};

export const htmlToEntity = (nodeName: string, node: any, createEntity: any) => {
  if (nodeName === 'a') {
    return createEntity('LINK', 'MUTABLE', { url: node.href });
  }
};

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  }, callback);
};

const Link = ({ contentState, entityKey, children }: LinkProps) => {
  const { url } = contentState.getEntity(entityKey).getData();
  return <a href={url}>{children}</a>;
};

export const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
]);

const getBlockStyle = (block: ContentBlock) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    default:
      return '';
  }
};

const StyleButton = ({ active, label, onClick, icon }: StyleButtonProps) => (
  <Tooltip content={label}>
    <Button active={active} icon={icon} onClick={onClick} minimal small style={{ marginRight: 5 }} />
  </Tooltip>
);

const LinkButton = ({ editorState, onToggle }: LinkButtonProps) => {
  const [isUrlInputOpen, setIsUrlInputOpen] = useState(false);
  const [url, setUrl] = useState('');
  const inputRef = useRef<HTMLInputElement>();

  const openUrlInput = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }
      setIsUrlInputOpen(true);
      setUrl(url);
      setImmediate(() => inputRef?.current?.focus());
    }
  };

  const confirmLink = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();

    const newUrl = url.trim().indexOf('http') === 0 ? url.trim() : `http://${url.trim()}`;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: newUrl });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    setIsUrlInputOpen(false);
    setUrl('');
    onToggle(newEditorState, entityKey);
  };

  const onLinkInputKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.which === 13) {
      confirmLink(e);
    }
  };

  // Currently, we have no "unlink" icon so we'll tell people to just delete the text
  // to get rid of the link
  // const removeLink = (e: MouseEvent<HTMLElement>) => {
  //   e.preventDefault();
  //   const selection = editorState.getSelection();
  //   if (!selection.isCollapsed()) {
  //     onToggle(editorState, null);
  //   }
  // };

  return (
    <Popover
      isOpen={isUrlInputOpen}
      onClose={(e) => {
        e?.stopPropagation();
        setIsUrlInputOpen(false);
      }}
      captureDismiss
    >
      <StyleButton key="Link" label="Link" onClick={openUrlInput} icon={IconNames.LINK} />
      <div className="py-4 px-5">
        <FormGroup label="Link-Adresse" helperText="z.B. www.google.de">
          <InputGroup
            value={url}
            inputRef={(ref) => (inputRef.current = ref || undefined)} // Blueprint still uses old callback refs
            onChange={({ currentTarget }: FormEvent<HTMLInputElement>) => setUrl(currentTarget.value)}
            onKeyDown={onLinkInputKeyDown}
            rightElement={<Button text="Ok" minimal small intent={Intent.PRIMARY} onClick={confirmLink} />}
          />
        </FormGroup>
      </div>
    </Popover>
  );
};

const INLINE_STYLES: StyleType[] = [
  { label: 'Fett', icon: IconNames.BOLD, style: 'BOLD' },
  { label: 'Kursiv', icon: IconNames.ITALIC, style: 'ITALIC' },
  { label: 'Unterstrichen', icon: IconNames.UNDERLINE, style: 'UNDERLINE' },
];

const BLOCK_TYPES: StyleType[] = [
  { label: 'Zitat', icon: IconNames.CITATION, style: 'blockquote' },
  { label: 'Liste', icon: IconNames.PROPERTIES, style: 'unordered-list-item' },
  { label: 'Nummerierte Liste', icon: IconNames.NUMBERED_LIST, style: 'ordered-list-item' },
];

const StyleControls = ({ editorState, onToggleBlock, onToggleInline, onToggleLink }: StyleControlsProps) => {
  const currentStyle = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

  return (
    <div className="flex flex-row py-1">
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onClick={() => onToggleInline(type.style)}
          icon={type.icon}
        />
      ))}
      <Divider />
      {BLOCK_TYPES.map((type) => (
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onClick={() => onToggleBlock(type.style)}
          icon={type.icon}
        />
      ))}
      <LinkButton editorState={editorState} onToggle={onToggleLink} />
    </div>
  );
};
