import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './components/App';
import './tailwind.generated.css';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import config from './helpers/config';

Sentry.init({ dsn: 'https://3b27c399aded45f6a40b014a9278ae19@o59970.ingest.sentry.io/1786888', ...config.sentry });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
