import { Button, IButtonProps, Popover } from '@blueprintjs/core';
import { useField, useFormikContext } from 'formik';
import React, { useRef } from 'react';
import { ChromePicker } from 'react-color';
import { useFocusOnError } from '../helpers/forms';

interface ColorInputProps extends IButtonProps {
  name: string;
}

const ColorInput = ({ name, ...props }: ColorInputProps) => {
  const [field] = useField(name);
  const fieldRef = useRef<ChromePicker>(null);
  const formik = useFormikContext<any>();
  useFocusOnError({ fieldRef, name });

  return (
    <Popover disabled={props.disabled || formik.isSubmitting}>
      <Button
        {...props}
        minimal
        disabled={props.disabled || formik.isSubmitting}
        style={{ backgroundColor: field.value }}
      />
      <ChromePicker
        ref={fieldRef}
        color={field.value}
        onChangeComplete={(color) => formik.setFieldValue(name, color.hex)}
        disableAlpha
        styles={{
          default: {
            picker: {
              boxShadow: 'none !important',
            },
          },
        }}
      />
    </Popover>
  );
};

export default ColorInput;
