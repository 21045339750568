import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import { CategoryDetailPageFragment, useDeleteCategoryMutation } from '../../../generated/graphql';
import { showErrorMessage } from '../../../helpers/graphql';

type CategoryDetailMenuProps = {
  category: CategoryDetailPageFragment;
  onEdit: () => void;
};

const CategoryDetailMenu = ({ category, onEdit }: CategoryDetailMenuProps) => {
  const history = useHistory();
  const [deleteCategory, { loading: deleting }] = useDeleteCategoryMutation({
    variables: {
      id: category.id,
    },
    onCompleted: () => {
      AppToaster.success(`${category.name}  wurde in den Papierkorb gelegt`);
      history.goBack();
    },
    onError: showErrorMessage,
  });

  return (
    <>
      <Button text="Bearbeiten" icon={IconNames.EDIT} className="ml-2" onClick={onEdit} />
      <Popover
        position={Position.BOTTOM}
        content={
          <Menu>
            <MenuItem text="In den Papierkorb" icon={IconNames.TRASH} onClick={deleteCategory as any} />
          </Menu>
        }
      >
        <Button icon={IconNames.MORE} className="ml-2" loading={deleting} />
      </Popover>
    </>
  );
};

export default CategoryDetailMenu;
