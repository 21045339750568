import { NetworkStatus } from '@apollo/client';
import React from 'react';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import { Trashed, useCompanionListQuery, UserStatus } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { dummyCompanions } from '../dummy';
import CompanionListItem from './CompanionListItem';

type SoulListProps = {
  isSelected?: (id: string) => boolean;
  onToggle?: (id: string) => void;
  trash?: boolean;
};

const CompanionList = ({ isSelected, onToggle, trash }: SoulListProps) => {
  const queryParams = useQueryParams();
  const status = (queryParams.get('status') as UserStatus) || UserStatus.Active;
  const filters = queryParams.get('filters');

  // infinite flag is a hack to make the cache's "FielPolicy" read function
  // return all items
  // @ts-ignore
  const variables = trash
    ? {
        trashed: Trashed.Only,
        infinite: true,
      }
    : {
        status,
        trashed: Trashed.Without,
        infinite: true,
      };

  const { data, loading, error, fetchMore, networkStatus } = useCompanionListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables,
  });

  const { data: companions, paginatorInfo } = data?.companions || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive={!onToggle}
      columns={[
        ...(isSelected && onToggle ? [{ heading: '' }] : []),
        { heading: 'Name' },
        { heading: 'Besonderheiten' },
        { heading: 'Telefon' },
        { heading: 'Email' },
      ]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={!!filters}
      empty={!companions?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyCompanions : companions || []).map((item) => (
        <CompanionListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default CompanionList;
