/** @jsx jsx */
import { NetworkStatus } from '@apollo/client';
import { jsx } from '@emotion/core';
import InfiniteScrollTable from '../../../components/InfiniteScrollTable';
import { Trashed, useDonorListQuery } from '../../../generated/graphql';
import useQueryParams from '../../../hooks/useQueryParams';
import { ListComponent } from '../../../types';
import { dummyDonors } from '../dummy';
import DonorListItem from './DonorListItem';

const DonorList = ({ trash, isSelected, onToggle }: ListComponent) => {
  const queryParams = useQueryParams();
  const filters = queryParams.get('filters');

  const { data, loading, error, fetchMore, networkStatus } = useDonorListQuery({
    // It is just too complicated to update cached queries after
    // mutations have succeeded
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      // infinite flag is a hack to make the cache's "FielPolicy" read function
      // return all items
      // @ts-ignore
      infinite: true,
      trashed: trash ? Trashed.Only : Trashed.Without,
    },
  });

  const { data: donors, paginatorInfo } = data?.donors || {};
  const { currentPage, hasMorePages } = paginatorInfo || {};
  const loadingMore = networkStatus === NetworkStatus.fetchMore;

  return (
    <InfiniteScrollTable
      interactive={!onToggle}
      columns={[...(onToggle ? [{ heading: '' }] : []), { heading: 'Name' }, { heading: 'Ansprechpartner' }]}
      loading={loading}
      loadingMore={loadingMore}
      hasFilters={!!filters}
      empty={!donors?.length}
      hasMorePages={!!hasMorePages}
      hasError={!!error}
      onLoadMore={() =>
        fetchMore({
          variables: {
            page: (currentPage || 1) + 1,
          },
        })
      }
    >
      {(loading && !loadingMore ? dummyDonors : donors || []).map((item) => (
        <DonorListItem
          key={item.id}
          item={item}
          skeleton={loading && !loadingMore}
          selected={isSelected && isSelected(item.id)}
          onToggle={onToggle}
        />
      ))}
    </InfiniteScrollTable>
  );
};

export default DonorList;
