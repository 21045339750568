import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AllotmentDetailPage from '../modules/allotments/pages/AllotmentDetailPage';
import AllotmentListPage from '../modules/allotments/pages/AllotmentListPage';
import CategoryDetailPage from '../modules/categories/pages/CategoryDetailPage';
import CategoryListPage from '../modules/categories/pages/CategoryListPage';
import CompanionDetailPage from '../modules/companions/pages/CompanionDetailPage';
import CompanionListPage from '../modules/companions/pages/CompanionListPage';
import ContactDetailPage from '../modules/contacts/pages/ContactDetailPage';
import DonorDetailPage from '../modules/donors/pages/DonorDetailPage';
import DonorListPage from '../modules/donors/pages/DonorListPage';
import EmployeeDetailPage from '../modules/employees/pages/EmployeeDetailPage';
import EmployeeListPage from '../modules/employees/pages/EmployeeListPage';
import EventDetailPage from '../modules/events/pages/EventDetailPage';
import EventListPage from '../modules/events/pages/EventListPage';
import LocationDetailPage from '../modules/locations/pages/LocationDetailPage';
import LocationListPage from '../modules/locations/pages/LocationListPage';
import MatchingPage from '../modules/matching/pages/MatchingPage';
import NewsletterDetailPage from '../modules/newsletters/pages/NewsletterDetailPage';
import NewsletterListPage from '../modules/newsletters/pages/NewsletterListPage';
import OrganisationDetailPage from '../modules/organisations/pages/OrganisationDetailPage';
import OrganisationListPage from '../modules/organisations/pages/OrganisationListPage';
import RoleDetailPage from '../modules/roles/pages/RoleDetailPage';
import RoleListPage from '../modules/roles/pages/RoleListPage';
import SoulDetailPage from '../modules/souls/pages/SoulDetailPage';
import SoulListPage from '../modules/souls/pages/SoulListPage';
import SettingsDetailPage from '../modules/settings/pages/SettingsDetailPage';
import StatsDetailPage from '../modules/stats/pages/StatsDetailPage';
import NewsletterArchiveListPage from '../modules/newsletters/pages/NewsletterArchiveListPage';
import EventArchiveListPage from '../modules/events/pages/EventArchiveListPage';
import TagListPage from '../modules/tags/pages/TagListPage';
import TagDetailPage from '../modules/tags/pages/TagDetailPage';
import TrashPage from '../modules/trash/pages/TrashPage';

const MainContent = () => (
  <div
    style={{
      marginTop: 55,
    }}
  >
    <Switch>
      <Route path="/events" exact component={EventListPage} />
      <Route path="/events/archive" exact component={EventArchiveListPage} />
      <Route path="/events/:eventId" exact component={EventDetailPage} />
      <Route path="/events/:eventId/matching" exact component={MatchingPage} />
      <Route path="/categories" exact component={CategoryListPage} />
      <Route path="/categories/:categoryId" exact component={CategoryDetailPage} />
      <Route path="/allotments" exact component={AllotmentListPage} />
      <Route path="/allotments/:allotmentId" exact component={AllotmentDetailPage} />
      <Route path="/souls" exact component={SoulListPage} />
      <Route path="/souls/:soulId" exact component={SoulDetailPage} />
      <Route path="/companions" exact component={CompanionListPage} />
      <Route path="/companions/:companionId" component={CompanionDetailPage} />
      <Route path="/donors" exact component={DonorListPage} />
      <Route path="/donors/:donorId" exact component={DonorDetailPage} />
      <Route path="/contacts/:contactId" exact component={ContactDetailPage} />
      <Route path="/locations" exact component={LocationListPage} />
      <Route path="/locations/:locationId" exact component={LocationDetailPage} />
      <Route path="/organisations" exact component={OrganisationListPage} />
      <Route path="/organisations/:organisationId" exact component={OrganisationDetailPage} />
      <Route path="/tags" exact component={TagListPage} />
      <Route path="/tags/:tagId" exact component={TagDetailPage} />
      <Route path="/employees" exact component={EmployeeListPage} />
      <Route path="/employees/:employeeId" exact component={EmployeeDetailPage} />
      <Route path="/roles" exact component={RoleListPage} />
      <Route path="/roles/:roleId" exact component={RoleDetailPage} />
      <Route path="/newsletters" exact component={NewsletterListPage} />
      <Route path="/newsletters/archive" exact component={NewsletterArchiveListPage} />
      <Route path="/newsletters/:newsletterId" exact component={NewsletterDetailPage} />
      <Route path="/settings" exact component={SettingsDetailPage} />
      <Route path="/stats" exact component={StatsDetailPage} />
      <Route path="/trash" exact component={TrashPage} />
      <Route path="*">
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  </div>
);

export default MainContent;
