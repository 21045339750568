import React from 'react';
import { Classes, Intent, Position, Tag, Tooltip } from '@blueprintjs/core';

type TooltipTagProps = {
  label: string;
  tooltip: string;
  intent?: Intent;
  minimal?: boolean;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
};

const TooltipTag = ({
  label,
  tooltip,
  minimal,
  marginRight,
  marginLeft,
  intent,
  position,
  skeleton,
}: TooltipTagProps) => (
  <Tooltip content={tooltip} position={position} disabled={skeleton}>
    <Tag
      intent={intent}
      minimal={minimal}
      className={[skeleton && Classes.SKELETON, marginRight && 'mr-1', marginLeft && 'ml-1'].join(' ')}
    >
      {label}
    </Tag>
  </Tooltip>
);

export default TooltipTag;
