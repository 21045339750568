import { gql } from '@apollo/client';
import React from 'react';
import TableCell from '../../../components/TableCell';
import Text from '../../../components/Text';
import { LocationListItemFragment } from '../../../generated/graphql';
import { ListItemComponent } from '../../../types';
import { noop } from '../../../helpers/utils';
import { Checkbox } from '@blueprintjs/core';
import { useNavigate } from '../../../hooks/useNavigate';

export type LocationListProps = {
  item: LocationListItemFragment;
  skeleton?: boolean;
} & ListItemComponent;

const LocationListItem = ({ item, skeleton, selected, onToggle }: LocationListProps) => {
  const navigate = useNavigate();
  const address: string | null =
    item.street && item.postal_code && item.city ? `${item.street}, ${item.postal_code} ${item.city}` : null;

  return (
    <tr onClick={onToggle || skeleton ? noop : navigate(`/locations/${item!.id}`)}>
      {onToggle && (
        <TableCell>
          <Checkbox inline disabled={skeleton} checked={selected} onChange={() => onToggle(item.id)} className="m-0" />
        </TableCell>
      )}
      <TableCell>
        <Text skeleton={skeleton}>{item.name}</Text>
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{address}</Text>
      </TableCell>
      <TableCell>
        <Text skeleton={skeleton}>{item.meeting_point}</Text>
      </TableCell>
    </tr>
  );
};

LocationListItem.fragments = {
  location: gql`
    fragment LocationListItem on Location {
      id
      name
      street
      city
      postal_code
      meeting_point
      public_transport
      accessible
    }
  `,
};

export default LocationListItem;
