import { Menu, MenuDivider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import React from 'react';
import { hasPermission, UserPermission } from '../helpers/constants';
import NavMenuItem from './NavMenuItem';

const Sidebar = () => (
  <div className="flex min-h-screen fixed" style={{ width: 200 }}>
    <Menu className="mt-12 bg-transparent">
      {(hasPermission(UserPermission.ManageEvents) ||
        hasPermission(UserPermission.ManageTicketAllotments) ||
        hasPermission(UserPermission.ManageCategories)) && (
        <NavMenuItem icon={IconNames.TIMELINE_EVENTS} text="Veranstaltungen" path="/events">
          {hasPermission(UserPermission.ManageEvents) && (
            <NavMenuItem icon={IconNames.PROPERTIES} text="Übersicht" path="/events" exact />
          )}
          {hasPermission(UserPermission.ManageEvents) && (
            <NavMenuItem icon={IconNames.PROJECTS} text="Archiv" path="/events/archive" />
          )}
          {hasPermission(UserPermission.ManageTicketAllotments) && (
            <NavMenuItem icon={IconNames.HORIZONTAL_BAR_CHART} text="Kontingente" path="/allotments" />
          )}
          {hasPermission(UserPermission.ManageCategories) && (
            <NavMenuItem icon={IconNames.TAG} text="Kategorien" path="/categories" />
          )}
        </NavMenuItem>
      )}
      {hasPermission(UserPermission.ManageNewsletters) && (
        <NavMenuItem icon={IconNames.ENVELOPE} text="Newsletter" path="/newsletters">
          <NavMenuItem icon={IconNames.PROPERTIES} text="Übersicht" path="/newsletters" exact />
          <NavMenuItem icon={IconNames.PROJECTS} text="Archiv" path="/newsletters/archive" />
        </NavMenuItem>
      )}
      <MenuDivider />

      {hasPermission(UserPermission.ManageSouls) && (
        <NavMenuItem icon={IconNames.HEART} text="Genießer" path="/souls" />
      )}
      {hasPermission(UserPermission.ManageCompanions) && (
        <NavMenuItem icon={IconNames.HAND} text="Begleiter" path="/companions" />
      )}
      {hasPermission(UserPermission.ManageOrganisations) && (
        <NavMenuItem icon={IconNames.HOME} text="Organisationen" path="/organisations" />
      )}
      {hasPermission(UserPermission.ManageCategories) && (
        <NavMenuItem icon={IconNames.TAG} text="Attribute" path="/tags" />
      )}

      <MenuDivider />
      {hasPermission(UserPermission.ManageDonors) && (
        <NavMenuItem icon={IconNames.BANK_ACCOUNT} text="Spender" path="/donors" />
      )}
      {hasPermission(UserPermission.ManageLocations) && (
        <NavMenuItem icon={IconNames.MAP_MARKER} text="Lokationen" path="/locations" />
      )}

      <MenuDivider />
      {hasPermission(UserPermission.ManageEmployees) && (
        <NavMenuItem icon={IconNames.PERSON} text="Mitarbeiter" path="/employees">
          <NavMenuItem icon={IconNames.PROPERTIES} text="Übersicht" path="/employees" exact />
          {hasPermission(UserPermission.ManageRoles) && (
            <NavMenuItem icon={IconNames.BADGE} text="Rollen" path="/roles" />
          )}
        </NavMenuItem>
      )}
      {hasPermission(UserPermission.MangeStatistics) && (
        <NavMenuItem icon={IconNames.CHART} text="Statistik" path="/stats" />
      )}
      {hasPermission(UserPermission.ManageSettings) && (
        <NavMenuItem icon={IconNames.SETTINGS} text="Einstellungen" path="/settings" />
      )}
      <NavMenuItem icon={IconNames.TRASH} text="Papierkorb" path="/trash" />
    </Menu>
  </div>
);

export default Sidebar;
