import { IMenuItemProps, MenuItem } from '@blueprintjs/core';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useNavigate } from '../hooks/useNavigate';

type NavMenuItemProps = {
  path: string;
  exact?: boolean;
} & IMenuItemProps;

const NavMenuItem = ({ path, exact, ...rest }: NavMenuItemProps) => {
  const match = useRouteMatch({ path, exact });
  const navigate = useNavigate();

  return (
    <MenuItem
      {...rest}
      href={path}
      onClick={navigate(path)}
      active={!!match}
      popoverProps={{
        usePortal: true, // This is needed for z-index to work properly
      }}
    />
  );
};

export default NavMenuItem;
