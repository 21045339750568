import React from 'react';
import { Classes, Colors, Icon, Popover, PopoverInteractionKind, Position, Intent } from '@blueprintjs/core';
import { IconName, IconNames } from '@blueprintjs/icons';

type PopoverIconProps = {
  icon?: IconName;
  intent?: Intent;
  color?: string;
  position?: Position;
  skeleton?: boolean;
  marginRight?: boolean;
  marginLeft?: boolean;
  children: React.ReactNode;
};

const getColor = (intent?: Intent, color?: string) => {
  if (intent) return undefined;
  if (color) return color;
  return Colors.GRAY1;
};

const PopoverIcon = ({
  icon,
  marginRight,
  marginLeft,
  intent,
  color,
  position,
  skeleton,
  children,
}: PopoverIconProps) => (
  <Popover interactionKind={PopoverInteractionKind.HOVER} hoverOpenDelay={100} position={position} disabled={skeleton}>
    <Icon
      icon={icon || IconNames.INFO_SIGN}
      intent={intent}
      color={getColor(intent, color)}
      className={[skeleton && Classes.SKELETON, marginRight && 'mr-1', marginLeft && 'ml-1'].join(' ')}
    />
    <div className="py-4 px-5" style={{ maxWidth: 500 }}>
      {children}
    </div>
  </Popover>
);

export default PopoverIcon;
