import { gql } from '@apollo/client';
import { Elevation, Tag } from '@blueprintjs/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import { ContentCard, ContentCardHeader } from '../../../components/ContentCard';
import EmailLink from '../../../components/EmailLink';
import ErrorCard from '../../../components/ErrorCard';
import LoadingCard from '../../../components/LoadingCard';
import NotFoundCard from '../../../components/NotFoundCard';
import {
  PropertyList,
  PropertyListDivider,
  PropertyListHeader,
  PropertyListItem,
} from '../../../components/PropertyList';
import ResourceLink from '../../../components/ResourceLink';
import Text from '../../../components/Text';
import { useEmployeeDetailQuery } from '../../../generated/graphql';
import EmployeeDetailMenu from './EmployeeDetailMenu';

type EmployeeInfoType = {
  onEdit: () => void;
};

const EmployeeInfo = ({ onEdit }: EmployeeInfoType) => {
  const { employeeId } = useParams();
  const { data, loading, error } = useEmployeeDetailQuery({
    variables: {
      id: employeeId || '',
    },
  });

  if (loading) return <LoadingCard />;
  if (error) return <ErrorCard />;
  if (!data || !data.employee) return <NotFoundCard resource="Mitarbeiter" />;

  const { employee } = data;

  const address: string | null =
    employee.street && employee.postal_code && employee.city
      ? `${employee.street}, ${employee.postal_code} ${employee.city}`
      : null;

  return (
    <ContentCard elevation={Elevation.TWO} className="mb-5">
      <ContentCardHeader
        leftElement={
          <>
            <Text large>{employee.display_name}</Text>
            <Tag minimal className="ml-2">
              Mitarbeiter
            </Tag>
          </>
        }
        rightElement={<EmployeeDetailMenu employee={employee} onEdit={onEdit} />}
      />
      <div className="flex py-4 px-5">
        <PropertyList>
          <PropertyListHeader>Person</PropertyListHeader>
          <PropertyListItem label="Email">
            <EmailLink email={employee.email} />
          </PropertyListItem>

          <PropertyListItem label="Telefon" value={employee.phone || '—'} showValueDisabled={!employee.phone} />
          <PropertyListItem label="Mobil" value={employee.mobile || '—'} showValueDisabled={!employee.mobile} />
          <PropertyListItem label="Adresse" value={address || '—'} showValueDisabled={!address} />
        </PropertyList>
        <PropertyListDivider />
        <PropertyList>
          <PropertyListHeader>Mitarbeiter Info</PropertyListHeader>
          <PropertyListItem label="Position" value={employee.meta.position} />
          <PropertyListItem label="Rollen" className="space-x-1">
            {!!employee.roles.length ? (
              employee.roles.map((role) => <ResourceLink key={role.id} path={`/roles/${role.id}`} text={role.name} />)
            ) : (
              <Text disabled>—</Text>
            )}
          </PropertyListItem>
        </PropertyList>
      </div>
    </ContentCard>
  );
};

EmployeeInfo.fragments = {
  employee: gql`
    fragment EmployeeInfo on Employee {
      id
      display_name
      first_name
      last_name
      email
      phone
      mobile
      street
      city
      postal_code
      roles {
        id
        name
      }
      meta {
        position
      }
    }
  `,
};

export default EmployeeInfo;
