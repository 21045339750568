/** @jsx jsx */
import { Colors, Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemListRenderer, ItemRenderer, Suggest } from '@blueprintjs/select';
import { css, jsx } from '@emotion/core';
import algoliasearch from 'algoliasearch/lite';
import { Fragment, useRef } from 'react';
import { AutocompleteProvided, Configure, Hit } from 'react-instantsearch-core';
import { connectAutoComplete, Highlight, InstantSearch } from 'react-instantsearch-dom';
import { useHistory } from 'react-router-dom';
import config from '../../../helpers/config';
import { UserHit } from '../../../types';
import { getSubdomain } from '../../../helpers/utils';

type CommonHit = {
  name: string;
};

type ModelHit = UserHit | CommonHit;

type SideWideHit = {
  id: number;
  model?: string | null;
} & ModelHit;

type SearchHit = Hit<SideWideHit>;

interface HitSections {
  [key: string]: SearchHit[];
}

enum HitModel {
  Event = 'Event',
  Soul = 'Soul',
  Companion = 'Companion',
  Employee = 'Employee',
  Contact = 'Contact',
  Donor = 'Donor',
  Location = 'Location',
  Organisation = 'Organisation',
}

const getSectionName = (hit: SearchHit): string => {
  switch (hit.model) {
    case HitModel.Soul:
      return 'Genießer';
    case HitModel.Companion:
      return 'Begleiter';
    case HitModel.Contact:
      return 'Ansprechpartner';
    case HitModel.Employee:
      return 'Mitarbeiter';
    case HitModel.Location:
      return 'Lokationen';
    case HitModel.Event:
      return 'Veranstaltungen';
    case HitModel.Donor:
      return 'Spender';
    case HitModel.Organisation:
      return 'Organisationen';
    default:
      return 'Unbekannt';
  }
};

const getIconName = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Soul:
      return IconNames.HEART;
    case HitModel.Companion:
      return IconNames.HAND;
    case HitModel.Contact:
      return IconNames.COMMENT;
    case HitModel.Employee:
      return IconNames.PERSON;
    case HitModel.Location:
      return IconNames.MAP_MARKER;
    case HitModel.Event:
      return IconNames.TIMELINE_EVENTS;
    case HitModel.Donor:
      return IconNames.BANK_ACCOUNT;
    case HitModel.Organisation:
      return IconNames.HOME;
    default:
      return IconNames.BLANK;
  }
};

const buildSections = (hits: SearchHit[]) =>
  hits.reduce<HitSections>((acc, hit) => {
    const section = getSectionName(hit);
    if (!acc[section]) {
      acc[section] = [];
    }

    return {
      ...acc,
      [section]: acc[section].concat([hit]),
    };
  }, {});

const renderHit: ItemRenderer<SearchHit> = (hit, { handleClick, modifiers }) => (
  <MenuItem
    active={modifiers.active}
    disabled={modifiers.disabled}
    key={hit.id}
    onClick={handleClick}
    icon={getIconName(hit)}
    text={renderHitLabel(hit)}
  />
);

const renderHitLabel = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Soul:
    case HitModel.Companion:
    case HitModel.Employee:
    case HitModel.Contact:
      return (
        <Fragment>
          <Highlight hit={hit} attribute="first_name" css={styles.highlight} />{' '}
          <Highlight hit={hit} attribute="last_name" css={styles.highlight} />
        </Fragment>
      );
    case HitModel.Location:
    case HitModel.Event:
    case HitModel.Donor:
    case HitModel.Organisation:
      return <Highlight hit={hit} attribute="name" css={styles.highlight} />;
    default:
      return 'Unbekannt';
  }
};

const renderHitList: ItemListRenderer<SearchHit> = ({ items: hits, renderItem }) => {
  if (!hits || !hits.length) {
    return (
      <Menu>
        <MenuItem disabled={true} text="Keine Resultate." />
      </Menu>
    );
  }

  const sections = buildSections(hits);

  return (
    <Menu>
      {Object.keys(sections).map((section) => (
        <Fragment key={section}>
          <MenuDivider title={section} />
          {sections[section].map(renderItem)}
        </Fragment>
      ))}
    </Menu>
  );
};

const renderValue = (hit: SearchHit) => {
  switch (hit.model) {
    case HitModel.Soul:
    case HitModel.Companion:
    case HitModel.Employee:
    case HitModel.Contact:
      return (hit as UserHit).display_name;
    case HitModel.Location:
    case HitModel.Event:
    case HitModel.Donor:
    case HitModel.Organisation:
      return (hit as CommonHit).name;
    default:
      return hit.id.toString();
  }
};

const SearchSuggest = Suggest.ofType<SearchHit>();

const SearchInput = ({ hits, refine, currentRefinement }: AutocompleteProvided<SideWideHit>) => {
  const history = useHistory();
  const navigate = (hit: SearchHit) => {
    switch (hit.model) {
      case HitModel.Soul:
        history.push(`/souls/${hit.id}`);
        break;
      case HitModel.Companion:
        history.push(`/companions/${hit.id}`);
        break;
      case HitModel.Employee:
        history.push(`/employees/${hit.id}`);
        break;
      case HitModel.Contact:
        history.push(`/contacts/${hit.id}`);
        break;
      case HitModel.Location:
        history.push(`/locations/${hit.id}`);
        break;
      case HitModel.Event:
        history.push(`/events/${hit.id}`);
        break;
      case HitModel.Donor:
        history.push(`/donors/${hit.id}`);
        break;
      case HitModel.Organisation:
        history.push(`/organisations/${hit.id}`);
        break;
      default:
    }
  };

  // Sort hits by model first so the keyboard navigation
  // keeps working
  hits.sort((a, b) => (a.model && b.model ? a.model.localeCompare(b.model) : 0));

  return (
    <div css={styles.searchBar}>
      <SearchSuggest
        itemListRenderer={renderHitList}
        itemListPredicate={(_, items) => items} // Filtering done by Algolia obviously
        query={currentRefinement}
        onQueryChange={refine}
        openOnKeyDown
        resetOnClose
        inputProps={{
          leftIcon: IconNames.SEARCH,
          placeholder: 'Suchen...',
          fill: true,
        }}
        inputValueRenderer={renderValue}
        itemRenderer={renderHit}
        itemsEqual="objectID"
        items={hits}
        onItemSelect={navigate}
        popoverProps={{ minimal: true, fill: true }}
      />
    </div>
  );
};

const ConnectedSearchInput = connectAutoComplete<SideWideHit>(SearchInput);

const SearchBar = () => {
  const searchClient = useRef(algoliasearch(config.algolia.appId, config.algolia.appSecret)).current;

  return (
    <InstantSearch searchClient={searchClient} indexName={`${getSubdomain()}_site_wide`}>
      <ConnectedSearchInput />
      <Configure hitsPerPage={12} />
    </InstantSearch>
  );
};

export default SearchBar;

const styles = {
  searchBar: css`
    max-width: 500px;
    flex-basis: 100%;
  `,
  highlight: css`
    em {
      font-style: normal;
      color: ${Colors.BLUE1};
    }
  `,
};
