/** @jsx jsx */
import { gql } from '@apollo/client';
import { Button, Colors, Intent, Tooltip } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { css, jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import AppToaster from '../../../AppToaster';
import PersonIconList from '../../../components/PersonIconList';
import Text from '../../../components/Text';
import TooltipIcon from '../../../components/TooltipIcon';
import { AttendeeListItemFragment, useAcceptSignupsMutation } from '../../../generated/graphql';
import { getSignupStatus, SignupStatus } from '../../../helpers/dataUtils';
import { showErrorMessage } from '../../../helpers/graphql';
import TooltipTag from '../../../components/TooltipTag';

export type AttendeeListItemProps = {
  item: AttendeeListItemFragment;
  isInGroup?: boolean;
};

const AttendeeStatusText = ({ status, item }: { status: SignupStatus; item: AttendeeListItemFragment }) => {
  const attendee = item.soul ? item.soul : item.companion!;

  switch (status) {
    case SignupStatus.CANDIDATE:
      return (
        <Text small muted>
          Noch nicht eingeladen.
        </Text>
      );
    case SignupStatus.ACCEPTED:
      return (
        <Text small intent={Intent.SUCCESS}>
          Bestätigt!
        </Text>
      );
    case SignupStatus.NO_EMAIL:
      return (
        <Fragment>
          <Text small>{attendee.phone || attendee.mobile || '-'} </Text>
          <Text small muted>
            (keine Email)
          </Text>
        </Fragment>
      );
    case SignupStatus.EMAIL_OPENED:
      return (
        <Text small muted>
          Einladungsmail gelesen.
        </Text>
      );
    case SignupStatus.EMAIL_SENT:
      return (
        <Text small muted>
          Einladungsmail verschickt.
        </Text>
      );
    case SignupStatus.EMAIL_REJECTED:
      return (
        <Text small intent={Intent.WARNING}>
          Einladungsmail abgewiesen!
        </Text>
      );
    case SignupStatus.EMAIL_ADDRESS_INVALID:
      return (
        <Text small intent={Intent.WARNING}>
          E-Mail Adresse ungültig!
        </Text>
      );
    default:
      return (
        <Text small intent={Intent.WARNING}>
          Unbekannter Fehler!
        </Text>
      );
  }
};

const getIndicatorColors = (status: SignupStatus): { iconColor: string; indicatorColor: string } => {
  const successStatuses = [SignupStatus.ACCEPTED];
  const warningStatuses = [
    SignupStatus.REJECTED,
    SignupStatus.EMAIL_REJECTED,
    SignupStatus.EMAIL_ADDRESS_INVALID,
    SignupStatus.EMAIL_UNKNOWN_ERROR,
  ];

  if (successStatuses.includes(status)) {
    return {
      indicatorColor: Colors.GREEN3,
      iconColor: Colors.LIGHT_GRAY5,
    };
  }

  if (warningStatuses.includes(status)) {
    return {
      indicatorColor: Colors.ORANGE3,
      iconColor: Colors.LIGHT_GRAY5,
    };
  }

  return {
    indicatorColor: Colors.LIGHT_GRAY5,
    iconColor: Colors.GRAY1,
  };
};

const AttendeeListItem = ({ item: signup, isInGroup }: AttendeeListItemProps) => {
  const attendee = signup.soul ? signup.soul : signup.companion!;
  const { organisation } = attendee;

  const status = getSignupStatus(signup);
  const { indicatorColor, iconColor } = getIndicatorColors(status);

  const [acceptSignups, { loading }] = useAcceptSignupsMutation({
    variables: {
      id: signup.event_id,
      token: signup.token,
    },
    onCompleted: () => AppToaster.success(`${attendee.display_name} wurde manuell bestätigt`),
    onError: showErrorMessage,
  });

  return (
    <div css={styles.container}>
      <div css={styles.indicator(indicatorColor)}>
        <TooltipIcon
          label={signup.soul ? 'Genießer' : 'Begleiter'}
          icon={signup.soul ? IconNames.HEART : IconNames.HAND}
          color={iconColor}
          marginRight
        />
      </div>
      <div css={styles.name}>
        <Link to={`/${signup.soul ? 'souls' : 'companions'}/${signup.soul ? signup.soul.id : signup.companion?.id}`}>
          {attendee!.display_name}
        </Link>
        <br />
        {!!organisation && (
          <Text small muted>
            {organisation.name}
          </Text>
        )}
      </div>
      <div css={styles.status}>
        {signup.is_first_attend && (
          <TooltipTag minimal intent={Intent.SUCCESS} label="Neu" tooltip="Erste Teilnahme" marginRight />
        )}
        <PersonIconList person={attendee} />
        <br />
        <AttendeeStatusText item={signup} status={status} />
      </div>
      <div css={styles.action}>
        {signup.is_invited && !signup.has_accepted && !isInGroup && (
          <Tooltip content="Zusage">
            <Button
              small
              intent={Intent.SUCCESS}
              icon={IconNames.TICK}
              loading={loading}
              onClick={() => acceptSignups()}
            />
          </Tooltip>
        )}
      </div>
    </div>
  );
};

AttendeeListItem.fragments = {
  acceptSignups: gql`
    fragment AcceptSignups on Event {
      id
      signups {
        id
        token
        has_accepted
        has_rejected
      }
    }
  `,
  attendees: gql`
    fragment AttendeeListItem on Signup {
      id
      event_id
      token
      is_candidate
      is_invited
      is_soul
      has_accepted
      has_rejected
      notes
      is_first_attend
      emails {
        id
        status
      }
      companion {
        id
        display_name
        email
        phone
        mobile
        notes
        meta {
          can_blind
          can_wheelchair
          can_wheeled_walker
          can_drive
        }
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
      }
      soul {
        id
        display_name
        email
        phone
        mobile
        notes
        meta {
          buys_ticket
          needs_blind
          needs_wheelchair
          needs_wheeled_walker
          needs_drive
          needs_trainstation_fetch
        }
        tags {
          id
          name
          icon
          intent
        }
        organisation {
          id
          name
        }
      }
    }
  `,
};

export default AttendeeListItem;

const styles = {
  container: css`
    min-height: 55px;
    display: flex;
    align-items: center;
    &:not(:first-of-type) {
      box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
      div:first-of-type {
        box-shadow: inset 0 1px 0 0 rgba(16, 22, 26, 0.15);
      }
    }
  `,
  indicator: (color: string) => css`
    width: 40px;
    display: flex;
    align-self: stretch;
    justify-content: center;
    align-items: center;
    background-color: ${color};
  `,
  name: css`
    padding: 6px 11px;
    width: calc(50% - 40px);
  `,
  status: css`
    padding: 6px 11px;
    width: calc(50% - 40px);
  `,
  action: css`
    width: 40px;
  `,
};
